import * as React from "react"
const SvgDeliveryMobile = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={82}
    height={82}
    fill="none"
    {...props}
  >
    <circle cx={40.962} cy={40.962} r={40.962} fill="#FED6D6" />
    <g clipPath="url(#a)">
      <path
        fill="#3F3B7F"
        d="M28.198 49.299h-9.78l-.26 2.617a.958.958 0 0 0 .957 1.049H26.96c-.051-.834.11-1.69.477-2.508a4.38 4.38 0 0 1 .76-1.158h.002ZM36.723 51.725c.079.407.105.823.079 1.24h9.121a.96.96 0 0 0 .96-.888l.196-2.778H35.47a4.862 4.862 0 0 1 1.254 2.427h-.001ZM30.05 49.06a3.524 3.524 0 0 0-1.791 1.78c-.743 1.654-.446 3.436.775 4.648 1.22 1.213 3.014 1.508 4.68.77a3.523 3.523 0 0 0 1.79-1.779c.743-1.655.446-3.436-.775-4.649-1.22-1.212-3.013-1.508-4.68-.77Zm3.489 4.72c-.128.217-.31.399-.529.526a2.271 2.271 0 0 1-1.135.316 1.949 1.949 0 0 1-1.39-.575c-.663-.66-.765-1.645-.26-2.51.128-.217.31-.399.53-.525.87-.502 1.862-.4 2.525.258.664.66.766 1.645.26 2.51h-.001ZM54.089 49.192a3.521 3.521 0 0 0-1.79 1.779c-.743 1.654-.446 3.435.774 4.647 1.22 1.212 3.013 1.508 4.678.77a3.521 3.521 0 0 0 1.79-1.779c.743-1.654.445-3.435-.774-4.647-1.22-1.212-3.013-1.508-4.678-.77Zm3.488 4.72c-.128.217-.31.398-.529.524a2.271 2.271 0 0 1-1.135.317 1.949 1.949 0 0 1-1.39-.575c-.664-.66-.765-1.645-.26-2.51.128-.218.31-.4.529-.525.87-.502 1.862-.4 2.526.258.663.66.765 1.644.26 2.51h-.001Z"
      />
      <path
        fill="#EE4347"
        d="m27.32 32.227.05.1.105.21.053.104a17.451 17.451 0 0 0 .335.62 34.032 34.032 0 0 0 .19.331c.022.034.042.07.063.105l.183.304.015.025a21.766 21.766 0 0 0 .436.682l.07.106c.073.11.149.222.227.335l.008.012a37.082 37.082 0 0 0 .516.723l.08.108c.089.12.18.243.274.365a35.871 35.871 0 0 0 3.827 4.23l.085-.088a.74.74 0 0 1 .025-.025l.08-.083.022-.024.109-.114.025-.026.099-.106.032-.034.132-.143.006-.007.135-.146.038-.042a30.678 30.678 0 0 0 .313-.347l.034-.038.138-.156.041-.046.178-.202.007-.009.173-.2.046-.054.165-.193.025-.03.194-.231.04-.048.165-.2.044-.053.205-.252.01-.014c.064-.08.13-.16.194-.242l.049-.062.19-.241.019-.026.212-.276.043-.056.177-.236.044-.059.215-.29.017-.025.195-.27.05-.068.199-.282.013-.02.213-.308.044-.065.175-.261.041-.063c.07-.105.139-.212.208-.318l.024-.039.18-.284.047-.077c.065-.103.129-.209.192-.314l.006-.01.196-.332.042-.074c.054-.092.106-.185.158-.277l.037-.065a31.276 31.276 0 0 0 .361-.677l.042-.083c.916-1.803 1.555-3.692 1.555-5.456.002-4.264-3.492-7.735-7.787-7.735-4.294 0-7.787 3.47-7.787 7.737 0 1.213.274 2.932 1.3 5.118l.137.287Zm6.35-8.839c1.765 0 3.201 1.427 3.201 3.181s-1.436 3.18-3.202 3.18c-1.765 0-3.2-1.426-3.2-3.18 0-1.754 1.435-3.18 3.2-3.18Z"
      />
      <path
        fill="#3F3B7F"
        d="M47.16 31.939h-6.251c-.675 1.458-1.611 3.011-2.806 4.65a41.986 41.986 0 0 1-4.075 4.782.376.376 0 0 1-.512.012c-.085-.075-2.116-1.872-4.179-4.565-1.295-1.69-2.293-3.319-2.992-4.88H20.53a.875.875 0 0 0-.87.81l-1.24 15.733h10.786c.148-.087.301-.167.46-.237 1.031-.458 2.123-.594 3.156-.396a4.93 4.93 0 0 1 1.637.632h12.62l.953-15.61a.876.876 0 0 0-.872-.931h.001ZM64.166 42.45l-2.112-.918a.564.564 0 0 1-.284-.272l-2.954-6.78a4.247 4.247 0 0 0-3.894-2.543l-4.54-.003c-.72 0-1.316.557-1.36 1.271l-1.145 19.01c-.002.575.452.846.992.846h2.128a5.33 5.33 0 0 1 .48-2.473 4.396 4.396 0 0 1 2.23-2.215c1.031-.456 2.12-.591 3.153-.394.966.186 1.847.651 2.547 1.347a4.866 4.866 0 0 1 1.436 3.735h3.022c.495 0 .905-.335.938-.766l.362-8.254a1.66 1.66 0 0 0-1-1.593l.001.001Zm-13.039-2.24.34-6.038 3.45.003c.74 0 1.41.469 1.704 1.194l1.967 4.842h-7.461Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M18.154 19.085h47.014v37.704H18.154z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgDeliveryMobile
