import { Colors, Fonts } from "../../utils/styles";

const styles = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    width: 80,
    height: 80,
    alignSelf: "center",
    marginBottom: 5,
  },
  headerContainer: {
    marginBottom: 5,
  },
  header: {
    ...Fonts.FONT_MEDIUM,
    fontSize: 18,
    textAlign: "center",
    color: Colors.BES_PURPLE,
  },
  bodyContainer: {
    width: "100%",
    marginBottom: 2,
  },
  body: {
    fontSize: 16,
    textAlign: "center",
    lineHeight: 1.5,
    whiteSpace: "pre-line",
    color: Colors.BES_BLACK,
  },
  button: {
    width: "100%",
    background: Colors.BES_PURPLE,
    border: "None",
    fontSize: 16,
    fontWeight: 600,
    margin: "10px 0px 10px 0px",
    height: 50,
    borderRadius: 7,
  },
};

export default styles;
