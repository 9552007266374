import { Colors } from "../../../utils/styles";
import * as React from "react";
const PendingSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13}
    height={13}
    fill="none"
    {...props}
  >
    <path
      fill={Colors.GRAY_DARK}
      d="M12.881 7.741a6.458 6.458 0 0 1-1.785 3.355 6.49 6.49 0 0 1-7.623 1.158.256.256 0 0 1-.061-.407L7.548 7.71c.1-.1.261-.1.361 0l.946.946.69-5.203-5.202.69.946.947c.1.1.1.261 0 .361L1.153 9.588a.255.255 0 0 1-.407-.061A6.526 6.526 0 0 1 .671 3.62a6.489 6.489 0 0 1 1.233-1.717A6.459 6.459 0 0 1 5.259.119a6.543 6.543 0 0 1 3.687.357 6.46 6.46 0 0 1 2.15 1.428 6.462 6.462 0 0 1 1.785 3.354 6.549 6.549 0 0 1 0 2.483Z"
    />
  </svg>
);
export default PendingSvg;
