import React, { useContext, useEffect, useState, useCallback } from "react";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import Header from "../../components/Header";
import SideBar from "../../components/SideBar";
import OrderItem from "../../components/OrderItem";
import LoginPage from "../login";
import AuthContext from "../../context/authContext";
import { getOrders, getOrder, deleteOrder } from "../../services/api";
import { clean_dollar_format } from "../../utils/utilities";
import { Colors } from "../../utils/styles";
import { ROUNDED_BUTTON } from "../../utils/styles/buttons";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from "react-toastify";
import styles from "./style";
import "./style.scss";

export const orderStatusMapping = {
  null: "Awaiting confirmation",
  1: "Order opened",
  3: "Order processed",
  5: "Order delivered",
  6: "Order refunded",
};

const OrderHistoryPage = () => {
  const [error, setError] = useState("");
  const [orderDetails, setOrderDetails] = useState({});
  const { isAuthenticated } = useContext(AuthContext);
  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = useState(true);
  const [closedOrders, setClosedOrders] = useState([]);
  const urlPath = useLocation().pathname;
  const [pendingOrders, setPendingOrders] = useState([]);
  const [opendedOrders, setOpenedOrders] = useState([]);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [modalOrderDeleteId, setmodalOrderDeleteId] = useState("");
  const [loading, setLoading] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = (orderId) => {
    getOrderDetails(orderId);
    setShow(true);
  };

  const getOrderDetails = async (order_id) => {
    try {
      const orderDetailsRes = await getOrder(order_id);
      setOrderDetails(orderDetailsRes.data);
    } catch (error) {
      setError(error.response.data.detail);
    }
  };

  const getOrderHistory = useCallback(async () => {
    setLoading(true);
    try {
      const orders_res = await getOrders("history");
      const allData = orders_res.data;
      const closedOrders = allData.filter(
        (order) => order.status === 5 || order.status === 6
      );
      const openOrders = allData.filter(
        (order) => order.status !== 5 && order.status !== 6
      );
      setOpenedOrders(openOrders);
      setClosedOrders(closedOrders);
    } catch (error) {
      setError(error.response.data.detail);
    } finally {
      setLoading(false);
    }
  }, []);

  const getPendingOrders = useCallback(async () => {
    setLoading(true);
    try {
      const orders_res = await getOrders("awaiting_payment");
      setPendingOrders(orders_res.data);
    } catch (error) {
      setError(error.response.data.detail);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleToggleDeleteModal = (order_id) => {
    setDeleteModalShow(!deleteModalShow);
    setmodalOrderDeleteId(order_id);
  };

  const handleToggleCloseModal = () => {
    setDeleteModalShow(!deleteModalShow);
    setmodalOrderDeleteId("");
  };

  const handleOrderDelete = async (order_id) => {
    try {
      const data = { status: "Cancelled" };
      await deleteOrder(order_id, data);
      toast.success("Order deleted successfully.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "light",
      });
      setmodalOrderDeleteId("");
      setDeleteModalShow(!deleteModalShow);
      getPendingOrders();
    } catch (error) {
      setDeleteModalShow(!deleteModalShow);
      setError("Item could not be deleted");
    }
  };

  const handleOnOrderDelete = () => {
    if (modalOrderDeleteId !== "") {
      handleOrderDelete(modalOrderDeleteId);
    } else {
      setDeleteModalShow(!deleteModalShow);
    }
  };
  useEffect(() => {
    getOrderHistory();
    getPendingOrders();

    if (!isAuthenticated && urlPath === "/history") {
      setModalShow(false);
    }
    // eslint-disable-next-line no-unused-vars
  }, [getOrderHistory, isAuthenticated, urlPath, getPendingOrders]);

  if (!isAuthenticated) {
    return <LoginPage path="/history" />;
  }

  return (
    <div>
      <Header hideAuth={true} />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="light"
      />
      <div className="custom_container custom_container--pall">
        <section style={{ height: "90vh", marginTop: 20 }}>
          <div className="custom-row">
            <div
              style={{
                padding: "10px 0 0 0",
              }}
              className="profile-sidebar first"
            >
              <SideBar path="/history" />
            </div>
            <div className="second p-4">
              <Modal
                className="modal-content-z"
                show={show}
                size="lg"
                onHide={handleClose}
                backdrop="static"
                centered
              >
                <Modal.Header closeButton>
                  <h5>ITEMS</h5>
                </Modal.Header>
                <Modal.Body style={{ height: "inherit" }}>
                  <OrderItem order={{ ...orderDetails }} />
                  <Row>
                    <Col lg={4}>
                      {" "}
                      <p>
                        <span style={{ fontWeight: "bold" }}>
                          Delivery Type:
                        </span>{" "}
                        {orderDetails.delivery_type === "PUP"
                          ? "Pickup"
                          : "Door-to-Door"}
                      </p>
                    </Col>
                    <Col lg={8}>
                      <p>
                        <span style={{ fontWeight: "bold" }}>
                          Delivery Address:
                        </span>{" "}
                        {orderDetails.shipping_address}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    {orderDetails.shipping_fee !== null ? (
                      <Col lg={4}>
                        <p>
                          <span style={{ fontWeight: "bold" }}>
                            Shipping Fee:
                          </span>{" "}
                          ${orderDetails.shipping_fee}
                        </p>
                      </Col>
                    ) : null}
                    {orderDetails.handling_fee !== null ? (
                      <Col lg={4}>
                        <p>
                          <span style={{ fontWeight: "bold" }}>
                            Handling Fee:
                          </span>{" "}
                          ${orderDetails.handling_fee}
                        </p>
                      </Col>
                    ) : null}
                    <Col lg={4}>
                      {" "}
                      <p>
                        <span style={{ fontWeight: "bold" }}>
                          Tax & Duties:
                        </span>{" "}
                        ${orderDetails.tax_duties}
                      </p>
                    </Col>
                  </Row>
                </Modal.Body>
              </Modal>
              {error && (
                <Modal
                  show={modalShow}
                  size="md"
                  onHide={handleClose}
                  backdrop="static"
                  centered
                >
                  {" "}
                  <Modal.Header>
                    <h5>Session Expired</h5>
                  </Modal.Header>
                  <Modal.Body style={{ height: "inherit" }}>
                    <p>{error}</p>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      size="md"
                      style={{
                        ...ROUNDED_BUTTON,
                        width: "fit-content",
                        height: 45,
                        margin: "0 auto",
                      }}
                      onClick={() => {
                        setModalShow(false);
                        setTimeout(() => window.location.reload(false), 500);
                      }}
                    >
                      Sign in to continue
                    </Button>
                  </Modal.Footer>
                </Modal>
              )}

              <Modal
                show={deleteModalShow}
                size="md"
                onHide={() => {
                  handleClose();
                }}
                backdrop="static"
                centered
              >
                {" "}
                <Modal.Header
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: 22,
                    fontWeight: "bold",
                  }}
                >
                  Delete Order
                </Modal.Header>
                <Modal.Body style={{ height: "inherit", textAlign: "center" }}>
                  <div>
                    <p className="text-center">
                      Are you sure want to delete this order?
                    </p>
                    <span className="text-center text-sm">
                      {" "}
                      You can't undo this action!!!{" "}
                    </span>
                  </div>
                </Modal.Body>
                <Modal.Footer variant="flex flex-jc-sb ai-i-c">
                  <Button
                    size="md"
                    style={{
                      ...ROUNDED_BUTTON,
                      width: "40%",
                      height: 40,
                      margin: "0 auto",
                      fontWeight: "bold",
                    }}
                    onClick={handleOnOrderDelete}
                  >
                    OK
                  </Button>
                  <Button
                    size="md"
                    style={{
                      ...ROUNDED_BUTTON,

                      color: "black",
                      backgroundColor: "white",
                      width: "40%",
                      height: 40,
                      margin: "0 auto",
                      fontWeight: "bold",
                      boxShadow:
                        "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
                    }}
                    onClick={() => {
                      handleToggleCloseModal();
                    }}
                  >
                    Cancel
                  </Button>
                </Modal.Footer>
              </Modal>

              <h5 style={styles.pendingOrdersHeader}>My Orders</h5>
              <hr />
              <Tabs
                defaultActiveKey="profile"
                id="uncontrolled-tab-example"
                className=" tabs-mobile"
                fill
              >
                <Tab
                  eventKey="profile"
                  className="firsth"
                  title={`Pending (${pendingOrders?.length || 0})`}
                >
                  {!loading &&
                    pendingOrders &&
                    pendingOrders.map((order) => {
                      return (
                        <Card
                          style={{
                            width: "100%",
                            marginTop: 40,
                            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                          }}
                          className="pending-orders-card"
                          key={order.id}
                        >
                          <Card.Body>
                            <div className="flex flex-jc-sb mb-3">
                              <Card.Title className=" fw-bold fs-6">
                                ORDER ID: {order.order_id}
                              </Card.Title>
                              <Button
                                variant="outlined btn-md"
                                style={{
                                  borderColor: Colors.BES_PURPLE,
                                  fontSize: 10,
                                  fontWeight: 600,
                                }}
                                onClick={() => handleShow(order.order_id)}
                              >
                                View Order
                              </Button>
                            </div>
                            <Row>
                              {order.is_currency_converted && !order.status ? (
                                <Col lg={6}>
                                  <Card.Subtitle className="mb-4 fw-bold fs-6">
                                    Price:{" "}
                                    {clean_dollar_format(order.order_total)}
                                  </Card.Subtitle>
                                  <Card.Text className="text-muted text-sm">
                                    Awaiting payment
                                  </Card.Text>
                                  <Button
                                    href={`/order/${order.order_id}/checkout`}
                                    style={{
                                      background: Colors.BES_PURPLE,
                                      border: "None",
                                      marginRight: 20,
                                      fontSize: 12,
                                      fontWeight: 600,
                                    }}
                                  >
                                    Review Payment
                                  </Button>
                                </Col>
                              ) : order.is_currency_converted &&
                                order.status === 4 ? (
                                <Col lg={6}>
                                  <Card.Subtitle className="mb-4 fw-bold fs-6">
                                    Price:{" "}
                                    {clean_dollar_format(
                                      order.additional_payment
                                    )}
                                  </Card.Subtitle>
                                  <Card.Text className="text-muted">
                                    Awaiting payment
                                  </Card.Text>
                                  <Button
                                    href={`/order/${order.order_id}/checkout`}
                                    style={{
                                      background: Colors.ALERT,
                                      borderColor: Colors.ALERT,
                                      marginRight: 20,
                                      fontSize: 12,
                                      fontWeight: 600,
                                    }}
                                  >
                                    Make Additional Payment
                                  </Button>
                                </Col>
                              ) : (
                                <Col lg={6}>
                                  <Card.Text className="text-muted text-sm">
                                    Order is awaiting confirmation and payment
                                    details
                                  </Card.Text>
                                </Col>
                              )}
                              <Col
                                lg={6}
                                style={{
                                  display: "flex",
                                  alignItems: "flex-end",
                                }}
                              >
                                {/* remove delete button for additional payment */}
                                {order.status !== 4 && (
                                  <FontAwesomeIcon
                                    icon={faTrash}
                                    size="1x"
                                    color={Colors.ALERT}
                                    style={{
                                      marginLeft: "auto",
                                      marginTop: -50,
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      handleToggleDeleteModal(order.order_id)
                                    }
                                  />
                                )}
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      );
                    })}

                  {loading && (
                    <div className="flex flex-dc flex-jc-c flex-ai-c mt-5">
                      <i className="fa fa-spinner fa-2x text-secondary mb-1"></i>
                      <small className="text-secondary text-sm">
                        Just a moment
                      </small>
                    </div>
                  )}
                  {pendingOrders && pendingOrders.length === 0 && !loading && (
                    <div className="flex flex-dc flex-jc-c flex-ai-c mt-5">
                      <i className="fa-solid fa-cart-shopping text-secondary mb-1"></i>
                      <small className="text-secondary text-sm">
                        No Pending orders
                      </small>
                    </div>
                  )}
                </Tab>
                <Tab
                  eventKey="home2"
                  className="first"
                  title={`Opened (${opendedOrders.length})`}
                >
                  {!loading &&
                    opendedOrders.map((order, idx) => {
                      return (
                        <Card
                          style={{
                            width: "100%",
                            marginTop: 40,
                            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                          }}
                          key={idx}
                          className="pending-orders-card"
                        >
                          <Card.Body>
                            <div className="flex flex-jc-sb flex-ai-c">
                              <Card.Title className="mb-4 fs-6 ">
                                ORDER ID: {order.order_id}
                              </Card.Title>
                              <Button
                                variant="outlined btn-md"
                                style={{
                                  borderColor: Colors.BES_PURPLE,
                                  fontSize: 10,
                                  fontWeight: 600,
                                }}
                                onClick={() => handleShow(order.order_id)}
                              >
                                View Order
                              </Button>
                            </div>
                            <Row>
                              <Col lg={6}>
                                <Card.Subtitle className="mb-4 fw-bold fs-6">
                                  Amount:{" "}
                                  {clean_dollar_format(order.order_total)}
                                </Card.Subtitle>
                                <Card.Text className="badge status_bg">
                                  {orderStatusMapping[order.status]}
                                </Card.Text>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      );
                    })}

                  {loading && (
                    <div className="flex flex-dc flex-jc-c flex-ai-c mt-5">
                      <i className="fa fa-spinner fa-2x text-secondary mb-1"></i>
                      <small className="text-secondary text-sm">
                        Just a moment
                      </small>
                    </div>
                  )}
                  {opendedOrders.length === 0 && (
                    <div className="flex flex-dc flex-jc-c flex-ai-c mt-5">
                      <i className="fa-solid fa-cart-shopping text-secondary mb-1"></i>
                      <small className="text-secondary text-sm">
                        No Opened orders
                      </small>
                    </div>
                  )}
                </Tab>
                <Tab
                  title={`Closed (${closedOrders.length})`}
                  eventKey="profilev"
                >
                  {!loading &&
                    closedOrders?.map((order, idx) => {
                      return (
                        <Card
                          style={{
                            width: "100%",
                            marginTop: 40,
                            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                          }}
                          key={idx}
                          className="pending-orders-card"
                        >
                          <Card.Body>
                            <div className="flex flex-jc-sb flex-ai-c">
                              <Card.Title className="mb-4 fs-6 ">
                                ORDER ID: {order.order_id}
                              </Card.Title>
                              <Button
                                variant="outlined btn-md"
                                style={{
                                  borderColor: Colors.BES_PURPLE,
                                  fontSize: 10,
                                  fontWeight: 600,
                                }}
                                onClick={() => handleShow(order.order_id)}
                              >
                                View Order
                              </Button>
                            </div>
                            <Row>
                              <Col lg={6}>
                                <Card.Subtitle className="mb-4 fw-bold fs-6">
                                  Amount:{" "}
                                  {clean_dollar_format(order.order_total)}
                                </Card.Subtitle>
                                <Card.Text className="badge status_bg">
                                  {orderStatusMapping[order.status]}
                                </Card.Text>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      );
                    })}

                  {loading && (
                    <div className="flex flex-dc flex-jc-c flex-ai-c mt-5">
                      <i className="fa fa-spinner fa-2x text-secondary mb-1"></i>
                      <small className="text-secondary text-sm">
                        Just a moment
                      </small>
                    </div>
                  )}
                  {closedOrders.length === 0 && (
                    <div className="flex flex-dc flex-jc-c flex-ai-c mt-5">
                      <i className="fa-solid fa-cart-shopping text-secondary mb-1"></i>
                      <small className="text-secondary text-sm">
                        No Closed orders
                      </small>
                    </div>
                  )}
                </Tab>
              </Tabs>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default OrderHistoryPage;
