import React, {
  useState,
  useEffect,
  useContext,
  Fragment,
  useCallback,
} from "react";
import { useNavigate, Link } from "react-router-dom";
import { Button, Form, Container, Row, Col, Modal } from "react-bootstrap";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { Oval } from "react-loader-spinner";
import TermsAndConditions from "../../components/TermsAndConditions";
import AuthContext from "../../context/authContext";
import { userSignup } from "../../services/api";
import { ROUNDED_BUTTON } from "../../utils/styles/buttons";
import "../login/loginStyles.css";
import AuthWrapper from "../../components/AuthWrapper";
import "./style.scss";
import styles from "./style";
import { saveToStore } from "../../utils/storage-helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const { REACT_APP_GOOGLE_CLIENT_ID, REACT_APP_GOGGLE_REDIRECT_URL_ENDPOINT } =
  process.env;

const RegisterPage = (props) => {
  const [firstname, setUserFirstName] = useState("");
  const [lastname, setUserLastName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [phone, setUserPhone] = useState(0);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [referral, setReferral] = useState("");
  const [hasPasswordError, setHasPasswordError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [hasError, setHasError] = useState(false);
  const [acceptCondiiton, setAcceptConditions] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalShow, setModalShow] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [signupwithEmail, setsignupwithEmail] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { setAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(window.location.search);
  const href = window.location.href;
  const refFromUrl = urlParams.get("ref");

  const handleClose = () => setShowModal(false);
  const handleShow = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  useEffect(() => {
    // Get the referral parameter from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const refFromUrl = urlParams.get("ref");

    // Check if 'ref' exists in the URL
    if (refFromUrl) {
      // Update the state with the referral parameter value
      setReferral(refFromUrl);
    }
  }, []); // Empty dependency array ensures this runs only once on component mount

  useEffect(() => {
    // Get the referral parameter from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const refFromUrl = urlParams.get("ref");

    // Check if 'ref' exists in the URL
    if (refFromUrl) {
      // Update the state with the referral parameter value
      setReferral(refFromUrl);
      setsignupwithEmail(!signupwithEmail);
    }
  }, []); // Empty dependency array ensures this runs only once on component mount

  const validateForm = () => {
    return (
      userEmail.length > 0 &&
      password.length > 0 &&
      phone > 0 &&
      firstname.length &&
      lastname.length &&
      acceptCondiiton === true &&
      isEmailValid
    );
  };

  const handleEmailChange = (e) => {
    const value = e;
    setUserEmail(e);
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    setIsEmailValid(isValidEmail);
  };

  const openGoogleLoginPage = useCallback(() => {
    const googleAuthUrl = "https://accounts.google.com/o/oauth2/v2/auth";
    const redirectUri = "google/";

    const scope = [
      "https://www.googleapis.com/auth/userinfo.email",
      "https://www.googleapis.com/auth/userinfo.profile",
    ].join(" ");

    const params = {
      response_type: "code",
      client_id: REACT_APP_GOOGLE_CLIENT_ID,
      redirect_uri: `${REACT_APP_GOGGLE_REDIRECT_URL_ENDPOINT}/${redirectUri}`,
      prompt: "select_account",
      access_type: "offline",
      scope,
    };

    const urlParams = new URLSearchParams(params).toString();

    window.location = `${googleAuthUrl}?${urlParams}`;
  }, []);

  const validatePassword = (e) => {
    const text = e.target.value;
    setPassword2(text);

    if (password === text) {
      setHasPasswordError(false);
    } else {
      setHasPasswordError(true);
    }
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const email = userEmail.toLowerCase();
    const data = {
      email,
      password,
      username,
      first_name: firstname,
      last_name: lastname,
      phone_no: phone,
      referral: referral,
    };
    const register_res = await userSignup(data);

    if (register_res.status !== 201) {
      const err = register_res.response.data;
      const errMsg = Object.keys(err).map((key) => {
        return `${[key]}: ${err[key]}`;
      });
      setError(errMsg);
      setHasError(true);
      setAuth(false);
      setSuccess(false);
      setModalShow(true);
    } else {
      await saveToStore("userEmail", email);
      navigate("/otp");
      setHasError(false);
      setSuccess(true);
      setAuth(false);
      setModalShow(true);
    }
    setIsLoading(false);
  };

  const toggleSignupwithEmail = () => {
    setsignupwithEmail(!signupwithEmail);
  };

  return (
    <AuthWrapper
      header="Create a Free Account"
      description="Shopping on your favourite stores is just one click away!"
    >
      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>TERMS AND CONDITIONS</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TermsAndConditions />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleClose}>
            I Agree
          </Button>
        </Modal.Footer>
      </Modal>
      {success && (
        // insert success modal
        <Modal
          {...props}
          size="md"
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={modalShow}
          onHide={() => setModalShow(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Registration Succesfull !
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Please check your email inbox or spam to activate your account.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="md"
              style={{
                ...ROUNDED_BUTTON,
                width: 100,
                height: 40,
                margin: "0 auto",
              }}
              onClick={() => {
                setSuccess(false);
                navigate("/signin");
              }}
            >
              Got it!
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {hasError && (
        <Modal
          {...props}
          size="md"
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={modalShow}
          onHide={() => setModalShow(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Invalid Credentials
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{error}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="md"
              style={{
                ...ROUNDED_BUTTON,
                width: 100,
                height: 40,
                margin: "0 auto",
              }}
              onClick={() => {
                setModalShow(false);
                // setTimeout(() => {
                //   window.location.replace("/register");
                // }, 300);
              }}
            >
              Got it!
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {signupwithEmail === true ? (
        <Container
          style={{
            marginTop: 250,
            paddingLeft: 85,
            paddingRight: 85,
            overflow: "scroll",
          }}
          className="signup-right-container signup-margin-top "
        >
          <h5 className="login-container-title">Create Account </h5>
          <p className="form-description">
            Shopping on your favourite stores is just one click away!
          </p>

          <Form className="" onSubmit={handleSubmit} style={{ marginTop: 30 }}>
            <Row style={{ marginBottom: 20 }}>
              <Col xs={12} md={12} lg={6}>
                <Form.Group
                  size="lg"
                  controlId="firstname"
                  style={styles.signInFormContainer}
                  className="register-form mb-2"
                >
                  <Form.Label style={styles.registerFormLabel}>
                    Firstname <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    placeholder="Enter First Name"
                    autoFocus
                    type="text"
                    value={firstname}
                    onChange={(e) => setUserFirstName(e.target.value)}
                    style={styles.formInput}
                    className="signupFormInput"
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={12} lg={6}>
                <Form.Group
                  size="lg"
                  controlId="lastname"
                  style={styles.signInFormContainer}
                >
                  <Form.Label style={styles.registerFormLabel}>
                    Lastname <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    placeholder="Enter Last Name"
                    type="text"
                    value={lastname}
                    onChange={(e) => setUserLastName(e.target.value)}
                    style={styles.formInput}
                    className="signupFormInput"
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={12} className="email-input">
                <Form.Group
                  size="lg"
                  controlId="email"
                  style={styles.signInFormContainer}
                  className="mt-3"
                >
                  <Form.Label style={styles.registerFormLabel}>
                    Email <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    placeholder="Enter email"
                    type="email"
                    value={userEmail}
                    onChange={(e) => handleEmailChange(e.target.value)}
                    style={styles.formInput}
                    className="signupFormInput"
                  />
                  {userEmail && !isEmailValid ? (
                    <small className="text-sm text-danger">
                      Invalid Email Address
                    </small>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>
            <Row style={{ marginBottom: 20 }}>
              <Col xs={12} md={12}>
                <Form.Group
                  size="lg"
                  controlId="phone"
                  style={styles.signInFormContainer}
                  className="register-form"
                >
                  <Form.Label style={styles.registerFormLabel}>
                    Phone <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <PhoneInput
                    international
                    defaultCountry="NG"
                    value={phone}
                    onChange={setUserPhone}
                    style={styles.formInput}
                    className="signupFormInput"
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={12}>
                <Form.Group
                  size="lg"
                  controlId="username"
                  style={styles.signInFormContainer}
                  className="mt-3"
                >
                  <Form.Label style={styles.registerFormLabel}>
                    Username <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    placeholder="Enter Username"
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    style={styles.formInput}
                    className="signupFormInput"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row style={{ marginBottom: 15 }}>
              <Col xs={12} md={6} lg={6}>
                <Form.Group
                  size="lg"
                  controlId="password"
                  style={styles.signInFormContainer}
                  className="register-form"
                >
                  <Form.Label style={styles.registerFormLabel}>
                    Password <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <div style={{ position: "relative" }}>
                    <Form.Control
                      placeholder="Password"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      style={styles.formInput}
                      className="signupFormInput"
                    />
                    <FontAwesomeIcon
                      icon={showPassword ? faEye : faEyeSlash}
                      onClick={() => setShowPassword(!showPassword)}
                      style={styles.secureEntry}
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col xs={12} md={6} lg={6}>
                <Form.Group
                  size="lg"
                  controlId="password2"
                  style={styles.signInFormContainer}
                >
                  <Form.Label style={styles.registerFormLabel}>
                    Confirm Password <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <div style={{ position: "relative" }}>
                    <Form.Control
                      placeholder="Confirm Password"
                      type={showPassword ? "text" : "password"}
                      value={password2}
                      onChange={(e) => validatePassword(e)}
                      style={styles.formInput}
                      className="signupFormInput register-form"
                    />
                    <FontAwesomeIcon
                      icon={showPassword ? faEye : faEyeSlash}
                      onClick={() => setShowPassword(!showPassword)}
                      style={styles.secureEntry}
                    />
                  </div>
                </Form.Group>
                {hasPasswordError && (
                  <p style={{ color: "red", fontSize: 12, marginTop: 5 }}>
                    Passwords does not match
                  </p>
                )}
              </Col>
              <Col xs={12} md={4} lg={12}>
                <Form.Group
                  size="lg"
                  controlId="referral"
                  style={styles.signInFormContainer}
                  className="mt-3"
                >
                  <Form.Label style={styles.registerFormLabel}>
                    How did you hear about us?
                  </Form.Label>
                  <Form.Control
                    placeholder="Referral, Facebook, Google e.t.c"
                    type="text"
                    value={referral}
                    disabled={refFromUrl ? true : false}
                    onChange={(e) => setReferral(e.target.value)}
                    style={styles.formInput}
                    className="signupFormInput"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group id="formGridCheckbox">
              <Form.Check
                type="checkbox"
                // label="I accept the"
                onChange={(e) => {
                  setAcceptConditions(e.target.checked);
                }}
                style={{ display: "inline-flex" }}
              />
              <button
                className="form-check-label"
                style={styles.termsAndConditionButton}
                onClick={(e) => handleShow(e)}
              >
                I accept the terms and conditions.
              </button>
            </Form.Group>
            <div class="d-grid gap-2 pe-auto">
              <Button
                className="block login-btn"
                size="lg"
                type="submit"
                style={styles.button}
                disabled={!validateForm() || isLoading}
              >
                {isLoading ? (
                  <div className="d-flex align-items-center justify-content-center">
                    <Oval
                      type="Circles"
                      color="#fff"
                      height={30}
                      width={30}
                      wrapperStyle={{ margin: "0 auto" }}
                    />
                  </div>
                ) : (
                  "Create Account"
                )}
              </Button>
            </div>
          </Form>
        </Container>
      ) : (
        <Fragment>
          <div className="mt-5 mb-5">
            <h1 className="login-container-title">Create an Account!</h1>
            <div className="d-grid gap-2 mt-4">
              <Button
                className="form-button bes_purple text-white mt-3"
                size="md"
                onClick={toggleSignupwithEmail}
              >
                Sign Up with Email
              </Button>

              {/* <div className="mt-3 text-center text-sm mb-3">OR</div> */}

              {/* <GoogleButton
                onClick={openGoogleLoginPage}
                label="Sign Up with Google"
                disabled={!REACT_APP_GOOGLE_CLIENT_ID}
                className="ml-4 google-login google-login-extend"
              /> */}

              <div className="mt-4 text-center">
                Have an account?
                <Link
                  to="/signin"
                  className=" cursor-toggle text-danger text-decoration-none"
                >
                  {" "}
                  Login
                </Link>
              </div>

              <div className="mt-4 flex flex-jc-c ">
                <Col lg={12}>
                  <Col lg={12} xs={12} className="">
                    <Row>
                      <div className="flex flex-jc-c gap-2 mt-1">
                        <a
                          target="_blank"
                          className="text-decoration-none text-center
                       "
                          rel="noreferrer"
                          href="https://play.google.com/store/apps/details?id=com.betaeshopping"
                          style={styles.links}
                        >
                          <img
                            width="140"
                            height="40"
                            className="object-fit-contain"
                            src="https://ci6.googleusercontent.com/proxy/G13iKmujmbZRIKvNuu5G9k4_kKSicdLwHZC9MDn-eGbyB2Kuph_mOrOv0ez5haxFeyUdXfD4EYEnCAhlzAEKbYnlqy3FDQnlbFNC4vlT51Qh8QpWHxqDEIdaHZOs-rnrFFeHkn1UqduHq9j6-fhCtQ=s0-d-e1-ft#https://stratus.campaign-image.com/images/1001222000003489004_zc_v9_1676373138172_googlee.png"
                            alt="goggle-play-icon"
                          />
                        </a>

                        <a
                          className="text-decoration-none
                        text-center ml-3"
                          rel="noreferrer"
                          target="_blank"
                          href="https://apps.apple.com/ng/app/beta-eshopping/id1642708607"
                          style={styles.links}
                        >
                          <img
                            width="140"
                            height="40"
                            className="object-fit-contain"
                            src="https://ci6.googleusercontent.com/proxy/8mynj_yIiAtz93NPrrhgh8S0z2ttyCndSPhqCCbpVNVY9ZsGuPUixI1572cPPrO9nRWk-HttAQRSGSxgDOEKCHNx61wl4UIclv-XnR65iZBtqS-d4FsVNt8LkK-ydCyU1KLyfYNFEKlZlatMCDQ=s0-d-e1-ft#https://stratus.campaign-image.com/images/1001222000003489004_zc_v9_1676373138125_apple.png"
                            alt="apple-icon"
                          />
                        </a>
                      </div>
                    </Row>
                  </Col>
                </Col>
              </div>
              <p className="mt-1 text-center">
                Download app to shop on the go!
              </p>
            </div>
          </div>
        </Fragment>
      )}
    </AuthWrapper>
  );
};

export default RegisterPage;
