import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "./style.scss";
import { getPostdetail } from "../../services/api";
import moment from "moment";
import {
  TwitterIcon,
  TwitterShareButton,
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import { useNavigate } from "react-router-dom";
import XSvgIcon from "../../assets/XIcon"

const DetailPage = () => {
  const { slug } = useParams();
  const [post, setPost] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const ROOT_URL = `${window.location.protocol}//${window.location.host}`
  const shareUrl = `${ROOT_URL}/blog/${slug}`;
  const navigate = useNavigate();

  const getDetail = async () => {
    setLoading(true);
    const detail = await getPostdetail(slug);
    if (detail.response?.status >= 400) {
      setError(detail.response.data.detail);
    } else {
      setPost(detail.data);
    }
    setLoading(false);
  };

  const postContentMarkup = () => {
    return { __html: post.content };
  };
  const handleOnClick = (slug) => {
    navigate(`/blog/tags/${slug}`); 
   }

  useEffect(() => {
    getDetail();

    // eslint-disable-next-line no-unused-vars
  }, []);
  console.log("postContentMarkup", shareUrl);
  return (
    <div>
      <Header hideAuth={true} />
      {!loading ? (
        <>
      <div className="headerBox">
        <img
          className="bannerBlogImage"
          src={post.image_url}
          alt={post.title}
        />
        <div className=" header-card">
          <h2 className="headerh2 text-dark ">{post.title}</h2>
          <div className="authorBar">
            <div className="imgbox"
            style={{"margin-left":"0.5rem"}}
            >
              <img
                src="https://i.ibb.co/bNRWgd5/favicon-96x96.png"
                alt=""
                width="30"
                height="30"
              />
            </div>
            <small>
              {post.author} -{" "}
              <span> {moment(post.created_at).format("MMMM Do YYYY")}</span>
            </small>
          </div>
        </div>
      </div>

      <div className="social_icons_column hide-for-mobile">
        <TwitterShareButton url={shareUrl}>
          <XSvgIcon />
        </TwitterShareButton>
        <WhatsappShareButton url={shareUrl}>
          <WhatsappIcon size={40} round={false} />
        </WhatsappShareButton>
        <TelegramShareButton url={shareUrl}>
          <TelegramIcon size={40} round={false} />
        </TelegramShareButton>
      </div>
      </>
      ):
      (
        <div className="flex flex-jc-c mt-5">
          <div className="flex flex-dc flex-jc-c flex-ai-c mt-5 ">
            <i className="fas fa-spinner mb-1 fa-2x"></i>
            <small> Just a moment</small>
          </div>
        </div>

      )}

      {!loading ? (
        <div className="container mb-5">
          <div
            className="container "
            dangerouslySetInnerHTML={postContentMarkup()}
          ></div>
          <div className="container flex flex-ai-c " style={{marginBottom:"10rem"}}>
            <small className="mr-1">Tags: </small>
            {post.list_tags &&
              post.list_tags.map((tag, index) => {
                return (
                  <span onClick={() => handleOnClick(tag.title)} key={index} className="cursor-toggle badge badge bg-dark mr-1">
                    {tag.title}
                  </span>
                );
              })}
          </div>

          <div className="social_icons_row hide-for-desktop mt-5">
            <TwitterShareButton url={shareUrl}>
              <XSvgIcon/>
            </TwitterShareButton>
            <WhatsappShareButton url={shareUrl}>
              <WhatsappIcon size={37} round={false} />
            </WhatsappShareButton>
            <TelegramShareButton url={shareUrl}>
              <TelegramIcon size={37} round={false} />
            </TelegramShareButton>
          </div>
        </div>
      ): null}
      { loading === false ? <Footer /> : null}
    </div>
  );
};

export default DetailPage;
