export const convert_dollars_to_naira = (amount) => {
  const conversion_rate = 570.0;
  const naira_equivalent = conversion_rate * amount;
  var formatterNGR = new Intl.NumberFormat("en-NG", {
    maximumSignificantDigits: 10,
    style: "currency",
    currency: "NGN",
  });
  return formatterNGR.format(naira_equivalent);
};

export const clean_naira_format = (amount) => {
  var formatterNGR = new Intl.NumberFormat("en-NG", {
    maximumSignificantDigits: 10,
    style: "currency",
    currency: "NGN",
  });
  return formatterNGR.format(amount);
};

export const clean_dollar_format = (amount) => {
  var formatterUsd = new Intl.NumberFormat("en-US", {
    maximumSignificantDigits: 10,
    style: "currency",
    currency: "USD",
  });
  return formatterUsd.format(amount);
};

export const truncate = (str, n) => {
  return str?.length > n ? str.substr(0, n - 1) + "..." : str;
};
export const ITEMS_PER_PAGE = 6;

const allNigeriaStates = [
  "Abia",
  "Adamawa",
  "Akwa Ibom",
  "Anambra",
  "Bauchi",
  "Bayelsa",
  "Benue",
  "Borno",
  "Cross River",
  "Delta",
  "Ebonyi",
  "Edo",
  "Ekiti",
  "Enugu",
  "Gombe",
  "Imo",
  "Jigawa",
  "Kaduna",
  "Kano",
  "Kastina",
  "Kebbi",
  "Kogi",
  "Kwara",
  "Lagos",
  "Nasarawa",
  "Niger",
  "Ogun",
  "Ondo",
  "Osun",
  "Oyo",
  "Plateau",
  "Rivers",
  "Sokoto",
  "Taraba",
  "Yobe",
  "Zamfara",
  "FCT",
];

export const allNigeriaStatesOptions = allNigeriaStates.map((state) => ({
  label: state,
  value: state,
}));

export const calculateETADateRange = (currentDate) => {
  // Copy the current date to avoid modifying the original date
  var date = currentDate;
  // Counter for working days
  var workingDays = 0;
  // Add 10 working days
  while (workingDays < 10) {
    // Increment the date by one day
    date.setDate(date.getDate() + 1);
    // Check if the current day is a weekend day (Saturday or Sunday)
    if (date.getDay() !== 0 && date.getDay() !== 6) {
      workingDays++;
    }
  }
  // Format the start date (current date + 1 working day)
  var startDate = date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
  });
  // Reset the working days counter to reflect the 10 working days
  workingDays = 10;
  // Add 15 working days
  while (workingDays < 15) {
    // Increment the date by one day
    date.setDate(date.getDate() + 1);
    // Check if the current day is a weekend day (Saturday or Sunday)
    if (date.getDay() !== 0 && date.getDay() !== 6) {
      workingDays++;
    }
  }
  // Format the end date (current date + 16 working days)
  var endDate = date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
  });
  // Combine the start and end dates in a string
  var dateRange =
    startDate + " - " + endDate + ", " + new Date().getFullYear() + ".";
  return dateRange;
};

export const addCommas = (num) =>
  num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const removeNonNumeric = (num) => num.toString().replace(/[^0-9.]/g, "");
