import { Colors } from "../../utils/styles";

const styles = {
  bodyContainer: {
    marginTop: 80,
  },

  signInHeaderText: {
    fontWeight: 500,
    fontSize: 24,
    color: "#000000",
    textAlign: "center",
    marginTop: 20,
  },
  signInForm: {
    marginTop: 100,
  },
  signInFormContainer: {
    marginBottom: 20,
  },
  signInFormLabel: {
    fontWeight: 500,
    fontSize: 16,
    color: "#181743",
  },
  formInput: {
    borderRadius: 8,
    border: "1px solid #BEBDFF",
    height: 46,
    margin: "0 auto",
    width: 400,
  },
  button: {
    width: 380,
    height: 53,
    margin: "40px auto auto auto",
    display: "block",
    background: Colors.BES_PURPLE,
  },
};

export default styles;
