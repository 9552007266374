import * as React from "react";
const DebitSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13}
    height={13}
    fill="none"
    {...props}
  >
    <path
      fill="#FA999B"
      d="M.119 5.259a6.459 6.459 0 0 1 1.785-3.355A6.49 6.49 0 0 1 9.527.746a.256.256 0 0 1 .062.407L5.45 5.29c-.1.1-.261.1-.361 0l-.946-.946-.69 5.203 5.202-.69-.946-.947c-.1-.1-.1-.262 0-.361l4.137-4.138a.255.255 0 0 1 .407.062 6.525 6.525 0 0 1 .075 5.906 6.487 6.487 0 0 1-1.233 1.717 6.458 6.458 0 0 1-3.355 1.785 6.54 6.54 0 0 1-3.687-.357 6.46 6.46 0 0 1-2.15-1.428A6.46 6.46 0 0 1 .119 7.741a6.549 6.549 0 0 1 0-2.482Z"
    />
  </svg>
);
export default DebitSvg;
