import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React from "react";
import { Colors } from "../../utils/styles";
import { clean_dollar_format } from "../../utils/utilities";
import { Link } from "react-router-dom";

const PaymentOptions = ({
  paymentType,
  handleOptionChange,
  handleWalletOnChange,
  isWalletPayChecked,
  walletBalance,
  disableFlutterWavePaymentOptions,
  disableWalletPaymentOption,
}) => {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  return (
    <FormControl>
      <FormLabel id="demo-radio-buttons-group-label" className="text-muted">
        Choose Payment Method
      </FormLabel>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="female"
        name="radio-buttons-group"
      >
        <FormControlLabel
          value="naira"
          control={
            <Radio
              value="naira"
              disabled={disableFlutterWavePaymentOptions}
              onChange={handleOptionChange}
              checked={paymentType === "naira"}
              {...label}
              sx={{
                color: disableFlutterWavePaymentOptions
                  ? Colors.GRAY_MEDIUM
                  : Colors.BES_PURPLE,
                "&.Mui-checked": {
                  color: disableFlutterWavePaymentOptions
                    ? Colors.GRAY_MEDIUM
                    : Colors.BES_PURPLE,
                },
              }}
            />
          }
          label="Pay in Naira"
        />
        {/* <FormControlLabel
          value="usd"
          control={
            <Radio
              value="usd"
              disabled={disableFlutterWavePaymentOptions}
              onChange={handleOptionChange}
              checked={paymentType === "usd"}
              {...label}
              sx={{
                color: disableFlutterWavePaymentOptions
                  ? Colors.GRAY_MEDIUM
                  : Colors.BES_PURPLE,
                "&.Mui-checked": {
                  color: disableFlutterWavePaymentOptions
                    ? Colors.GRAY_MEDIUM
                    : Colors.BES_PURPLE,
                },
              }}
            />
          }
          label="Pay in USD"
        /> */}
        <div className="d-flex align-items-center">
          <FormControlLabel
            value="wallet"
            disabled={disableWalletPaymentOption}
            onChange={handleWalletOnChange}
            checked={isWalletPayChecked}
            control={
              <Checkbox
                sx={{
                  color: disableWalletPaymentOption
                    ? Colors.GRAY_MEDIUM
                    : Colors.BES_PURPLE,
                  "&.Mui-checked": {
                    color: disableWalletPaymentOption
                      ? Colors.GRAY_MEDIUM
                      : Colors.BES_PURPLE,
                  },
                }}
              />
            }
            label={`BetaWallet (${clean_dollar_format(walletBalance)})`}
          />
          {Number(walletBalance) === 0 && (
            <Link to="/wallet" style={{ textDecoration: "underline" }}>
              Fund Wallet
            </Link>
          )}
        </div>
      </RadioGroup>
    </FormControl>
  );
};

export default PaymentOptions;
