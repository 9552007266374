import * as React from "react";
const SvgShopMobile = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={169}
    height={170}
    fill="none"
    {...props}
  >
    <circle cx={84.368} cy={85.244} r={84.368} fill="#FED6D6" />
    <path
      fill="#3F3B7F"
      d="m52.202 48.935 5.27.894 3.105 83.873-10.723-4.262 2.348-80.505ZM59.506 49.443l3.09 84.392 61.08-6.339-2.014-79.595-17.644.262.231 2.68c1.141.567 1.909 1.655 2.017 2.922.174 2.001-1.372 3.764-3.445 3.932-2.074.168-3.9-1.325-4.074-3.326-.11-1.267.462-2.462 1.488-3.205l-.254-2.943-3.04.046.016-7.55c.009-4.372-2.565-8.171-6.327-10.069 4.852-.348 9.115 3.152 9.52 7.841l1.141 13.185c-.992.47-1.637 1.487-1.54 2.614.127 1.469 1.464 2.561 2.986 2.438 1.523-.123 2.653-1.413 2.527-2.883-.098-1.126-.909-2.025-1.967-2.33l-1.14-13.187c-.499-5.782-5.782-10.095-11.773-9.612l-1.178.095c-.983.079-1.931.292-2.834.608-.373-.036-.75-.058-1.132-.058l-1.272-.003c-3.173-.005-6.15 1.19-8.379 3.37-2.229 2.177-3.432 5.062-3.387 8.123l.12 8.26-12.816.33v.002Zm20.018 3.267-.39-3.774-4.645.12-.119-8.232c-.037-2.496.943-4.849 2.761-6.625a9.62 9.62 0 0 1 5.64-2.67c-.366.324-.72.665-1.043 1.039-1.885 2.186-2.761 4.947-2.469 7.777l1.334 12.925c-1.126.412-1.886 1.493-1.78 2.708.127 1.47 1.464 2.56 2.986 2.439 1.522-.124 2.654-1.414 2.526-2.883-.089-1.03-.778-1.864-1.705-2.235l-1.356-13.148c-.239-2.306.476-4.558 2.013-6.34a8.819 8.819 0 0 1 3.082-2.285c4.75.549 8.443 4.46 8.433 9.189l-.017 7.587-11.642.173.434 4.206c1.002.602 1.66 1.62 1.76 2.773.173 2-1.373 3.764-3.446 3.932-2.074.166-3.9-1.325-4.074-3.326-.116-1.345.567-2.63 1.715-3.349l.002-.001Z"
    />
    <path
      fill="#EEF"
      d="M119.939 138.803c11.174 0 20.231-8.985 20.231-20.068 0-11.084-9.057-20.069-20.231-20.069-11.173 0-20.231 8.985-20.231 20.069 0 11.083 9.058 20.068 20.231 20.068Z"
    />
    <path
      fill="#EE4347"
      d="M123.287 119.223c.728 1.542 1.41 2.916 2.022 4.318.222.513.404 1.099.383 1.643-.042.998-.847 1.752-1.857 1.933-.956.171-1.913-.29-2.398-1.242-.608-1.196-1.15-2.428-1.727-3.642-.158-.335-.344-.659-.566-1.078-.524.527-.981.984-1.442 1.441-.724.722-1.575.956-2.549.583-.943-.36-1.4-1.134-1.368-2.303.098-3.603.214-7.206.302-10.81.025-1.001.352-1.803 1.301-2.257.957-.457 1.805-.181 2.585.436 2.88 2.268 5.761 4.535 8.648 6.799.809.635 1.171 1.42.858 2.424-.313.991-1.038 1.49-2.061 1.588-.654.062-1.308.108-2.131.174v-.007Z"
    />
  </svg>
);
export default SvgShopMobile;
