import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import Accordion from "react-bootstrap/Accordion";
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import "./styles.scss";

const FaqPage = () => {
  return (
    <>
      <Header />
      <div className="jumbotron jumbotron-fluid jumbotron-header">
        <Container className='mx-1'>
        <div className='custom_container'>
        <p className="display-4">FAQ</p>
        <p>Frequently Asked Questions</p>
        </div>
        </Container>
      </div>
      <div className="mt-5 mb-5">
        <Container>
          <Row>
            <Col lg={12}></Col>
            <Col lg={12}>
              <Accordion defaultActiveKey="0" flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    How Does the Beta-eshopping website work?
                  </Accordion.Header>
                  <Accordion.Body>
                  Beta-Eshopping website makes it easy for you to purchase your favorite products from US & European stores - all from the comfort of your home or place of work.<br/>
                  <br/>All you have to do is download {" "}
                  <a
                      href="https://www.beta-eshopping.com/#"
                      target="_blank"
                      rel="noreferrer"
                    >
                    Beta-Eshopping Chrome extension
                    </a> {" "}
                    and pin it to your Browser or download the app from 
                    {" "}
                  <a
                      href="https://play.google.com/store/apps/details?id=com.betaeshopping"
                      target="_blank"
                      rel="noreferrer"
                    >
                   Google Playstore
                    </a> {" "}
                    or 
                    {" "}
                  <a
                      href="https://apps.apple.com/ng/app/beta-eshopping/id1642708607"
                      target="_blank"
                      rel="noreferrer"
                    >
                   Appstore
                    </a> {" "}
                    , and start shopping. 
                  You can also shop via custom order for stores not supported by our extension.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    Do I need credit card to make purchases?
                  </Accordion.Header>
                  <Accordion.Body>
                  Yes, you do. We have several options for making payments: 
                  You can either pay with your Naira or dollar card or do a Bank Transfer 
                  to either of our Naira or Dollar accounts.
                    
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    Where can I pick-up my items?
                  </Accordion.Header>
                  <Accordion.Body>
                  There are two options available for delivery. 
                  You have the choice of either picking up your items at one of our centers across 
                  Nigeria or having them delivered to your doorstep.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>What is Custom Order?</Accordion.Header>
                  <Accordion.Body>
                  If you want to purchase items from stores that aren't supported by our Beta-Eshopping extension/app, 
                  you can place a {" "}
                    <a
                      href="https://www.beta-eshopping.com/custom-order"
                      target="_blank"
                      rel="noreferrer"
                    >
                    Custom Order 
                    </a> 
                    instead. This feature also allows you to combine items from multiple stores into a single order. To get started, 
                    simply fill out the order form with the URL, name, and other necessary details of the products you want to buy. 
                    Then, select your shipping preferences and place your order. 
                    With custom orders, you can also shop from multiple stores at once, making it easier than ever to get everything you need.

                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    What are your shipping fees and other costs?
                  </Accordion.Header>
                  <Accordion.Body>
                  The cost of shipping your items is calculated using an algorithm that takes into account their weight and volumetric dimensions. 
                  In addition to shipping costs, other expenses may include tax & duties, 
                  and a handling fee of $5 for items below $100 or $10 for items that cost above $100.

                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                    Why is the shipping fee different for two identical items?
                  </Accordion.Header>
                  <Accordion.Body>
                  The Shipping cost may vary if two similar items are coming from different region or stores, 
                  as each store have different charges aside from the cost of the item itself.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header>
                    How do I know when my order has arrived?
                  </Accordion.Header>
                  <Accordion.Body>
                  To keep tabs on your order status, simply access your profile and click on "Track Order" on the home page. 
                  This will give you updates on the status of your order. 
                  Additionally, our courier service, Beta Courier, will get in touch with you to provide details 
                  about the delivery of your items once they arrive in Nigeria.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                  <Accordion.Header>How long does it take to get my orders?</Accordion.Header>
                  <Accordion.Body>
                  Since we partner with multiple merchants, the time it takes for your items to arrive can vary. 
                  However, in general, you can expect your item(s) to be delivered within 10-15 working days, 
                  depending on the specific merchant you're purchasing from.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                  <Accordion.Header>
                    Do you have a return policy?
                  </Accordion.Header>
                  <Accordion.Body>
                    Yes we do, There are 2 types of returns: <br />
                    <br /> 1. <b>Wrong item received:</b> In the event that you receive the wrong item, 
                    Beta-Eshopping will provide you with instructions on how to make a return. 
                    Please reach out to us via email at <b>info@beta-eshopping.com</b> for further guidance.

                    <br />
                    <br />

                    2. <b> Change of mind:</b> If you receive the item but later decide to return it, 
                    you will be responsible for the restocking fee and the shipping costs associated with returning it. 
                    These costs will be based on the merchant's specific return policy.

                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9">
                  <Accordion.Header>What items cannot be shipped?</Accordion.Header>
                  <Accordion.Body>
                    For safety and regulatory reasons, we do not ship items
                    listed below;
                    <ol>
                      <li>
                        Hazardous materials/Chemicals/Radioactive material
                      </li>
                      <li>Paint/Ink and toners</li>
                      <li>Medication/Prescription drugs</li>
                      <li>Fire Arms/weapons/gun powder etc.</li>
                      <li>Alcohol/liquid/gas</li>
                      <li>Flammable substances</li>
                      <li>Lighter folds and matches/Fire extinguishers</li>
                      <li>Live Products including food, plants, animals</li>
                      <li>Food products/Perishable goods</li>
                      <li>Human remains</li>
                      <li>Lithium-ion Batteries</li>
                      <li>Antiques/Artworks</li>
                      <li>Credit/Debit card and gift vouchers</li>
                    </ol>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="10">
                  <Accordion.Header>How can I edit my cart?</Accordion.Header>
                  <Accordion.Body>
                  To avoid a mix-up, it is best to send in a new order. 
                  Please keep in mind that once an order has been paid for, 
                  it can no longer be edited.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="11">
                  <Accordion.Header>
                    Can I shop from different stores on the website?
                  </Accordion.Header>
                  <Accordion.Body>
                  Certainly! You can make use of our {" "}
                  <a
                      href="https://www.beta-eshopping.com/custom-order"
                      target="_blank"
                      rel="noreferrer"
                    >
                    Custom Order 
                    </a> 
                    {" "}
                  option to order from multiple stores.

                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
            <Col lg={2}></Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default FaqPage;