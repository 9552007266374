import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./home";
import LoginPage from "./login";
import RegisterPage from "./register";
import StoresPage from "./store";
import NewPasswordPage from "./newpassword";
import OTPpage from "./verify-otp";
import ResendOTP from "./resend-otp";
import ResetPasswordPage from "./resetpassword";
import ResetPasswordOTPpage from "./reset-password-otp"
import LoadCartPage from "./loadcart";
import CustomOrderPage from "./customorder";
import ProfilePage from "./profile";
import CurrentRatePage from "./current-rate";
import PendingOrderPage from "./pendingorders";
import CheckOutPage from "./checkout";
import SuccessPage from "./paymentSuccess";
import OrderHistoryPage from "./orderhistory";
import VerifyAccountPage from "./verifyaccount";
import ContactUsPage from "./contactus";
import HowItWorksPage from "./howitworks";
import AboutUsPage from "./aboutus";
import AboutUsPageMobile from "./aboutus-mobile";
import FaqPage from "./faq";
import FaqPageMobile from "./faq-mobile";
import ContactUsPageMobile from "./contactus-mobile";
import TCPageMobile from "./tc-mobile";
import BlogPage from "./blog";
import DetailPage from "./blog/detail";
import CategoryPage from "./blog/categories";
import SearchPage from "./blog/search";
import TagsPage from "./blog/tags";
import VerifyRedirect from "./verify-redirect";
import NotFound from "./404-not-found";
import CartCode from "./cart-code";
import Google from "./social-auth";
import CompleteRegistration from "./complete-registration";
import Wallet from "./wallet";
import FundWallet from "./wallet/fundWallet";
import DebitCardPage from "./wallet/debitCard";
import ViewTransactions from "./wallet/viewTransactions";
import ReferralPage from "./profile/refferal";

const reload = () => window.location.reload();

const Pages = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/signin" element={<LoginPage />} />
        <Route exact path="/register" element={<RegisterPage />} />
        <Route exact path="/stores" element={<StoresPage />} />
        <Route exact path="/resetpassword" element={<ResetPasswordPage />} />
        <Route exact path="/reset-password-otp" element={<ResetPasswordOTPpage />} />
        <Route exact path="/newpassword" element={<NewPasswordPage />} />
        <Route exact path="/otp" element={<OTPpage/>} />
        <Route exact path="/resend-otp" element={<ResendOTP/>} />
        <Route exact path="/load-cart" element={<LoadCartPage />} />
        <Route exact path="/custom-order" element={<CustomOrderPage />} />
        <Route exact path="/profile" element={<ProfilePage />} />
        <Route exact path="/current-rate" element={<CurrentRatePage />} />
        <Route exact path="/pending-orders" element={<PendingOrderPage />} />
        <Route exact path="/history" element={<OrderHistoryPage />} />
        <Route
          exact
          path="/order/:order_id/checkout"
          element={<CheckOutPage />}
        />
        <Route exact path="/payment" element={<SuccessPage />} />
        <Route exact path="/verify-account" element={<VerifyAccountPage />} />
        <Route exact path="/contact-us" element={<ContactUsPage />} />
        <Route
          exact
          path="/mobile/contact-us"
          element={<ContactUsPageMobile />}
        />
        <Route exact path="/about-us" element={<AboutUsPage />} />
        <Route exact path="/mobile/about-us" element={<AboutUsPageMobile />} />
        <Route exact path="/how-it-works" element={<HowItWorksPage />} />
        <Route exact path="/faq" element={<FaqPage />} />
        <Route exact path="/verify-email" element={<VerifyRedirect />} />
        <Route exact path="/blog" element={<BlogPage />} />
        <Route exact path="/blog/:slug" element={<DetailPage />} />
        <Route exact path="/blog/categories/:slug" element={<CategoryPage />} />
        <Route exact path="/blog/search/:slug" element={<SearchPage />} />
        <Route exact path="/blog/tags/:slug" element={<TagsPage />} />
        <Route exact path="/cart/receive" element={<CartCode />} />
        <Route exact path="/mobile/faq" element={<FaqPageMobile />} />
        <Route
          exact
          path="/mobile/terms-conditions"
          element={<TCPageMobile />}
        />
        <Route
          path="/.well-known/apple-app-site-association"
          onEnter={reload}
        />
        <Route path="*" element={<NotFound />} />

        <Route exact path="/google/" element={<Google />} />
        <Route
          exact
          path="/complete-registration/"
          element={<CompleteRegistration />}
        />
        <Route exact path="/wallet" element={<Wallet />} />
        <Route exact path="/wallet/fund" element={<FundWallet />} />
        <Route exact path="/wallet/fund/card" element={<DebitCardPage />} />
        <Route
          exact
          path="/wallet/transactions"
          element={<ViewTransactions />}
        />
         <Route
          exact
          path="/refer-a-friend"
          element={<ReferralPage />}
        />
      </Routes>
    </Router>
  );
};
export default Pages;
