import React, { useContext, useEffect, useState, useCallback } from "react";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { ROUNDED_BUTTON } from "../../utils/styles/buttons";

import Header from "../../components/Header";
import SideBar from "../../components/SideBar";
import OrderItem from "../../components/OrderItem";
import LoginPage from "../login";
import AuthContext from "../../context/authContext";
import { getOrders, getOrder, deleteOrder } from "../../services/api";
import { clean_dollar_format } from "../../utils/utilities";
import { Colors } from "../../utils/styles";
// import { useLocation } from "react-router-dom";

import styles from "./style";

const PendingOrderPage = () => {
  const [pendingOrders, setPendingOrders] = useState([]);
  const [error, setError] = useState("");
  const [orderDetails, setOrderDetails] = useState({});
  const { isAuthenticated } = useContext(AuthContext);
  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(true);
  const [deleteSuccess, setDelSuccess] = useState(false);

  // const urlPath = useLocation().pathname;

  const handleClose = () => setShow(false);

  const handleShow = (orderId) => {
    getOrderDetails(orderId);
    setShow(true);
  };

  const getOrderDetails = async (order_id) => {
    const orderDetailsRes = await getOrder(order_id);

    if (orderDetailsRes.response?.status >= 400) {
      setError(orderDetailsRes.response.data.detail);
      setError(true);
    } else {
      setOrderDetails(orderDetailsRes.data);
    }
  };

  const getPendingOrders = useCallback(async () => {
    const orders_res = await getOrders("awaiting_payment");

    if (orders_res.response?.status >= 400) {
      setError(orders_res.response.data.detail);
    } else {
      setPendingOrders(orders_res.data);
    }
  }, []);

  useEffect(() => {
    let isSubscribed = true;
    getPendingOrders();

    // eslint-disable-next-line no-unused-vars
    return () => (isSubscribed = false);
  }, [getPendingOrders]);

  const handleOrderDelete = async (order_id) => {
    const data = { status: "Cancelled" };
    const deleteOrderRes = await deleteOrder(order_id, data);

    if (deleteOrderRes.status !== 204) {
      setError("Item could not be deleted");
    } else {
      setDelSuccess(true);
    }
  };

  if (!isAuthenticated) {
    return <LoginPage path="/pending-orders" />;
  }

  return (
    <div>
      <Header hideAuth={true} />
      <section style={{ height: "90vh", marginTop: 20 }}>
        <Row style={{ height: "inherit", overflow: "auto" }}>
          <Col
            lg={2}
            style={{
              boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
              padding: "10px 0 0 0",
            }}
            className="profile-sidebar"
          >
            <SideBar path="/pending-orders" />
          </Col>
          <Col lg={9} style={{ marginLeft: 40 }}>
            <Modal
              show={show}
              size="lg"
              onHide={handleClose}
              backdrop="static"
              centered
            >
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body style={{ height: "inherit" }}>
                <OrderItem order={{ ...orderDetails }} />
                <Row>
                  <Col lg={4}>
                    {" "}
                    <p>
                      <span style={{ fontWeight: "bold" }}>Delivery Type:</span>{" "}
                      {orderDetails.delivery_type === "PUP"
                        ? "Pickup"
                        : "Door-to-Door"}
                    </p>
                  </Col>
                  <Col lg={8}>
                    {" "}
                    <p>
                      <span style={{ fontWeight: "bold" }}>
                        Delivery Address:
                      </span>{" "}
                      {orderDetails.shipping_address}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    {orderDetails.is_currency_converted ? (
                      <p>
                        <span style={{ fontWeight: "bold" }}>
                          Shipping Fee:
                        </span>{" "}
                        ${orderDetails.shipping_fee}
                      </p>
                    ) : null}
                  </Col>
                  <Col lg={4}>
                    {orderDetails.is_currency_converted ? (
                      <p>
                        <span style={{ fontWeight: "bold" }}>
                          Handling Fee:
                        </span>{" "}
                        ${orderDetails.handling_fee}
                      </p>
                    ) : null}
                  </Col>
                  <Col lg={4}>
                    {orderDetails.is_currency_converted ? (
                      <p>
                        <span style={{ fontWeight: "bold" }}>
                          Tax & Duties:
                        </span>{" "}
                        ${orderDetails.tax_duties}
                      </p>
                    ) : null}
                  </Col>
                </Row>
              </Modal.Body>
            </Modal>
            {error && (
              <Modal
                show={modalShow}
                size="md"
                onHide={handleClose}
                backdrop="static"
                centered
              >
                {" "}
                <Modal.Header>
                  <h5>Session Expired</h5>
                </Modal.Header>
                <Modal.Body style={{ height: "inherit" }}>
                  <p>{error}</p>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    size="md"
                    style={{
                      ...ROUNDED_BUTTON,
                      width: "fit-content",
                      height: 45,
                      margin: "0 auto",
                    }}
                    onClick={() => {
                      setModalShow(false);
                      setTimeout(() => window.location.reload(false), 500);
                    }}
                  >
                    Sign in to continue
                  </Button>
                </Modal.Footer>
              </Modal>
            )}
            {deleteSuccess && (
              <Modal
                show={deleteModalShow}
                size="sm"
                onHide={() => {
                  setDelSuccess(false);
                  handleClose();
                }}
                backdrop="static"
                centered
              >
                {" "}
                <Modal.Header>
                  <h5>Success</h5>
                </Modal.Header>
                <Modal.Body style={{ height: "inherit" }}>
                  <p className="text-center"> Order deleted successfully!</p>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    size="md"
                    style={{
                      ...ROUNDED_BUTTON,
                      width: 70,
                      height: 40,
                      margin: "0 auto",
                      fontWeight: "bold",
                    }}
                    onClick={() => {
                      setDeleteModalShow(false);
                    }}
                  >
                    OK
                  </Button>
                </Modal.Footer>
              </Modal>

              // <Alert
              //   variant="success"
              //   style={{ margin: "0 auto", textAlign: "center", width:"fit-content" }}
              //   onClose={() => setDelSuccess(false)}
              //   dismissible
              // >
              //   Order deleted successfully!
              // </Alert>
            )}
            <h5 style={styles.pendingOrdersHeader}>Pending Orders</h5>
            {pendingOrders.map((order) => {
              return (
                <Card
                  style={{
                    width: "50rem",
                    marginTop: 40,
                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                  }}
                  className="pending-orders-card"
                  key={order.id}
                >
                  <Card.Body>
                    <Card.Title className="mb-4 fw-bold fs-6">
                      ORDER ID: {order.order_id}
                    </Card.Title>
                    <Row>
                      {order.is_currency_converted && !order.status ? (
                        <Col lg={6}>
                          <Card.Subtitle className="mb-4 fw-bold fs-6">
                            Price: {clean_dollar_format(order.order_total)}
                          </Card.Subtitle>
                          <Card.Text className="text-muted">
                            Awaiting payment
                          </Card.Text>
                          <Button
                            href={`/order/${order.order_id}/checkout`}
                            style={{
                              background: Colors.BES_PURPLE,
                              border: "None",
                              marginRight: 20,
                              fontSize: 12,
                              fontWeight: 600,
                            }}
                          >
                            Review Payment
                          </Button>
                          <Button
                            variant="outlined"
                            style={{
                              borderColor: Colors.BES_PURPLE,
                              fontSize: 12,
                              fontWeight: 600,
                            }}
                            onClick={() => handleShow(order.order_id)}
                          >
                            View Order
                          </Button>
                        </Col>
                      ) : order.is_currency_converted && order.status === 4 ? (
                        <Col lg={6}>
                          <Card.Subtitle className="mb-4 fw-bold fs-6">
                            Price:{" "}
                            {clean_dollar_format(order.additional_payment)}
                          </Card.Subtitle>
                          <Card.Text className="text-muted">
                            Awaiting payment
                          </Card.Text>
                          <Button
                            href={`/order/${order.order_id}/checkout`}
                            style={{
                              background: Colors.ALERT,
                              borderColor: Colors.ALERT,
                              marginRight: 20,
                              fontSize: 12,
                              fontWeight: 600,
                            }}
                          >
                            Make Additional Payment
                          </Button>
                          <Button
                            variant="outlined"
                            style={{
                              borderColor: Colors.BES_PURPLE,
                              fontSize: 12,
                              fontWeight: 600,
                            }}
                            onClick={() => handleShow(order.order_id)}
                          >
                            View Order
                          </Button>
                        </Col>
                      ) : (
                        <Col lg={6}>
                          <Card.Text className="text-muted">
                            Order is awaiting confirmation and payment details
                          </Card.Text>
                          <Button
                            variant="outlined"
                            style={{
                              borderColor: Colors.BES_PURPLE,
                              fontSize: 12,
                              fontWeight: 600,
                            }}
                            onClick={() => handleShow(order.order_id)}
                          >
                            View Order
                          </Button>
                        </Col>
                      )}
                      <Col
                        lg={6}
                        style={{ display: "flex", alignItems: "flex-end" }}
                      >
                        {/* remove delete button for additional payment */}
                        {order.status !== 4 && (
                          <FontAwesomeIcon
                            icon={faTrash}
                            size="lg"
                            color={Colors.ALERT}
                            style={{
                              marginLeft: "auto",
                              marginTop: -50,
                              cursor: "pointer",
                            }}
                            onClick={() => handleOrderDelete(order.order_id)}
                          />
                        )}
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              );
            })}
          </Col>
        </Row>
      </section>
    </div>
  );
};

export default PendingOrderPage;
