import React, { useState, useContext, useEffect } from "react";
import { Form, Container, Row, Col, Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faPlusCircle,
  faMinusCircle,
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
  faLuggageCart,
  faCartPlus,
} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "../../components/Tooltip";
import PhoneInput from "react-phone-number-input";
import { allNigeriaStatesOptions } from "../../utils/utilities";

import AuthContext from "../../context/authContext";
import Header from "../../components/Header";
import LoginPage from "../login";
import { createOrder } from "../../services/api";

import { ROUNDED_BUTTON } from "../../utils/styles/buttons";

import { Colors } from "../../utils/styles";
import styles from "./style";
import "./style.scss";
import { useNavigate } from "react-router-dom";

const customValues = {
  item_url: "",
  title: "",
  size: "",
  color: "",
  quantity: 0,
  code: "",
  info: "",
};

const CustomOrderPage = (props) => {
  const [order, setOrder] = useState(null);
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const [shippingType, setShippingType] = useState();
  const [shippingAd, setShippingAd] = useState("");

  const [disabled, setDisabled] = useState(true);
  const [modalShow, setModalShow] = React.useState(true);
  const [toggleSwitch, setToggleSwitch] = useState(false);
  const [addPhoneNo, setaddPhoneNo] = useState(false);
  const [phoneNo, setphoneNo] = useState(0);

  const { isAuthenticated } = useContext(AuthContext);
  const [orderList, setOrderList] = useState([{ ...customValues }]);

  const [selectedState, setSelectedState] = useState(null);
  const navigate = useNavigate();

  const unauthErr =
    "You have been logged out automatically. Click the button below to sign in.";

  useEffect(() => {}, [orderList]);

  const handleClose = () => {
    setShow(false);
    setOrderList([{ ...customValues }]);
    setTimeout(() => window.location.reload(), 200);
    navigate("/history");
  };

  const handleShow = () => {
    setShow(true);
  };

  const validateForm = () => {
    let validate = false;
    for (let i = 0; i < orderList.length; i++) {
      if (
        orderList[i].item_url.length > 0 &&
        orderList[i].title.length > 0 &&
        orderList[i].quantity > 0
      ) {
        validate = true;
      } else {
        validate = false;
      }
    }
    return validate;
  };

  const validatePlaceOrder = () => {
    let validate = true;

    if (shippingType === "pickup") {
      if (shippingAd.trim() !== "") {
        validate = false;
      }
    }

    if (shippingType === "door-to-door") {
      if (
        !addPhoneNo &&
        phoneNo === 0 &&
        shippingAd.trim() !== "" &&
        selectedState != null
      ) {
        validate = false;
      } else if (
        addPhoneNo &&
        phoneNo > 0 &&
        shippingAd.trim() !== "" &&
        selectedState != null
      ) {
        validate = false;
      } else if (
        !addPhoneNo &&
        shippingAd.trim() !== "" &&
        selectedState != null
      ) {
        validate = false;
      } else {
        validate = true;
      }
    }
    return validate;
  };

  const shippingAddress = () => {
    if (phoneNo !== 0 && addPhoneNo && selectedState != null) {
      return (
        shippingAd +
        ", " +
        selectedState +
        " State " +
        "(Recipient No " +
        phoneNo +
        ")"
      );
    } else if (selectedState != null) {
      return shippingAd + ", " + selectedState + " State";
    } else {
      return shippingAd;
    }
  };

  const handlePlaceOrder = async () => {
    const orderData = {
      shipping_type: shippingType,
      type: "custom_order",
      items: orderList,
      shipping_address: shippingAddress(),
    };

    const order = await createOrder(orderData);

    if (order.response?.status >= 400) {
      const err = order.response.data;
      const errMsg = Object.keys(err).map((key) => {
        return `${err[key]}`;
      });
      setError(errMsg);
      setModalShow(true);
    } else {
      setOrder(order.data);
      setError("");
      handleShow();
    }
    // Allow async function to execute before redirect
  };

  const handleShippingType = (e) => {
    const val = e.target.value;
    setShippingType(val);
    if (val === "door-to-door") {
      setShippingAd("");
      if (shippingAd.length > 0 && phoneNo > 0) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    } else if (val === "pickup") {
      setDisabled(true);
    } else {
      setShippingType();
      setDisabled(true);
    }
  };

  const handleTextArea = (e) => {
    setShippingAd(e.target.value);
    setDisabled(false);
    if (e.target.value.trim() !== "") {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const handleAddPhoneNumber = () => {
    setaddPhoneNo(!addPhoneNo);
  };

  const handleChangeNumber = (e) => {
    setphoneNo(e);
  };
  // handle input change.
  // method used on change of the input fields.
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...orderList];
    list[index][name] = value;
    if (value === 0) {
      setDisabled(true);
    }
    setOrderList(list);
  };

  // handle click event of the Remove button
  // remove an order from the list based on the index
  const handleRemoveClick = (index) => {
    const list = [...orderList];
    list.splice(index, 1);
    setOrderList(list);
  };

  const handleToggleSwitch = () => {
    setToggleSwitch(!toggleSwitch);
  };

  // handle click event of the Add button
  // add the new order in the list
  const handleAddClick = () => {
    setOrderList([...orderList, { ...customValues }]);
    setDisabled(true);
  };

  const handlePickupOption = (e, index) => {
    const val = e.target.value;
    if (val.toLowerCase() === "please select a pick up location") {
      setShippingAd("");
      setDisabled(true);
    } else {
      setShippingAd(e.target.value);
      setDisabled(false);
    }
  };

  if (!isAuthenticated) {
    return <LoginPage path="/custom-order" />;
  }

  return (
    <section style={{ height: "100vh" }}>
      <Header hideAuth={true} isAuthenticated={isAuthenticated} />
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ justifyContent: "center" }}>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontWeight: 600 }}
          >
            Order Placed Successfully
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ textAlign: "center", alignSelf: "center" }}
          className="mb-5"
        >
          <FontAwesomeIcon
            icon={faCheckCircle}
            size="6x"
            color={Colors.BES_PURPLE}
            className="mb-5"
          />
          <p style={{ fontWeight: 600 }}>Order ID: {order?.order_id}</p>
          <span style={{ width: 20 }}>
            Your order has been received, it will be ready for payment within 2
            working hours. Kindly check pending orders in your profile if you
            did not get a mail notification.
          </span>
          <p>Thank you.</p>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "center" }}>
          <Button
            onClick={handleClose}
            style={{
              borderRadius: 10,
              backgroundColor: Colors.BES_PURPLE,
              color: "#FFFFFF",
              fontSize: 16,
              width: 150,
              marginTop: 20,
              height: 50,
              border: "none",
            }}
          >
            Done
          </Button>
        </Modal.Footer>
      </Modal>

      {error && (
        <Modal
          {...props}
          size="md"
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={modalShow}
          onHide={() => setModalShow(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Oops!</Modal.Title>
          </Modal.Header>
          <Modal.Body>{error}</Modal.Body>
          <Modal.Footer>
            {error[0] === unauthErr ? (
              <Button
                size="md"
                style={{
                  ...ROUNDED_BUTTON,
                  width: "fit-content",
                  height: 40,
                  margin: "0 auto",
                }}
                onClick={() => {
                  setModalShow(false);
                  setError("");
                  window.location.reload(false);
                }}
              >
                Sign in to continue!
              </Button>
            ) : (
              <Button
                size="md"
                style={{
                  ...ROUNDED_BUTTON,
                  width: 100,
                  height: 40,
                  margin: "0 auto",
                }}
                onClick={() => {
                  setModalShow(false);
                  setError("");
                }}
              >
                Got it!
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      )}
      <Container className="mt-5" style={{ height: "75%" }}>
        {toggleSwitch === false ? (
          <div style={{ maxHeight: "80%", overflow: "auto" }}>
            <div className="box_header flex flex-jc-c  mb-3">
              <div>
                <h5>
                  <FontAwesomeIcon
                    style={{ marginRight: "0.5rem", fontSize: "1.2rem" }}
                    icon={faCartPlus}
                  />
                  Custom Order Form
                </h5>
              </div>
            </div>
            {orderList.map((item, i) => {
              return (
                <Row
                  key={i}
                  className=" row-box-shadow align-items-center mb-3"
                >
                  <Col lg={2}>
                    <p>Item Url</p>
                    <Form.Control
                      name="item_url"
                      value={item.item_url}
                      onChange={(e) => handleInputChange(e, i)}
                      style={styles.formInput}
                    />
                  </Col>
                  <Col lg={2}>
                    <p>Item Name</p>
                    <Form.Control
                      name="title"
                      value={item.title}
                      onChange={(e) => handleInputChange(e, i)}
                      style={styles.formInput}
                      required
                    />
                  </Col>
                  <Col lg={1} xs={6}>
                    <p>Size</p>
                    <Form.Control
                      name="size"
                      style={styles.formInput}
                      value={item.size}
                      onChange={(e) => handleInputChange(e, i)}
                      required
                    />
                  </Col>
                  <Col lg={2}>
                    <p>Color</p>
                    <Form.Control
                      name="color"
                      style={styles.formInput}
                      value={item.color}
                      onChange={(e) => handleInputChange(e, i)}
                      required
                    />
                  </Col>
                  <Col lg={1} xs={6}>
                    <p>Quantity</p>
                    <input
                      type="number"
                      name="quantity"
                      min="1"
                      className="text-center"
                      style={styles.formInput}
                      value={item.quantity}
                      onChange={(e) => handleInputChange(e, i)}
                    ></input>
                    {/* <Form.Control
                    type="number"
                    name="quantity"
                    style={styles.formInput}
                    value={item.quantity}
                    onChange={(e) => handleInputChange(e, i)}
                  /> */}
                  </Col>
                  <Col lg={1} xs={6}>
                    <p>Code</p>
                    <Form.Control
                      style={styles.formInput}
                      name="code"
                      value={item.code}
                      onChange={(e) => handleInputChange(e, i)}
                      required
                    />
                  </Col>
                  <Col lg={3}>
                    <p>Additional Information</p>
                    <Form.Control
                      name="info"
                      value={item.info}
                      onChange={(e) => handleInputChange(e, i)}
                      style={styles.formInput}
                    />
                  </Col>
                  <Col className="d-flex">
                    {orderList.length !== 1 && (
                      <div>
                        <Tooltip content="Remove Item" direction="top">
                          <FontAwesomeIcon
                            style={{ marginRight: 5, marginTop: 30 }}
                            icon={faMinusCircle}
                            size="lg"
                            color="#F14336"
                            onClick={() => handleRemoveClick(i)}
                            className="add-icon"
                          />
                        </Tooltip>
                      </div>
                    )}
                    {orderList.length - 1 === i && (
                      <div>
                        <Tooltip content="Add Item" direction="top">
                          <FontAwesomeIcon
                            style={{ marginTop: 30 }}
                            icon={faPlusCircle}
                            size="lg"
                            color={Colors.BES_PURPLE}
                            onClick={handleAddClick}
                            className="add-icon"
                          />
                        </Tooltip>
                      </div>
                    )}
                  </Col>
                </Row>
              );
            })}
            <div className="d-flex justify-content-center">
              <Button
                type="button"
                disabled={!validateForm()}
                size="lg"
                style={{
                  borderRadius: 7,
                  border: "none",
                  backgroundColor: Colors.BES_PURPLE,
                  color: "#FFFFFF",
                  fontSize: 16,
                  width: 200,
                  marginTop: 20,
                  marginBottom: 30,
                  height: 50,
                  // position: "absolute",
                  left: "40%",
                  bottom: 0,
                }}
                onClick={handleToggleSwitch}
              >
                Next
                <FontAwesomeIcon
                  style={{ marginLeft: "1rem", fontSize: "1rem" }}
                  icon={faArrowAltCircleRight}
                />
              </Button>
            </div>
          </div>
        ) : (
          <div className="address-bar">
            <div className="box">
              <div className="box_header flex flex-jc-sb flex-ai-c">
                <div
                  className="flex flex-jc-c cursor-toggle gap-2"
                  onClick={handleToggleSwitch}
                >
                  <FontAwesomeIcon
                    style={{
                      color: Colors.BES_PURPLE,
                      fontSize: "1.2rem",
                    }}
                    icon={faArrowAltCircleLeft}
                  />
                  <h6>Back</h6>
                </div>

                <div>
                  <h6 className="fw-bold">
                    <FontAwesomeIcon
                      style={{
                        marginRight: "0.5rem",
                        fontSize: "1.2rem",
                        color: Colors.BES_PURPLE,
                      }}
                      icon={faLuggageCart}
                    />
                    Shipping Details
                  </h6>
                </div>
              </div>
              <select
                value={shippingType}
                onChange={handleShippingType}
                className="form-select form-select-lg mt-3 mb-3"
                style={{ fontSize: 16 }}
                // check if the length of the array is greater than one
                disabled={!validateForm()}
              >
                <option>Please select a shipping type</option>
                <option>pickup</option>
                <option>door-to-door</option>
              </select>

              {shippingType === "door-to-door" && (
                <div>
                  <textarea
                    placeholder="Enter delivery address and state"
                    className="mb-3 mt-3 form-control form-control-lg"
                    type="text"
                    onChange={(e) => handleTextArea(e)}
                    style={{ fontSize: 16 }}
                  />

                  <select
                    value={selectedState}
                    onChange={(event) => setSelectedState(event.target.value)}
                    className="form-select form-select-lg mt-3 mb-3"
                    style={{ fontSize: 16 }}
                  >
                    <option value="">Please select a state</option>
                    {allNigeriaStatesOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.value}
                      </option>
                    ))}
                  </select>

                  <div
                    className="d-flex align-items-center mt-4"
                    style={{ width: "100%", marginBottom: "0.5rem" }}
                  >
                    <input
                      style={{ marginRight: "0.4rem" }}
                      type="checkbox"
                      id="recipient-id"
                      name="recipient-id"
                      value="recipient-id"
                      onChange={() => handleAddPhoneNumber()}
                      checked={addPhoneNo}
                    />

                    <label>
                      Add Recipient{" "}
                      <i
                        className="fa fa-question-circle text-danger"
                        aria-hidden="true"
                        title=" If the phone number you have for the recipient is different 
                      from the one on your profile, please give us the correct phone number for the recipient."
                      ></i>
                    </label>
                  </div>

                  {addPhoneNo === true && (
                    <PhoneInput
                      international
                      defaultCountry="NG"
                      placeholder="Enter Recipient Phone Number"
                      value={phoneNo}
                      onChange={(e) => handleChangeNumber(e)}
                      style={styles.formInput}
                      className="signupFormInput"
                    />
                  )}
                </div>
              )}
              {shippingType === "pickup" && (
                <select
                  value={shippingAd}
                  onChange={handlePickupOption}
                  className="form-select form-select-lg mt-3 mb-3"
                  style={{ fontSize: 16 }}
                >
                  <option>Please select a pick up location</option>
                  <optgroup label="Lagos">
                    <option>
                      23B, Fatai Atere Way, Matori Industrial Estate, Oshodi,
                      Lagos.
                    </option>
                  </optgroup>
                  {/* <optgroup label="Lekki">
                    <option>
                      33B, Adebayo Doherty Street, Road 14, Lekki Phase 1, Lagos.
                    </option>
                  </optgroup> */}
                  <optgroup label="FCT">
                    <option>
                      Suite 2.5, Willand plaza Wuse zone 4 opposite zenith bank,
                      Abuja.
                    </option>
                  </optgroup>
                  <optgroup label="Kaduna">
                    <option>
                      1, Mohammed Buhari way by NEPA round about behind Total
                      fuel station, Kaduna.
                    </option>
                  </optgroup>
                  <optgroup label="Ondo">
                    <option>39, Oba Adesida Road, Akure.</option>
                  </optgroup>
                  <optgroup label="Oyo">
                    <option>
                      Beta courier service, 1st floor, Lister building, Ring
                      Road, Ibadan.
                    </option>
                  </optgroup>
                  <optgroup label="Delta">
                    <option>
                      Beta courier, 57, Effurun/sapele road inside Esiton
                      filling station by Urhoboh college, Warri.
                    </option>
                  </optgroup>
                </select>
              )}

              <div
                className="d-flex"
                style={{
                  justifyContent: "space-between",
                  gap: "1rem",
                  marginTop: "2rem",
                }}
              >
                <Button
                  size="lg"
                  type="submit"
                  disabled={validatePlaceOrder()}
                  onClick={() => {
                    handlePlaceOrder();
                  }}
                  style={{
                    borderRadius: 7,
                    border: "none",
                    backgroundColor: Colors.BES_PURPLE,
                    color: "#FFFFFF",
                    fontSize: 16,
                    width: "100%",
                    marginTop: 20,
                    marginBottom: 30,
                    height: 50,
                    // position: "absolute",
                    left: "40%",
                    bottom: 0,
                  }}
                >
                  <FontAwesomeIcon
                    style={{ marginRight: "1rem", fontSize: "1rem" }}
                    icon={faLuggageCart}
                  />
                  Place Order
                </Button>
              </div>
            </div>
          </div>
        )}
      </Container>
    </section>
  );
};

export default CustomOrderPage;
