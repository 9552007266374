import React, { useState } from "react";
import { Form, Table, Button } from "react-bootstrap";

import Header from "../../components/Header";
import "./styles.scss";
import { getCartItems } from "../../services/api";
import { ToastContainer, toast } from "react-toastify";
import { Colors } from "../../utils/styles";

const CartCode = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [cartData, setCartData] = useState([]);
  const [error, setError] = useState("");

  const handleSearch = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setSearchTerm(value);
  };

  const handlefetchCartItems = async () => {
    const items = await getCartItems(searchTerm);
    if (items.response?.status >= 400) {
      toast.error(items.response.data.detail, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "light",
      });
      setSearchTerm("");
      setError(items.response.data.detail);
    } else {
      setCartData(items.data);
      setError("");
    }
  };

  return (
    <div>
      <Header hideAuth={true} />
      <div className="custom_container custom_container--pall">
        <section style={{ height: "90vh", marginTop: 20 }}>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover={false}
            theme="light"
          />
          <div className="custom-row flex flex-jc-c ">
            <div
              style={{
                padding: "10px 0 0 0",
                height: "10rem !important",
              }}
              className="profile-sidebar first"
            >
              <div className="p-2">
                <Form>
                  <Form.Group className="d-flex justify-content-center">
                    <Form.Control
                      type="text"
                      placeholder="Paste cart code here..."
                      value={searchTerm}
                      onChange={handleSearch}
                    />
                  </Form.Group>
                </Form>
                <div className="flex flex-jc-c">
                  <Button
                    variant="outline-primary"
                    size="sm"
                    disabled={searchTerm.length > 0 ? false : true}
                    onClick={handlefetchCartItems}
                    style={{
                      borderRadius: 10,
                      backgroundColor: Colors.BES_PURPLE,
                      color: "#FFFFFF",
                      fontSize: 16,
                      width: 150,
                      marginTop: 1,
                      height: 35,
                      border: "none",
                    }}
                  >
                    {" "}
                    Get Items
                  </Button>
                </div>
              </div>
            </div>
            <div
              lg={6}
              className="second"
              style={{ height: "5rem !important" }}
            >
              <>
                <div>
                  {cartData && cartData.length > 0 && error === "" ? (
                    <Table
                      responsive
                      style={{
                        height: "15rem !important",
                        fontSize: "0.5rem !important",
                      }}
                    >
                      <thead>
                        <tr className="text-muted text-center">
                          <th>Product</th>
                          <th>Quantity</th>
                          <th>Size</th>
                          <th>Unit price</th>
                          <th>Total price </th>
                        </tr>
                      </thead>
                      <tbody className="text-center">
                        {cartData &&
                          cartData.map((item) => {
                            return (
                              <tr key={item.id}>
                                <td
                                  className="flex-dc flex-ai-c"
                                  style={{ maxWidth: "7rem" }}
                                >
                                  <img
                                    src={item.image_url}
                                    alt="trer"
                                    width="50"
                                    height="50"
                                  />
                                  <br />
                                  <a
                                    href={item.url}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <small
                                      className="p-1"
                                      style={{ fontSize: "0.8rem" }}
                                    >
                                      {item.name}
                                    </small>
                                  </a>
                                </td>
                                <td>{item.quantity}</td>
                                <td>{item.size || "N/A"}</td>
                                <td>{item.unit_price}</td>
                                <td>{item.total_price}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  ) : (
                    <div>
                      {error ? (
                        <div className="flex flex-dc flex-jc-c flex-ai-c mt-5">
                          <i className="fa fa-info-circle text-danger fa-2x text-secondary mb-1"></i>
                          <small className="text-danger text-sm">
                            Cart Code {error}{" "}
                          </small>
                        </div>
                      ) : (
                        <div className="flex flex-dc flex-jc-c flex-ai-c mt-5">
                          <i className="fa fa-shopping-cart fa-2x text-secondary mb-1"></i>
                          <small className="text-secondary text-sm">
                            Cart Items would be displayed here{" "}
                          </small>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default CartCode;
