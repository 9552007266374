import React, { useContext, useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { getUser } from "../../services/api";
import { saveToStore } from "../../utils/storage-helpers";
import XSvgIcon from "../../assets/XIcon";

import AuthContext from "../../context/authContext";
import Header from "../../components/Header";
import SideBar from "../../components/SideBar";
import LoginPage from "../login";
import "./styles.scss";

import {
  TwitterShareButton,
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

const { REACT_APP_GOGGLE_REDIRECT_URL_ENDPOINT } = process.env;

const ReferralPage = () => {
  const { user, isAuthenticated } = useContext(AuthContext);
  const [inviteCode, setInviteCode] = useState("");

  const handleFetchUser = async () => {
    const userDetails = await getUser();
    setInviteCode(userDetails.data.invite_code);
    await saveToStore("user", userDetails.data);
  };

  useEffect(() => {
    if (user.invite_code === undefined) {
      handleFetchUser();
    }
  }, []);

  if (!isAuthenticated) {
    return <LoginPage path="/profile" />;
  }

  const refferalUrl = "https://onelink.to/beta-eshopping";

  const description = `Unlock Exicting Offers when you shop from the US, UK, or China with Beta-eShopping!  Get a $5 welcome bonus on your first order when you use the referral code ${
    user.invite_code || inviteCode
  } at sign up. Click to get started: \n`;

  const handleCopyIode = () => {
    navigator.clipboard.writeText(user.invite_code);
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(refferalUrl);
  };

  return (
    <div>
      <Header hideAuth={true} />
      <div className="custom_container custom_container--pall">
        <section style={{ height: "90vh", marginTop: 20 }}>
          <div className="custom-row">
            <div
              style={{
                padding: "10px 0 0 0",
              }}
              className="profile-sidebar first"
            >
              <SideBar path="/refer-a-friend" />
            </div>
            <div lg={6} className="second px-4 py-4">
              <h5 className="mb-4 bes-purple-text text-uppercase text-center">
                Referral Program
              </h5>
              <p className="text-center px-3">
                <i className="fa-solid fa-user-group fa-2x text-center mb-1 bes-purple-text"></i>
              </p>
              <p className="text-center px-4 mb-4">
                Become an affiliate and earn $5 commission on every fufilled
                order when you refer someone to shop on Beta-eshopping.
              </p>
              <div className="flex flex-jc-sb flex-ai-c px-2">
                <div>
                  <label className="form-label text-muted">
                    Your Referral Code
                  </label>
                  <p>{user.invite_code || inviteCode}</p>
                </div>
                <button
                  onClick={handleCopyIode}
                  className="btn brn-sm bes-purple bes-purple-outline-p"
                >
                  {" "}
                  <i className="fa-regular fa-copy"></i> Copy{" "}
                </button>
              </div>

              <div className="flex flex-jc-sb flex-ai-c px-2">
                <div>
                  <label className="form-label text-muted">
                    Your Referral Link
                  </label>
                  <p id="refferal_link">{refferalUrl}</p>
                </div>
                <button
                  onClick={handleCopyLink}
                  className="btn brn-sm bes-purple bes-purple-outline-p"
                >
                  {" "}
                  <i className="fa-regular fa-copy"></i> Copy{" "}
                </button>
              </div>

              <div>
                <div className="text-start mt-4  flex flex-ai-c gap-2 px-2">
                  <spam>Share on</spam>
                  <TwitterShareButton title={description} url={refferalUrl}>
                    <XSvgIcon />
                  </TwitterShareButton>
                  <WhatsappShareButton url={refferalUrl} title={description}>
                    <WhatsappIcon size={40} round={true} />
                  </WhatsappShareButton>
                  <TelegramShareButton title={description} url={refferalUrl}>
                    <TelegramIcon size={40} round={true} />
                  </TelegramShareButton>
                </div>
              </div>

              <>
                <div className=""></div>
              </>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ReferralPage;
