import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import styles from "./style";
import "./styles.scss";

const HowItWorksPage = (props) => {
  return (
    <div>
      <Header hideAuth={true} />

      <Container style={styles.bodyContainer}>
        <Row style={{ justifyContent: "center" }}>
          <Col lg={6} style={{ textAlign: "center" }}></Col>
        </Row>

        <Row style={{ justifyContent: "left" }}>
          <Col lg={6} style={{ textAlign: "left" }}>
            <section>
              <h4>1. To Load a Cart</h4>
              <ol className="normal">
                <li>
                  {" "}
                  <a
                    href="http://www.beta-eshopping.com/signin"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Create a new account
                  </a>{" "}
                  or sign in to your existing account
                </li>
                <li>
                  Download the{" "}
                  <a
                    href="https://chrome.google.com/webstore/detail/beta-eshopping/kgcpjclkbimaefcfefhmgcpenbkdmigb"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Beta E-shopping Chrome Extension
                  </a>{" "}
                  and pin it to the browser.
                </li>
                <li>On the homepage, click on “SHOP NOW”. </li>
                <li>
                  Select your preferred store (e.g., Amazon), and add items to
                  your cart
                </li>
                <li>
                  Click on the “Check out” button on Beta-Eshopping banner
                </li>
                <li>Click on “Continue Checkout on Beta-Eshopping”</li>
                <li>
                  Choose your preferred shipping method, select “Place Order,”
                </li>
                <li>
                  An email notification will be sent to you. Review pending
                  orders and make payment
                </li>
                <li>
                  Sit back and relax as we handle your request and deliver it to
                  you within 10 to 15 business days.
                </li>
              </ol>
            </section>
            <hr></hr>
            <section>
              <h4>2. Custom Orders</h4>
              <ol className="normal">
                <li>
                  {" "}
                  <a
                    href="http://www.beta-eshopping.com/signin"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Create a new account
                  </a>{" "}
                  or sign in to your existing account
                </li>
                <li>
                  Click on{" "}
                  <a
                    href="http://www.beta-eshopping.com/custom-order"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Custom Order
                  </a>{" "}
                  on the navigation bar.
                </li>
                <li>
                  Copy the Item URL, item name, and other neccessary details of
                  the item from the store you are shopping from and paste in the
                  cells as indicated.
                </li>
                <li>Select your shipping type and place your order</li>
                <li>
                  An email notification will be sent to you. you can then review
                  your pending orders on your dashbaord and make payment
                </li>
              </ol>
            </section>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default HowItWorksPage;
