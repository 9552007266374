import { createContext, useContext } from "react";

export const TransactionContext = createContext({
  transaction: [],
  setTransactions: () => {},
});

export const useTransaction = () => {
  return useContext(TransactionContext);
};
