import { Colors } from "../../utils/styles";

const styles =  {
    bodyContainer: {
        marginTop: 80
    },
    signInHeaderText: {
        fontWeight: 700,
        fontSize: 30,
        color: "#000000",
        textAlign: "center",
        marginTop: 40,
    },
    signInForm: {
        marginTop: 50
    },
    signInFormContainer: {
        marginBottom: 20
    },
    signInFormLabel: {
        fontWeight: 500,
        fontSize: 16,
        color: "#181743",
    },
    formInput: {
        borderRadius: 8,
        border: "1px solid #BEBDFF",
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        height: 46,
        margin: "0 auto",
        width: 300,
    },
    button: {
        width: 300,
        height: 53,
        margin: "40px auto auto auto",
        display: "block",
        background: Colors.BES_PURPLE,
    },
};

export default styles;