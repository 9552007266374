import React, { useState, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Container, Modal } from "react-bootstrap";
import { Oval } from "react-loader-spinner";
import { Link } from "react-router-dom";

import AuthContext from "../../context/authContext";
import { userLogin } from "../../services/api";
import { saveToStore } from "../../utils/storage-helpers";

import styles from "./style";
import "./loginStyles.css";
import { ROUNDED_BUTTON } from "../../utils/styles/buttons";
import GoogleButton from "react-google-button";
import AuthWrapper from "../../components/AuthWrapper";

const { REACT_APP_GOOGLE_CLIENT_ID, REACT_APP_GOGGLE_REDIRECT_URL_ENDPOINT } =
  process.env;

const LoginPage = (props) => {
  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = React.useState(true);
  const [passwordShown, setPasswordShown] = useState(false);
  const { setAuth, isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  const { path } = props;
  const prevPage = path ? path : "/";

  // useEffect(() => {
  //   let isSubscribed = true;

  //   // eslint-disable-next-line no-unused-vars
  //   return () => (isSubscribed = false);
  // }, []);

  const validateForm = () => {
    return userEmail.length > 0 && password.length > 0;
  };

  // Password toggle handler
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const openGoogleLoginPage = useCallback(() => {
    const googleAuthUrl = "https://accounts.google.com/o/oauth2/v2/auth";
    const redirectUri = "google/";

    const scope = [
      "https://www.googleapis.com/auth/userinfo.email",
      "https://www.googleapis.com/auth/userinfo.profile",
    ].join(" ");

    const params = {
      response_type: "code",
      client_id: REACT_APP_GOOGLE_CLIENT_ID,
      redirect_uri: `${REACT_APP_GOGGLE_REDIRECT_URL_ENDPOINT}/${redirectUri}`,
      prompt: "select_account",
      access_type: "offline",
      scope,
    };

    const urlParams = new URLSearchParams(params).toString();

    window.location = `${googleAuthUrl}?${urlParams}`;
  }, []);

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const email = userEmail.toLowerCase();
    const data = {
      email,
      password,
    };

    const login_res = await userLogin(data);
    if (login_res.status !== 200) {
      const err = login_res.response.data;
      const errMsg = Object.keys(err).map((key) => {
        return `${[key]}: ${err[key]}`;
      });
      const removedetail = errMsg[0].replace("detail: ", "");
      if (removedetail.includes("Not found")) {
        setError("Invalid Username or Password.");
      } else {
        setError(removedetail);
      }
      await saveToStore("userEmail", email);

      setHasError(true);
      setAuth(false);
      setModalShow(true);
    } else {
      await saveToStore("access_token", login_res.data.access_token);
      await saveToStore("user", login_res.data.user);
      setAuth(true);
      setHasError(false);
      navigate(prevPage);
    }
    setIsLoading(false);
  };

  if (isAuthenticated) {
    navigate("/");
  }

  const handleNavigationToVerify = () => {
    navigate("/otp");
  };

  return (
    <AuthWrapper
      header="Start Shopping Now!"
      description="Log in to enjoy a stress-free shopping experience."
    >
      {hasError && (
        <Modal
          {...props}
          size="md"
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={modalShow}
          onHide={() => setModalShow(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Login Error
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p> {error} </p>
          </Modal.Body>
          <Modal.Footer>
            {error.length > 90 ? (
              <Button
                size="md"
                style={{
                  ...ROUNDED_BUTTON,
                  width: 200,
                  height: 40,
                  margin: "0 auto",
                }}
                onClick={handleNavigationToVerify}
              >
                Go to OTP Page
              </Button>
            ) : (
              <Button
                size="md"
                style={{
                  ...ROUNDED_BUTTON,
                  width: 150,
                  height: 40,
                  margin: "0 auto",
                }}
                onClick={() => {
                  setModalShow(false);
                }}
              >
                Got it!
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      )}
      <Container
        style={{ marginTop: 10, paddingLeft: 90, paddingRight: 90 }}
        className="signup-right-container "
      >
        <h1 className="login-container-title">Welcome Back!</h1>
        <p className="form-description">
          Enter your email and password to continue.
        </p>
        <Form onSubmit={handleSubmit} style={styles.signInForm}>
          <Form.Group
            size="lg"
            controlId="email"
            style={styles.signInFormContainer}
            className="form-group"
          >
            <Form.Label style={styles.signInFormLabel}>Email</Form.Label>
            <Form.Control
              placeholder="Enter email"
              autoFocus
              type="email"
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
              style={styles.formInput}
              className="loginFormInput mb-4"
            />
          </Form.Group>
          <Form.Group
            size="lg"
            controlId="password"
            style={styles.signInFormContainer}
          >
            <Form.Label style={styles.signInFormLabel}>Password</Form.Label>
            <Form.Control
              placeholder="Password"
              type={passwordShown ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={styles.formInput}
              className="loginFormInput "
            />
            <div className="flex flex-jc-sb ai-i-c mt-2 ">
              <div className="flex ai-i-c">
                <div>
                  <input
                    type="checkbox"
                    name="showPassword"
                    onChange={togglePassword}
                  />
                </div>
                <div className="flex flex-jc-sb ai-i-c">
                  <label
                    htmlFor="showPassword"
                    style={{ marginLeft: 5, color: "#333", fontSize: 13 }}
                  >
                    Show Password
                  </label>
                </div>
              </div>
              <Form.Text id="passwordHelpBlock">
                <Link to="/resetpassword" style={styles.loginSpanText}>
                  Forgot password?
                </Link>
              </Form.Text>
            </div>
          </Form.Group>
          <div className="d-grid gap-2 text-center ">
            <Button
              className="block login-btn"
              size="lg"
              type="submit"
              style={styles.button}
              disabled={!validateForm() || isLoading}
            >
              {isLoading ? (
                <div className="d-flex align-items-center justify-content-center">
                  <Oval
                    type="Circles"
                    color="#fff"
                    height={30}
                    width={30}
                    wrapperStyle={{ margin: "0 auto" }}
                  />
                </div>
              ) : (
                "Login"
              )}
            </Button>
          </div>
          <div className="mt-3 text-center mb-3">OR</div>

          <div className="flex flex-dc flex-jc-c ai-i-c">
            <GoogleButton
              onClick={openGoogleLoginPage}
              label="Sign in with Google"
              disabled={!REACT_APP_GOOGLE_CLIENT_ID}
              className="ml-4 google-login"
            />
            <div className="mt-4 text-center">
              Don't Have an account
              <Link
                to="/register"
                className=" cursor-toggle text-danger text-decoration-none"
              >
                {" "}
                Sign Up
              </Link>
            </div>
          </div>
        </Form>
      </Container>
    </AuthWrapper>
  );
};

export default LoginPage;
