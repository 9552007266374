import React from "react";

const TermsAndConditions = () => {
  return (
    <>
      <div>
        <h5>THE TERMS ‘YOU’ ‘YOUR’ ‘WE’ ‘OUR’ AND ‘ESHOPPING’.</h5>
        <p>
          In these terms and conditions, the terms ‘you’ and ‘your’ refer to you
          personally (if you are an individual) or the entity you represent (if
          you registered on behalf of a business entity), and the terms, ‘we’,
          and ‘our’ refer to ‘Beta-eshopping’.
        </p>
        <h5>ACCEPTANCE</h5>
        <p>
          You agree that clicking the ‘I Accept’ button prior to registering for
          a ‘Beta-eshopping’ account is valid and equivalent to (i) signing a
          hard-copy printed agreement between you and ‘Beta-eshopping’; and (ii)
          signing a hard-copy printed authorization appointing Beta-eshopping or
          a third party nominated by Beta-eshopping to act on your behalf as
          your true and lawful exporting agent. You may decline the terms and
          conditions, but if you do so, you will not be able to open an account
          or use the ‘Beta-eshopping’ services
        </p>
      </div>
      <h5>ACCOUNT CREATION.</h5>
      <p>
        To register to use our ‘Beta-eshopping’ services, you need to provide
        ‘Beta-eshopping’ a current, valid e-mail address, your street address,
        contact phone numbers, and in some cases a valid government issued
        identification. You certify that the information you provide is accurate
        and that you will update it promptly if any of it changes. We reserve
        the right to cancel any unconfirmed account or an account that has been
        inactive for a lengthy period of time, or if we find you have submitted
        inaccurate information, or if we determine that your account may be
        compromised or used for fraudulent purposes. You may not open more than
        one ‘Beta-eshopping’ account.
      </p>
      <h5>NO TRANSFER OF ACCOUNT.</h5>
      <p>
        You may not transfer your ‘Beta-eshopping’ account or your account
        login/password to any other person and you are responsible for any use
        of your ‘Beta-eshopping’ account.
      </p>
      <h5>SPECIFIED COUNTRIES.</h5>
      <p>
        You may only create a ‘Beta-eshopping’ account and use ‘Beta-eshopping’
        services in Nigeria.
      </p>
      <h5>SECURITY</h5>
      <p>
        You agree to keep your ‘Beta-eshopping’ password confidential, to
        safeguard it and your computer(s) and mobile device(s) from access by
        others, and to log out of the website when your session is complete. You
        are responsible for any use of the ‘Beta-eshopping’ website and of your
        ‘Beta-eshopping’ account made under your login and password. If you
        become aware of or suspect any unauthorised use of your ‘Beta-eshopping’
        account, or any known or suspected security breach such as disclosure or
        loss of your password or theft of your ‘Beta-eshopping’ account
        information, you should notify ‘us’ promptly by using the following
        link:{" "}
        <a href="/contact-us" target="_blank">
          Beta eshopping
        </a>
        . We will block or deactivate your account, and, depending on the
        circumstances of the security breach, we may allow you to open a new
        account.
      </p>
      <h5>YOUR WARRANTIES AND REPRESENTATIONS.</h5>
      <ol>
        <li>You warrant and represent that:</li>
        <li>You are at least 18 years of age,</li>
        <li>
          You have obtained all necessary permissions to enter into and perform
          this agreement, and
        </li>
        <li>
          You will use the Beta-eshopping services only for lawful purposes and
          in accordance with these terms and conditions.
        </li>
      </ol>
      <h5>PRIVACY: DATA PROTECTION.</h5>
      <ol>
        <li>
          You may not use the ‘Beta-eshopping’ website or ‘Beta-eshopping’
          services for any unlawful purpose. We reserve the right to terminate
          or restrict your access to your ‘Beta-eshopping’ account and your use
          of our services if, in our opinion, your use may violate any laws,
          infringe upon another person’s rights, or breach the terms of this
          Agreement.
        </li>
        <li>
          You may use the ‘Beta-eshopping’ website and ‘Beta-eshopping’ services
          solely for your personal use (including your friends and family
          members - see section 22) or for use by the business entity you
          represent. If you intend to resell products you receive via our
          services, you agree to comply with all laws and regulations that apply
          to the importation of products for resale, and with any applicable
          restrictions on resale required by the merchant.
        </li>
        <li>
          You may not offer for sale, resell, market, distribute, create a
          database from, or otherwise commercially exploit in any manner any of
          the content on the ‘Beta-eshopping’ website or information generated
          using the website.
        </li>
      </ol>
      <h5>SHIPPING COST CALCULATOR</h5>
      <p>
        If you use our Shipping Cost Calculator to determine the combined costs
        of duty, taxes and international shipping, the resulting cost is an
        estimate only. The actual cost may vary from the estimate due to several
        factors, including but not limited to: fuel surcharges; government
        levies and surcharges; changes in currency exchange rates; differences
        between the expected and actual package weights; discrepancies between
        the estimated and actual product classification or value; the use of
        volumetric weight calculations for bulky, lightweight packages; and/or
        additional local delivery charges if your delivery address is in a
        remote geographic area.
      </p>
      <h5>MALICIOUS CONTENT.</h5>
      <p>
        We make reasonable attempts to exclude viruses, worms, and other
        malicious content from these web pages, but we cannot and do not
        guarantee the complete absence of such. Please take appropriate
        safeguards before downloading information from the ‘Beta-eshopping’
        website.
      </p>
      <h5>INTELLECTUAL PROPERTY</h5>
      <p>
        Our ‘Beta-eshopping’ websites, user interfaces, the ‘look and feel,’ the
        content, the Shipping Cost Calculator, and all associated and underlying
        software, know-how, methodologies and processes are and will remain the
        sole property of ‘Beta-eshopping’, or the licensor of such rights,
        third-party suppliers and associated companies, and are protected by
        patent, copyright, trademark, trade secret and other intellectual
        property laws. The use of the software, the data generated by the
        software, and all other materials made available on this website are
        licensed to you on a nonexclusive and limited basis.
      </p>
      <ol>
        <li>
          You may not copy, reverse engineer, decompile or create derivative
          works from the ‘Beta-eshopping’ website.
        </li>
        <li>
          You may not use any meta tags or any other ‘hidden text’ which
          incorporate “Beta-eshopping’s” name or trademark unless we give our
          prior written consent.
        </li>
        <li>
          While you are using the website, you may print out or electronically
          store any or all of the web pages or screens but solely for your
          personal use.
        </li>
        <li>
          You may not scrape, frame or display information from the
          ‘Beta-eshopping’ website for use in another website or service without
          our prior written consent.
        </li>
        <li>
          You may not place a hyperlink to the website on your site except in
          the manner we specify and after receipt of our prior written
          permission
        </li>
      </ol>
      <h5>INTELLECTUAL PROPERTY INFRINGEMENT; COUNTERFEIT GOODS</h5>
      <ol>
        <li>
          • We make efforts not to publish any material that is subject to third
          party copyright, trademark or other legal protections. If you think
          material on the website infringes your intellectual property rights,
          please let us know by submitting a complaint by following this{" "}
          <a href="/contact-us" target="_blank">
            link
          </a>
        </li>

        <li>
          We take the problems caused by Internet advertising and sale of
          counterfeit goods seriously. Counterfeit goods are imitations of
          well-known brands. They are typically sold at prices lower than the
          genuine product, and usually bear the genuine product’s trademark or
          logo. Advertisements for these products may or may not specify that
          they are replicas, and they are often difficult to distinguish from
          the genuine article - particularly in online advertisements where
          people do not have the chance to examine the goods themselves. While
          we make every effort to ensure that our service is not used for the
          sale of counterfeit goods, and we carefully select the merchants and
          products linked to our websites, we cannot guarantee that every item
          advertised and/or delivered through our cross-border shopping service
          is 100% authentic. But we do commit to investigate any such problems
          reported to us and to take steps to stop such goods from being sold
          via our website.
        </li>
        <li>
          If you are the rightful owner of a trademark, and you know or suspect
          that your rights are being infringed by the advertising of counterfeit
          goods on our ’Beta-eshopping’ website and/or the shipment of such
          goods through our international delivery services, please inform us by
          submitting a{" "}
          <a href="/contact-us" target="_blank">
            complaint
          </a>
        </li>
        <li>
          After we receive your complaint, we will conduct an investigation. If
          we determine that goods infringing your rights are indeed being
          advertised and/or distributed through our website, we will take
          appropriate action such as: - removing the advertisements for the
          infringing product;
          <br />
          - prohibiting the merchant of the product infringing such intellectual
          property from selling via the ‘Beta-eshopping’ websites;
          <br />
          - disabling the customer’s account if the customer is knowingly
          importing counterfeit goods using ‘Beta-eshopping’ services; and
          <br />- notifying law enforcement authorities.
        </li>
      </ol>
      <h5>TRADEMARKS</h5>
      <p>
        ‘‘Beta-eshopping” is registered in Nigeria. Use of this website does not
        grant you a license to use the ‘Beta-eshopping’ trademark. Our license
        to use these trademarks is limited, and these trademarks may not be
        copied, downloaded, reproduced, used, modified or distributed in any way
        (except as part of a copy of any of these web pages or screens as
        authorised above) without our written permission. All trademarks or
        trade names displayed or referred to on our website are the property of
        their respective owners.
      </p>
      <h5>OTHER WEBSITES</h5>
      <p>
        We are not responsible for the content or accuracy of information
        obtained from any third party (non-’Beta-eshopping’) website, including
        merchants retailing items on the ‘Beta-eshopping’ website, which may be
        linked to the ‘Beta-eshopping’ website, or which you may access from
        this website.
      </p>
      <h5>DISCLAIMER OF WARRANTY.</h5>
      <p>
        Your use of the website is at your own risk, and you acknowledge that
        the website services are provided on an ‘as is’ and ‘as available’
        basis. While we endeavour to provide accurate and current information
        via our website, we disclaim all warranties, express and implied,
        including but not limited to warranties of merchantability, fitness for
        a specific purpose, and non-infringement, to the full extent permissible
        by applicable law. The website is not warranted to be uninterrupted or
        error-free. We are not liable for any inability to access, delay in
        accessing, or interruptions in access to your account or conducting
        transactions due to unavailable or unreliable telecommunications
        networks or internet service providers, incompatible or malfunctioning
        equipment, software you use for such access, or other causes beyond our
        control.
      </p>
      <h5>LIMITATION OF LIABILITY.</h5>
      <p>
        In no event will we be liable for any indirect, special, consequential,
        incidental, punitive or exemplary damages (including but not limited to
        loss of business, loss of revenue, loss of profits, loss of goodwill,
        lost opportunity, business interruption, or loss of information) arising
        out of your use of or inability to use your ‘Beta-eshopping’ account or
        the ‘Beta-eshopping’ website, even if we have been advised of the
        possibility of such damages.
      </p>
      <h5>NOTICES</h5>
      <p>
        E-mail notices, newsletters and/or information on the website will be
        the primary methods by which we will communicate. We will send notices
        and any other pertinent information to you at the e-mail address you
        supply when you open your ‘Beta-eshopping’ account or have subsequently
        updated. You can send notices or other communications to us by using the
        Contact Us page.
      </p>
      <h5>MODIFICATION OF ‘BETA-ESHOPPING’ TERMS AND CONDITIONS</h5>
      <p>
        We may modify these terms and conditions at any time without notice.
        Your continued use of your ‘Beta-eshopping’ account after the
        introduction of such modifications will constitute your acceptance of
        the modified terms and conditions.
      </p>
      <p>
        <strong>Your purchase of products from merchants</strong>
      </p>
      <h5>ORDERING PRODUCTS</h5>
      <p>
        You place an order with us through links in the ‘Beta-eshopping’ website
        or via use of ‘our’ app. You will pay ‘Beta-eshopping’ directly for the
        product and the cost of shipping and delivery to your address. You agree
        to comply with any conditions and restrictions of sale which are
        specified on any merchant’s website or of which you are notified of
        during the purchase process.
      </p>
      <h5>CANCELLING ORDERS</h5>
      <p>
        Beta-eshopping’ cancellation policy: Once an order has been submitted,
        no changes or cancellations are allowed as orders are placed within
        minutes of submission. At check out the customer acknowledges a box
        confirming these details. In some extreme exceptions, we try our best to
        accommodate the customer and as a courtesy we will attempt to cancel or
        alter the order but such modification is subject to the merchant’s
        return/cancellation policy and may incur a restocking fee.
      </p>
      <h5>COUNTERFEIT ITEMS.</h5>
      <p>
        Counterfeit items if purchased will not be shipped and not be refunded
        to the buyer. It is illegal to buy an item that is an unauthorized copy,
        imitation, substitute or modified part which is knowingly misrepresented
        or specified as a genuine product, part or an original unauthorized
        manufactured product. Any item delivered to or supplied to
        ‘Beta-eshopping’ or our affiliates found to be counterfeit or suspected
        counterfeit will not be shipped to the buyer or returned to the seller.
        Such items will also not be refunded to the buyer. Buyers of such items
        will not receive any refund on the item or the shipping cost paid.
        Buyers are discouraged from buying such items as they may also be
        reported to local authorities for attempting to purchase counterfeit
        products which are unlawful
      </p>
      <h5>RESTOCKING FEES.</h5>
      <p>
        A restocking fee covers the cost for return shipping to the merchant,
        ‘Beta-eshopping’ incurred costs, and any other additional fees the
        merchant may require. Do bear in mind that if the merchant has its own
        restocking fee, it will be included as well. Restocking fees must be
        paid before a refund or reorder can be processed.
      </p>
      <p>
        Please note that if the customer declines payment of the restocking fee,
        the order stands as is.
      </p>
      <h5>CORRECT ADDRESSING.</h5>
      <p>
        You will ensure ‘Beta-eshopping’ has your most up-to-date delivery
        address, including an email and contact phone number. Additional
        handling fees may apply if your package was incorrectly or inadequately
        addressed. Deliveries to PO Boxes are not accepted. Deliveries will also
        be made to a main receiving area, and not specifically to the named
        addressee.
      </p>
      <h5>PURCHASE TRANSACTION SOLELY BETWEEN YOU AND ‘BETA-ESHOPPING’.</h5>
      <ol>
        <li>
          ‘Beta-eshopping’ provides services enabling you to shop online from
          merchants’ websites in another country and have products delivered to
          you in your home country; and ‘Beta-eshopping’ as the owner of the
          website is the sole party selling the products to you.
        </li>
        <li>
          ‘Beta-eshopping’ makes no representations, warranties or guarantees
          regarding the quality or suitability of the products you order.
        </li>
        <li>
          We have no liability for any failure of a merchant to fulfill your
          order, or for any defects in any product you purchase, or any injury
          or damage that any product may cause, or for any other failure of the
          product to meet your expectations.
        </li>
        <li>
          We shall handle all communications with merchants regarding product
          exchanges. We will, upon your request and upon payment of the relevant
          fees, if any, arrange for the return delivery of item(s) ordered from
          the merchant provided such product is still available. Applicable
          return charges may be applied.
        </li>
      </ol>
      <h5>OUR RECEIVING PROCEDURE.</h5>
      <p>
        We will in good faith receive packages delivered to us from the
        merchant, inspect them, and then complete the documentation required to
        ship such packages to you on a DDP/DAP, delivered with duties and taxes
        paid, at your designated address via our courier service. We will notify
        you when items are received either electronically, or by making the
        track and trace information for such shipment available on our website.
        The ultimate delivery address must be in the same country as the country
        where you are located; you cannot ship packages to individuals in any
        other country. Payment must be via a credit card in the local currency
        where you are located. COD Shipments will not be accepted.
      </p>
      <h5>LOSS OR DAMAGE OF PRODUCTS IN TRANSIT.</h5>
      <p>
        ‘Beta-eshopping’ will replace any products lost or damaged in transit
        from the merchant to us for on sale and shipping to you. We reserve the
        right to refuse delivery of such product if, upon inspection of the
        outside of the package, it is obvious that the product is damaged or
        incomplete. In such a case, we will return the product to the merchant
        and will notify you that a delay may occur in procuring the item you
        purchased.
      </p>
      <h5>INSPECTION OF PACKAGES.</h5>
      <ol>
        <li>
          We shall (upon receipt, or in preparing the item for export) open and
          inspect each item, or part thereof, for security and safety purposes,
          to verify compliance with export and import laws, and to confirm the
          product description and other information you and the merchant supply,
          all without prior notice and without liability to you.
        </li>
        <li>
          If we discover that the description of the contents of the package or
          other information supplied is not accurate, then, depending on the
          specific situation, we may refuse to ship it (you will be notified),
          or we may correct the information after seeking further information
          from the merchant.
        </li>
      </ol>
      <h5>PROHIBITED ITEMS.</h5>
      <ol>
        <li>
          We will place on ‘hold’ all products that are restricted or deemed
          prohibited for export or import to the destination country.
          Additionally, any product deemed unsafe for international
          transportation will be prohibited for shipment and placed on hold. See
          our preferred shipping provider’s Prohibited items FAQ by following
          this link{" "}
          <a href="/faq" target="_blank" rel="noreferrer">
            FAQ
          </a>
          . After you have been notified of the ‘hold’ status, we will take your
          request in terms of what to do with the item into account. Additional
          charges may apply. You will have 14 days to advise us of your
          requested disposition of the product. If you have not responded to our
          notification within 14 days, the product may be destroyed.
        </li>
        <li>
          Products that cannot be legally sold or transported (such as illegal
          drugs, weapons, etc.) cannot be returned to the merchant or shipped to
          an alternative address. They are subject to seizure by law enforcement
          authorities, who may investigate further.
        </li>
        <li>
          It is not always a simple matter to determine whether a specific
          product is export-restricted. In such cases, ‘Beta-eshopping’ may
          submit such items for review by the government agency responsible for
          export or import compliance.
        </li>
      </ol>
      <h5>NO MAIL ACCEPTED.</h5>
      <p>
        We will only process packages and envelopes containing items you have
        purchased from us. We are not a mail forwarding agency, and we do not
        and cannot ship documents or other mail to you. Any mail or packages
        (other than items purchased from us) addressed to you that do not
        contain items of value may be destroyed.
      </p>
      <h5>UNIDENTIFIED RECIPIENTS.</h5>
      <p>
        Occasionally we receive packages where the appropriate recipient cannot
        be identified, e.g., the customer’s name and address may be missing or
        misstated. If you have ordered a product and there is an unusual delay
        in receiving notification from ‘Beta-eshopping’ that an item is being
        shipped to you, please contact Customer Service and describe the product
        you are expecting so that we can inspect unidentified packages and see
        if yours is there. Any goods received at our export facility for which
        an owner cannot be determined (after we have made reasonable
        investigative efforts) will be held for 60 days. If the goods remain
        unclaimed after 60 days, ‘Beta-eshopping’ will have the right to dispose
        of them in any manner permitted under applicable law.
      </p>
      <h5>APPOINTMENT OF BETA-ESHOPPING.</h5>
      <p>
        By registering for a ‘Beta-eshopping’ account, you appoint
        ‘Beta-eshopping’ to act as your agent to manage all export, import, and
        customs clearance procedures, including acting as the United States
        Principal Party in Interest for items purchased in the USA and to ensure
        that customs duties and taxes are paid to the relevant customs and tax
        authorities. We will perform these functions and act as the exporter of
        record based on the information you and the merchant provide and,
        accordingly, we will NOT be responsible or liable for any additional
        duties, taxes or penalties that are assessed if the information you
        provide to us is inaccurate or incorrect.
      </p>
      <h5>ACCURATE CONTENTS AND VALUE DECLARATION. PAYMENT OF DUTY AND TAX</h5>
      <ol>
        <li>
          An accurate description of the contents of your package, and its
          value, is necessary for compliance with export and import laws. In
          this regard, you acknowledge that:
        </li>
        <li>
          Packages may be inspected at any time by export and import
          authorities. Packages with misleading product descriptions or
          under-declared valuations may be delayed by the relevant customs
          authorities, who may dispute and correct the declaration – in which
          case, you might possibly have to pay additional duty or other fees.
        </li>
        <li>
          The amount of tax and duty actually charged by your country’s Customs
          authority may differ from the amount you paid us. Here’s why:
        </li>
        <li>
          Tax and duty are based on the “dutiable value” of the goods. We
          calculate the tax and duty as accurately as we can, after your package
          is inspected, weighed and measured, and ready for dispatch at our
          export facility. You pay us those calculated amounts of tax and duty,
          along with the shipping charge, when you release your shipment. Your
          ‘Beta-eshopping’ invoice includes a breakdown of (i) the product cost,
          (ii) the shipping cost, (iii) the Handling fee charge.
        </li>
        <li>
          We arrange payment of the duty and tax to local Customs authorities on
          your behalf. The actual tax and duty paid sometimes turns out to be
          higher or lower than the amount we calculated, for a number of
          reasons. For example, the Customs authority in your country may assign
          your goods a different customs code from the one we used; or Customs
          may (for whatever reason) use a different formula to calculate the
          duty; or the calculation may be affected by a change in currency
          exchange rates between the time of our calculation and the time the
          tax and duty is paid to Customs authorities. In such situations, you
          will not receive a refund or be charged extra for the difference; due
          to the administrative time and costs that such arrangements would
          require and the pricing quoted in your ‘Beta-eshopping’ invoice should
          be final.
        </li>
      </ol>
      <h5>SHIPPING CHARGES</h5>
      <ol>
        <li>
          You are responsible for payment of all actual international shipping
          and handling charges
        </li>
        <li>
          Volumetric weight may apply to bulky, lightweight packages. Volumetric
          weight is calculated by multiplying the height by width and length in
          meters and multiplying by 167 to produce a weight expressed in kilos.
        </li>
        <li>
          Your final cost will include any applicable country-specific
          government-mandated clearance fees in addition to duty, tax, shipping,
          government levies, and fuel surcharges if applicable.
        </li>
      </ol>
      <h5>ADDITIONAL FEES</h5>
      <ol>
        <li>
          Additional fees are also merchant fees required before an order will
          be fulfilled. The fees can be any of the following
        </li>
        <li>Shipping fees from the seller to our warehouse,</li>
        <li>
          Additional duties/customs fees from the merchant to our warehouse
        </li>
        <li>Customization fees.</li>
        <li>
          Any time there are additional merchant fees, a detailed email will be
          sent to the customer to notify him/her of the development.
        </li>
      </ol>

      <h5>DELIVERY</h5>
      <ol>
        <li>
          ‘Beta-eshopping’ will make every reasonable effort to deliver the
          shipment to you according to our regular schedules, but such schedules
          cannot be guaranteed and do not form part of the agreement between us.
        </li>
        <li>
          Shipments can be delivered directly to most addresses (except post
          office boxes). However, in certain remote areas, you may need to pick
          up your package from our closest pick-up location
        </li>
      </ol>
      <h5>COMPLIANCE WITH LAW: NO ILLEGAL MATERIALS OR PROHIBITED GOODS.</h5>
      <p>
        You agree to comply with all applicable laws and regulations in effect
        in the country from which the goods are shipped, and in the country in
        which the goods are received. You will not use or attempt to use
        ‘Beta-eshopping’ to receive or send illegal, hazardous or dangerous
        materials, prohibited goods, or controlled substances. Goods are
        unacceptable for shipment if:
      </p>
      <ol>
        <li>
          They are deemed to be Unacceptable Shipments as per our preferred
          shipping company’s terms and conditions of carriage;
        </li>
        <li>
          The goods are classified as hazardous material, dangerous goods, or
          articles prohibited or restricted by IATA (International Air Transport
          Association), ICAO (International Civil Aviation organisation), export
          control laws and regulations, import or customs regulations, or any of
          the laws or regulations of any applicable government department or
          other relevant organization;
        </li>
        <li>
          We decide that we cannot transport an item safely or legally (such
          items include but are not limited to: animals, currency, bearer-form
          negotiable instruments, precious metals and stones, firearms, parts
          thereof and ammunition, pornography, and illegal narcotics/drugs); or
        </li>
        <li>
          ‘Beta-eshopping’ knows or has reason to suspect, that the item or its
          shipment would be in violation of export and import laws and
          regulations as more fully set out in section 34.
        </li>
      </ol>
      <h5>COMPLIANCE WITH LAW: FRAUD AND MONEY LAUNDERING.</h5>
      <p>
        The use of our website and/or service for fraudulent activities or money
        laundering is prohibited. If we have any reason to believe that you use
        our website or services for the above-mentioned, your account will be
        deactivated and you will be reported to the appropriate law enforcement
        agency. <br />
        You agree to comply with all applicable laws and regulations.
      </p>
      <h5>EXPORT AND IMPORT COMPLIANCE.</h5>
      <ol>
        <li>You acknowledge and agree that:</li>
        <li>
          You will comply with all applicable export and import laws and
          regulations;
        </li>
        <li>
          We will at all times comply with all applicable export and import
          laws, and we will refuse to ship any package which we know or have
          reason to suspect is in violation of such laws;
        </li>
        <li>
          We will refuse to ship any item to a destination that requires an
          export or import license;
        </li>
        <li>
          You will not use or attempt to use ‘Beta-eshopping’ to receive or
          send:
        </li>
        <li>
          Any items that are specially designed, modified, adapted, or
          configured for a military application; or
        </li>
        <li>
          Any dual-use items that are subject to export license requirements; or
        </li>
        <li>
          Any products that are intended to be used in chemical or biological
          weapons, missile technology, or for purposes of nuclear proliferation
        </li>
        - To any individual or entity named in: the OFAC list of Specially
        Designated Nationals and Blocked Persons; or any of the lists maintained
        by the US Department of Commerce Bureau of Industry and Security - the
        Denied person List, the Entity List, or the Unverified List; or any of
        the similar lists maintained by the US Department of State such as the
        Debarred Parties List or the Proliferators List;
        <br />
        - To any country which the US Department of the Treasury, Office of
        Foreign Assets Control (‘OFAC’) has designated as supporting
        international terrorism (currently Cuba, Iran, North Korea, Sudan, and
        Syria); or <br />- To any entities or individuals listed in the
        Consolidated United Nations Security Council Sanctions List, the
        European Union Consolidated Financial Sanctions List, the Consolidated
        Canadian Autonomous Sanctions List, the United Kingdom Consolidated List
        of Financial Sanctions Targets, the Japanese Ministry of Economy, Trade,
        and Industry End User List, or similar national or international lists
        <li>
          You will be liable and agree to indemnify us for any costs, penalties,
          or fines which may be incurred if any shipment is found to violate any
          of the foregoing conditions and for any legal costs that we may incur
          in connection with such violations or reporting violations to the
          appropriate authorities; and
        </li>
        <li>
          We will have the right to terminate your account and your use of
          ‘Beta-eshopping’ services if you violate any of the above agreements
          and acknowledgments.
        </li>
      </ol>
      <h5>SHOPPING FROM CHINA</h5>
      <p>
        <ol>
          <li>
            Beta-eShopping offers the opportunity to shop from selected Chinese
            stores, providing access to a wide range of products. Please note
            that there may be variations in items ordered, such as size, color,
            or design, and these discrepancies may not be apparent until the
            items arrive in Nigeria.
          </li>
          <li>
            Beta-eShopping will make every reasonable effort to ensure the
            accuracy of product listings; however, we cannot guarantee that the
            received item will be identical to the one depicted in the listing.
          </li>
          <li>
            Customers are responsible for the authenticity of the item links
            they provide when making purchases from Chinese sellers. We advise
            customers to exercise caution and due diligence on the store/seller
            they are buying from.
          </li>
          <li>
            Some Chinese sellers may engage in fraudulent activities, including
            not sending the ordered items or sending a different item. While
            Beta-eShopping will endeavor to facilitate resolutions in such
            cases, customers should be aware of the potential risks associated
            with online shopping from China.
          </li>
          <li>
            In the event of discrepancies between the ordered item and the
            received item, customers are encouraged to promptly contact
            Beta-eShopping's customer support.
          </li>
          <li>
            In the event of non-receipt of items from Chinese sellers,
            Beta-eShopping will contact you.
          </li>
          <li>
            Refunds for purchases made from China will be subject to the terms
            and conditions of the specific seller and according to
            Beta-eShopping's standard refund policy, with any additional
            measures determined on a case-by-case basis.
          </li>
          <li>Returns are not possible with China orders</li>
        </ol>
      </p>

      <h5>LIMITED LIABILITY FOR LOST OR DAMAGED INTERNATIONAL SHIPMENTS.</h5>
      <p>
        Every package shipped internationally by or for ‘Beta-eshopping’ is
        transported on a limited liability basis subject to the Beta Courier
        Services terms and conditions of carriage (as found at the company
        website of our preferred shipper, https://betalogistics.ng/) which are
        incorporated herein by reference. Our liability in respect of loss or
        damage to any one Shipment transported by air (including ancillary road
        transport or stops en route) shall be limited in accordance with the
        Montreal Convention or the Warsaw Convention as applicable, or in the
        absence of the applicability of such Convention, to the lower of (i) the
        current market or declared value, or (ii) 19 Special Drawing Rights per
        kilogram (approximately $US 26.00 per kilogram).
      </p>
      <p>
        <strong>Refunds, Returns, Claims</strong>
      </p>
      <h5>LOST OR DAMAGED INTERNATIONAL SHIPMENTS.</h5>
      <p>
        In the case of loss of or damage to all or part of a shipment,
        ‘Beta-eshopping’ will refund the charges for the affected item as per
        its standard policies and procedures.
      </p>

      {/* <h5>MAKING CLAIMS</h5>
        <p>
          To facilitate efforts to determine the actual cause of any damage,
          loss or delay, any claims for loss or damage must be submitted to
          ‘Beta-eshopping’ not later than 2 days from the date of delivery or
          expected delivery of your package, failing which we will have no
          liability whatsoever. Claims must be made in writing, accompanied by
          scanned or faxed copies of the consignment note/air waybill and all
          supporting documents, photographs of damaged items, and relevant
          invoices and/or receipts for that item.
        </p> */}
      <h5>PRODUCT RETURNS OR EXCHANGES.</h5>
      <p>
        Only items that are damaged may be returned. If after delivery to you,
        you wish to return a damaged item, you will need to make direct
        arrangements by Contacting Us as soon as possible to arrange for any
        return, refund, or exchange of items. You should first obtain advance
        authorization for product returns and we may give you a return
        authorization number. We will, upon request, help to arrange return
        shipping to the merchant from your home country. You will need to drop
        the Shipment off where directed, likely at the office or service point
        or agent of our preferred shipping provider, Beta Logistics. The
        original customs duty and tax cannot be recouped when returning an
        international shipment back to the merchant.
      </p>
      <p>
        <strong>General</strong>
      </p>
      <h5>MODIFICATION OF ‘BETA-ESHOPPING SERVICES.</h5>
      <p>
        We may modify or terminate our services at any time upon reasonable
        notice, either temporarily or permanently, without incurring any
        liability to you except for completing the processing of products
        ordered prior to the date of termination.
      </p>
      <h5>TERMINATION</h5>
      <p>
        You may terminate this agreement and your use of your ‘Beta-eshopping’
        account at any time. As noted elsewhere in these terms and conditions,
        we may suspend or terminate your account if you fail to comply with
        these terms and conditions, and in the event of such suspension or
        termination, we will incur no liability to you.
      </p>
      <h5>CIRCUMSTANCES BEYOND OUR CONTROL.</h5>
      <p>
        ‘Beta-eshopping’ is not liable for any loss or damage arising out of
        circumstances beyond our reasonable control. These include, but are not
        limited to: acts of God, fires, earthquakes, hurricanes, tornados,
        floods, fogs, storms and other natural disasters, plane crashes,
        embargoes, wars, riots, acts of terrorism, civil commotions, strikes or
        other industrial actions, epidemics or pandemics, power cuts,
        disruptions of communications or other utilities, governmental actions
        or inactions, restraint of princes, any defects or characteristics
        related to the nature of a shipment even if known to ‘Beta-eshopping’,
        and damage to or erasure of electronic or photographic images, data, or
        recordings due to electrical or magnetic interference.
      </p>
      <h5>INDEMNIFICATION.</h5>
      <p>
        You agree to indemnify, defend and hold ‘Beta-eshopping’ and its
        shareholders, officers, directors, affiliated companies, employees, and
        agents harmless from all claims, losses, damages, liabilities, and costs
        (including reasonable attorneys fees) incurred by ‘Beta-eshopping’ which
        result from your use of the ‘Beta-eshopping’ website and
        ‘Beta-eshopping’ services, including but not limited to your negligent
        acts or omissions, any misrepresentations you make in connection with
        obtaining ‘Beta-eshopping services’, any misdeclaration of contents of
        value or packages for export purposes, and/or your failure to comply
        with these terms and conditions.
      </p>
      <h5>ENTIRE AGREEMENT.</h5>
      <p>
        These terms and conditions (or as they may be modified from time to
        time) constitute the entire agreement between us for the use of the
        ‘Beta-eshopping’ website, your use of your account, and the provision of
        ‘Beta-eshopping’ services. Any other written or oral statements are
        superseded and of no effect.
      </p>
      <h5>PARTIAL INVALIDITY.</h5>
      <p>
        If any provision of this agreement is judged by a court or body having
        jurisdiction as illegal, invalid, or unenforceable, such provision will
        be deemed deleted without affecting any other provision or in any other
        jurisdiction.
      </p>
      <h5>WAIVER</h5>
      <p>
        Any failure to enforce any provision of this agreement, or any failure
        to exercise any right at any time, will not be deemed a waiver thereof.
      </p>
      <h5>HEADINGS</h5>
      <p>
        Section headings are for convenience and reference only and do not form
        part of this agreement.
      </p>
      <h5>GOVERNING LAW.</h5>
      <p>
        This agreement is governed by the laws of the United Kingdom, without
        regard to conflict of law principles.
      </p>
      <h5>SHIPPING INSURANCE AND PROTECTION.</h5>
      <p>
        Beta Logistics Insurance meets the general requirements for loss and
        damage to goods in transit. Protection is effective from the time Beta
        Logistics has control or possession of the shipment until the time of
        delivery.
      </p>

      <h5>WALLET USAGE</h5>
      <p>
        <ol>
          <li>
            The Beta-eShopping Wallet is a digital payment feature provided for
            your convenience during transactions within the Beta-eShopping app.
          </li>
          <li>
            The wallet can be funded through various payment methods specified
            within the app.
          </li>
          <li>
            You are responsible for maintaining the security and confidentiality
            of your wallet.
          </li>
          <li>
            Notify Beta-eShopping immediately if you suspect unauthorized access
            to your wallet.
          </li>
          <li>
            Funds in your wallet are for use exclusively within the
            Beta-eShopping app.
          </li>
          <li>Refunds for purchases will be credited back to the wallet.</li>
          <li>Returns are subject to the Beta-eShopping return policy.</li>
          <li>
            Wallet users may receive exclusive discounts and promotions at
            Beta-eShopping's discretion.
          </li>
          <li>
            Beta-eShopping reserves the right to suspend or terminate your
            wallet access in case of misuse or violation of these terms.
          </li>
          <li>
            Beta-eShopping is not liable for any unauthorized access or loss of
            wallet funds due to negligence on your part.
          </li>
        </ol>
      </p>
    </>
  );
};

export default TermsAndConditions;
