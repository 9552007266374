export const supportedStores = [
  "sports direct",
  "aztecasoccer",
  "amazon",
  "amazon uk",
  "world of watches",
  "lauras boutique",
  "carter's",
  "clarks",
  "prettylittlething",
  "carparts4less",
  "perfumebox",
  "sparepartsstore24",
  "burton",
  "zara",
  "aldo",
  "carid",
  "jd sports",
  "bath & body works",
  "matchesfashion",
  "gymshark",
  "lookfantastic",
  "t.m. lewin",
  "cottonon",
  "cookie's kids",
  "tk maxx",
  "ebonyline",
  "neiman marcus",
  "oshkosh",
  "accessorize",
  "dorothy perkins",
  "emporio armani",
  "men's wearhouse",
  "nine west",
  "watch station",
  "kitbag",
  "zalando",
  "standout",
  "scotts",
  "divatress",
  "madewell",
  "rag & bone",
  "kith us",
  "bnkr",
  "office",
  "forever 21",
  "nyx cosmetics",
  "hudabeauty",
];
