import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/authContext";
import Header from "../../components/Header";
import SideBar from "../../components/SideBar";
import LoginPage from "../login";
import { clean_naira_format } from "../../utils/utilities";
import { getCurrentRate } from "../../services/api";

import "./styles.scss";

const CurrentRatePage = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const [currentRate, setCurrentRate] = useState(null);
  const [poundsRate, setPoundsRate] = useState(null);

  // const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  const getCurrentRates = async () => {
    const rateRes = await getCurrentRate();
    setLoading(true);
    if (rateRes.response?.status >= 400) {
      // setError(rateRes.response.data.detail);
      setLoading(false);
    } else {
      setCurrentRate(rateRes.data.dollar_rate);
      setPoundsRate(rateRes.data.pounds_rate);
      setLoading(false);
    }
  };

  useEffect(() => {
    getCurrentRates();
    if (!isAuthenticated) {
      return <LoginPage path="/profile" />;
    }
    // eslint-disable-next-line no-unused-vars
  }, [isAuthenticated]);

  return (
    <div>
      <Header hideAuth={true} />

      <div className="custom_container custom_container--pall">
        <section style={{ height: "90vh", marginTop: 20 }}>
          <div className="custom-row">
            <div
              style={{
                padding: "10px 0 0 0",
              }}
              className="profile-sidebar first"
            >
              <SideBar path="/current-rate" />
            </div>
            <div lg={6} className="second px-4" style={{ boxShadow: "none" }}>
              <>
                {!loading ? (
                  <div>
                    <div className="account_box">
                      <h4 className="mb-4">About Exchange Rates</h4>
                      <h6>
                        {" "}
                        Dollar to Naira: $1 ={" "}
                        {currentRate !== null &&
                          clean_naira_format(currentRate)}
                      </h6>
                      <h6>
                        {" "}
                        Pounds to Dollar: £1 = $
                        {poundsRate !== null && poundsRate}
                      </h6>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-dc flex-jc-c flex-ai-c mt-5">
                    <i className="fa fa-spinner fa-2x text-secondary mb-1"></i>
                    <small className="text-secondary text-sm">
                      Just a moment
                    </small>
                  </div>
                )}
              </>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default CurrentRatePage;
