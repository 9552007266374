import * as React from "react";
const SvgPay = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={170}
    height={170}
    fill="none"
    {...props}
  >
    <circle cx={85.211} cy={85.327} r={84.368} fill="#FED6D6" />
    <g clipPath="url(#a)">
      <path
        fill="#EE4347"
        d="m83.572 93.616-6.668-6.735a6.167 6.167 0 0 1-1.772-4.347c0-1.297.4-2.595 1.214-3.687a6.014 6.014 0 0 1 4.323-2.41 5.99 5.99 0 0 1 4.652 1.664l5.747 5.544v-50.09H60.284v60.063h23.288v-.002ZM66.77 64.666h-1.452c-.583 0-1.056-.48-1.056-1.071s.473-1.071 1.056-1.071h1.444c.289-2.526 1.782-4.37 4.06-4.97.341-.09.697-.016.971.2a1.135 1.135 0 0 1-.374 1.98c-1.328.411-2.255 1.434-2.552 2.788h6.649c.845-2.86 2.034-4.741 4.885-4.741 2.584 0 4.462 1.886 4.772 4.74h.857c.582 0 1.055.48 1.055 1.072 0 .591-.473 1.07-1.055 1.07h-.897c-.419 2.988-2.26 4.034-3.35 4.392a1.11 1.11 0 0 1-1.01-.161 1.17 1.17 0 0 1-.471-.935c0-.48.295-.912.733-1.078 1.083-.402 1.725-1.112 1.99-2.217h-5.842c-1.087 3.532-2.65 4.982-5.342 4.982-2.886 0-4.718-1.809-5.071-4.982v.002Z"
      />
      <path
        fill="#EE4347"
        d="M83.109 62.528h-5.298c.71-1.993 1.58-2.414 2.72-2.414.861 0 2.285.327 2.58 2.414h-.002ZM71.738 67.296c-1.696 0-2.626-.838-2.892-2.628h6.031c-.813 2.165-1.852 2.628-3.14 2.628Z"
      />
      <path
        fill="#3F3B7F"
        d="M106.064 125.757H88.319c-.39-4.347-2.688-8.289-6.313-10.733-3.375-2.276-5.391-6.093-5.391-10.205v-8.984h8.853c.576.992.89 2.134.89 3.293v.702c0 3.295 1.816 6.303 4.625 7.831.585.32 1.314.031 1.541-.604.043-.124.066-.254.066-.379a1.1 1.1 0 0 0-.566-.969c-2.109-1.142-3.476-3.403-3.476-5.879v-.702a8.828 8.828 0 0 0-.643-3.293 8.736 8.736 0 0 0-1.886-2.882l-7.572-7.646c-1.357-1.37-1.51-3.571-.355-5.122a3.832 3.832 0 0 1 2.757-1.539 3.823 3.823 0 0 1 2.964 1.063l7.253 6.998 2.187 2.11 2.28 2.2a1.082 1.082 0 0 0 1.548-.038 1.12 1.12 0 0 0-.04-1.568l-3.788-3.653V71.9c.386.386.79.786 1.198 1.195 4.051 4.024 9.091 9.038 10.783 13.19 2.187 5.37 1.725 11.402 1.276 17.237l-.119 1.587c-.447 6.112-.397 12.301-.345 18.285.006.78.012 1.57.02 2.364l-.002-.002ZM84.02 144.768v-16.789h26.118v16.785l-26.118.004Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M110.138 33.556v111.212H60.284V33.555z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgPay;
