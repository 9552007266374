// imageData.js
export const CreateAccount = require("./assets/create an account.png");
export const PlaceCartOrder = require("./assets/place-cart-order.png");
export const CompleteCheckout = require("./assets/complete-checkout.png");
export const Checkout = require("./assets/checkout.png");
export const Stores = require("./assets/stores.png");
export const ShopNow = require("./assets/shop-now.png");
export const chromeEx = require("./assets/chrome-extension.png");
export const CustomOrder = require("./assets/custom-order.png");
export const CreateCustomOrder = require("./assets/create-custom-order.png");
export const PlaceCustomOrder = require("./assets/custom-order-place-order.png");
export const orderPlaced = require("./assets/order-placed.png");
