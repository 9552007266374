import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import styles from "./style";
import "./style.scss";
import {  getCategories, getPostbyCategory } from "../../services/api";
import moment from "moment";
import { useParams } from "react-router-dom";
import { truncate } from "../../utils/utilities";
import SearchBar from "../../components/SearchBar";
import Footer from "../../components/Footer";


const CategoryPage = () => {
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { slug } = useParams();

  const getPostsList = async () => {
    setLoading(true);
    const postList = await getPostbyCategory(slug);
    if (postList.response?.status >= 400) {
      setError(postList.response.data.detail);
    } else {
      setPosts(postList.data);
    }
    setLoading(false);

  };

  const handleOnClick = (id) => {
    navigate(`/blog/categories/${id}`);
  };

  const getCategoriesList = async () => {
    setLoading(true); 
    const catList = await getCategories();
    if (catList.response?.status >= 400) {
      setError(catList.response.data.detail);
    } else {
      setCategories(catList.data);
      const catData = catList.data.find((cat) => cat.slug === slug);
      setCategoryId(catData.id);
      getPostsList();
    }
    setLoading(false);

  };

  const handleDetailView = (slug) => {
    navigate(`/blog/${slug}`);
  };

  const postContentMarkup = (post) => {
    return { __html: truncate(post.content, 250) };
  };

  const returnCategory = (id) => {
    const catData = categories.find((cat) => cat.id === id);
    return catData.title
  }

  useEffect(() => {
    getCategoriesList();
  }, [slug]);

  return (
    <div>
      <Header hideAuth={true} />
      <Container style={styles.bodyContainer}>
      <div className="test-search">
              <SearchBar
                handleDetailView={handleDetailView}
                placeholder="Search a keyword..."
                data={posts}
              />
            </div>
            <div className="categories categories_scroll  flex-ai-c mt-3">
              {categories.length !== 0 ? 
                categories.map((category, index) => {
                  return (
                    <span
                      key={index}
                      onClick={() => handleOnClick(category.slug)}
                      className={`${
                        category.id === categoryId
                          ? "badge bes_purple mr-1"
                          : "badge text-dark bg-light mr-1"
                      }`}
                    >
                      {category.title}
                    </span>
                  );
                }):null}
            </div>
        {loading === false ? (
          <div>
            <Row className="hide-for-desktop">
              {posts.length !== 0 ? posts.map((post, index) => (
                  <Col
                    lg={4}
                    key={index}
                    style={{ justifyContent: "center", marginTop: "20px" }}
                  >
                    <div
                      className="blog"
                      onClick={() => handleDetailView(post.slug)}
                    >
                      <img
                        className="img-fluid"
                        src={post.image_url}
                        alt={post.slug}
                      />
                      <div className="title-card">
                        <span className="badge badge bg-dark text-uppercase">{returnCategory(categoryId)}</span>
                        <br />
                        <h6 className="postTitleInList ">
                          {truncate(post.title, 40)}
                        </h6>
                        <br />
                        <span className="badge badge bg-secondary text-light">
                          {moment(post.created_at).format("MMMM Do YYYY")}
                        </span>
                      </div>
                    </div>
                  </Col>
                )):null}
            </Row>

            <div className="hide-for-mobile" style={{ justifyContent: "left", marginBottom: "12rem" }}>
              {posts.length !== 0 ? posts.map((post, index) => (
                  <div
                    className="searchSectionBox custom_container custom_container--pall"
                    key={index}
                    style={{ marginBottom: "30px" }}
                  >
                    <div
                      className="searchSection "
                      onClick={() => handleDetailView(post.slug)}
                    >
                      <div className="flex flex-ai-c">
                        <img src={post.image_url} alt={post.slug} />
                        <div className="ml-1 flex flex-dc flex-ai-c">
                          <div>
                            <h4 className="searchSection__title mb-2 mt-5 ">
                              {truncate(post.title, 50)}
                            </h4>
                            <p className="mb-3 bes_purple-color">
                              <i className="fas fa-calendar-alt"></i>
                              <small>
                                {" "}
                                {moment(post.created_at).format("MMMM Do YYYY")}
                              </small>
                            </p>

                            <div
                              className="text-dark mb-3 contentOnSearch"
                              dangerouslySetInnerHTML={postContentMarkup(post)}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )):null}
            </div>
          </div>
        ) : (
          <div className="flex flex-jc-c mt-5">
            <div className="flex flex-dc flex-jc-c flex-ai-c mt-5 ">
              <i className="fas fa-spinner mb-1 fa-2x"></i>
              <small> Just a moment</small>
            </div>
          </div>
        )}
      </Container>
      { loading === false && posts.length > 0 ? <Footer /> : null}
    </div>
  );
};

export default CategoryPage;
