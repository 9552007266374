import React, { useState, useEffect, useContext, useCallback } from "react";
import { Button, Container, Row, Col, Card, Image } from "react-bootstrap";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { supportedStores } from "../store/supportedStores";

// SVG React Components
import SvgPay from "./assets/pay";
import SvgCart from "./assets/cart";
import SvgDelivery from "./assets/delivery";
import SvgShop from "./assets/shop";

import SvgCartMobile from "./assets/cartmobile";
import SvgPayMobile from "./assets/paymobile";
import SvgDeliveryMobile from "./assets/deliverymobile";
import SvgShopMobile from "./assets/shopmobile";

// Images
import Woman from "./assets/lady_updated.png";
import Man from "./assets/man_updated.png";
import ManMobile from "./assets/man-mobile.png";
import WonanMobile from "./assets/woman-mobile.png";
import Loading from "../../components/Loading";

import {
  ROUNDED_BUTTON,
  ROUNDED_BUTTON_SECONDARY,
} from "../../utils/styles/buttons";

import { fetchStores } from "../../services/api";
import ImageContext from "../../context/imageContext";
import CustomCarousel from "../../components/CustomCarousel";
import styles from "./style";
import "./style.scss";
import { Colors } from "../../utils/styles";

const HomePage = () => {
  const [stores, setStores] = useState([]);
  const [showImage, setShowImage] = useState(true);
  const { bannerImage, promoImage } = useContext(ImageContext);

  const handleDownloadApp = () => {
    window.open("https://onelink.to/beta-eshopping", "_blank");
  };

  const toggleImage = useCallback(() => {
    setShowImage(!showImage);
  }, [showImage]);

  const handleImageToggle = useCallback(() => {
    const timeout = setTimeout(toggleImage, 7000);
    return () => {
      clearTimeout(timeout);
    };
  }, [toggleImage]);

  useEffect(handleImageToggle, [handleImageToggle]);

  useEffect(() => {
    getStores();
  }, []);

  const getStores = async () => {
    const storeList = await fetchStores();
    const sortedStores = storeList.data.sort((a, b) => {
      const nameA = a.displayname.toUpperCase(); // ignore upper and lowercase
      const nameB = b.displayname.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    const supportedStoresData = sortedStores.filter((store) =>
      supportedStores.includes(store.displayname.toLowerCase())
    );
    const first12Stores = supportedStoresData.slice(0, 12);
    setStores(first12Stores);
  };

  const storeOftheWeekImage =
    promoImage &&
    promoImage
      .filter((image) => image.promo_type === "store_of_the_week")
      .shift();

  const itemOftheWeekImage =
    promoImage &&
    promoImage
      .filter((image) => image.promo_type === "item_of_the_week")
      .shift();

  const promoImages =
    promoImage &&
    promoImage.filter((image) => image.promo_type === "promo_image");

  return (
    <React.Fragment>
      <Header />
      <div>
        <div className="banner mobile-banner">
          <Container className="home-title-container">
            <Row className="mobile-header">
              <Col lg={6}>
                <div className="mb-2 px-4 py-4 mb-lg-0 text-center text-lg-start">
                  <div
                    style={{
                      fontSize: 55,
                      fontWeight: 900,
                      color: "#EE4347",
                      lineHeight: 1.3,
                    }}
                    className="home-hero-header"
                  >
                    Shop Globally Receive locally
                  </div>
                  <p
                    className="lead fw-normal mb-5 text-description"
                    style={{ color: "#2E2B2B" }}
                  >
                    Shop from your favorite stores in Europe and North America
                    and{" "}
                    <span className="fw-bold">
                      get it delivered to your doorstep
                    </span>
                  </p>
                  <div className="action-btn-container">
                    <Button
                      size="lg"
                      href="/signin"
                      style={ROUNDED_BUTTON}
                      className="action-button"
                    >
                      Shop Now
                    </Button>

                    <Button
                      size="lg"
                      onClick={handleDownloadApp}
                      style={ROUNDED_BUTTON_SECONDARY}
                      className="btn-secondary"
                    >
                      Download App
                    </Button>
                  </div>
                </div>
              </Col>
              <div className="hide-for-desktop flex flex-jc-c ai-i-c">
                {showImage ? (
                  <div className="fade-in">
                    <img
                      src={ManMobile}
                      alt="lady-with-stores"
                      width="100%"
                      height="100%"
                    />
                  </div>
                ) : (
                  <div className="fade-in">
                    <img
                      src={WonanMobile}
                      alt="lady-with-stores"
                      width="100%"
                      height="100%"
                    />
                  </div>
                )}
              </div>
              <Col lg={6}>
                <div className="hide-for-mobile">
                  {showImage ? (
                    <div className="fade-in">
                      <img
                        src={Woman}
                        alt="lady-with-stores"
                        style={
                          Object.values(bannerImage).every((val) => val !== "")
                            ? {
                                position: "absolute",
                                overflow: "hidden",
                                height: 420,
                                width: "50%",
                                objectFit: "contain",
                              }
                            : styles.carousel
                        }
                      />
                    </div>
                  ) : (
                    <img
                      src={Man}
                      alt="man-with-stores"
                      className="fade-in"
                      style={
                        Object.values(bannerImage).every((val) => val !== "")
                          ? {
                              position: "absolute",
                              overflow: "hidden",
                              height: 420,
                              width: "50%",
                              objectFit: "contain",
                            }
                          : styles.carousel
                      }
                    />
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Container className="mb-4 mt-5">
          <Row>
            <Col lg={12}>
              <p style={styles.sectionHeader}>The Beta Shopping Experience</p>
            </Col>
          </Row>
        </Container>
        <div>
          <div
            className="shopping-icons hide-for-mobile"
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <div>
              <div style={styles.icon}>
                <SvgShop />
              </div>
              <p style={styles.iconText}>Shop from 100+ US/UK Stores</p>
            </div>
            <div>
              <div style={styles.icon}>
                <SvgCart />
              </div>
              <p style={styles.iconText}>Add items to Cart</p>
            </div>
            <div>
              <div style={styles.icon}>
                <SvgPay />
              </div>
              <p style={styles.iconText}>Pay with your Naira or Dollar card</p>
            </div>
            <div style={{ paddingLeft: 10 }}>
              <div style={styles.icon}>
                <SvgDelivery />
              </div>
              <p style={styles.iconText}>
                Get your item delivered withing 15 working days
              </p>
            </div>
          </div>

          <div
            className="shopping-icons hide-for-desktop container"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Row>
              <Col sm={6}>
                <div style={styles.iconMobile}>
                  <SvgShopMobile />
                  <p style={styles.iconTextMobile}>
                    Shop from 100+ US/UK Stores
                  </p>
                </div>
              </Col>
              <Col sm={6}>
                <div style={styles.iconMobile}>
                  <SvgCartMobile />
                  <p style={styles.iconTextMobile}>Add items to Cart</p>
                </div>
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                <div style={styles.iconMobile}>
                  <SvgPayMobile />
                  <p style={styles.iconTextMobile}>
                    Pay with your Naira or Dollar card
                  </p>
                </div>
              </Col>
              <Col sm={6}>
                <div style={styles.iconMobile}>
                  <SvgDeliveryMobile />
                  <p style={styles.iconTextMobile}>
                    Delivery within 15 working days
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        {/* SVG Ends */}
        {/* Shopping section */}
        <div style={{ background: "#F8F8F8" }} className="stores">
          <Container className="mt-5 ">
            <Row className="align-items-center">
              <Row
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  marginTop: 50,
                }}
              >
                <Col>
                  <h4
                    className="mb-3"
                    style={{
                      color: Colors.BES_PURPLE,
                      fontSize: 32,
                      fontWeight: 700,
                    }}
                  >
                    Shop from a wide range of stores
                  </h4>
                  <p
                    className="lead fw-normal text-muted mb-3"
                    style={{ fontSize: 16, fontWeight: "normal" }}
                  >
                    Over 30 stores supported by the beta-eshopping chrome
                    extension
                  </p>
                  {/* Insert carousel here */}
                  {/* <HomeCarousel /> */}
                </Col>
              </Row>
              <div
                className="home-stores-list"
                style={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  flexWrap: "wrap",
                }}
              >
                {stores?.length ? (
                  stores.map((store, idx) => (
                    <Col lg={3} sm={3} key={idx}>
                      <a
                        href={store.store_url}
                        target="_blank"
                        rel="noreferrer"
                        style={styles.links}
                      >
                        <div className="store-column">
                          <Card
                            style={{
                              borderRadius: "100%",
                              marginBottom: 30,
                              width: 140,
                              height: 140,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              border: "none",
                              boxShadow:
                                "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            }}
                            className="store-card"
                          >
                            <Card.Body>
                              <Image
                                src={store.store_icon}
                                style={{
                                  width: 140,
                                  height: 140,
                                  borderRadius: "100%",
                                }}
                              />
                            </Card.Body>
                          </Card>
                        </div>
                      </a>
                    </Col>
                  ))
                ) : (
                  <Loading hide={true} />
                )}
              </div>
              <Row
                style={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  marginTop: 50,
                  marginBottom: 50,
                  justifyContent: "center",
                }}
              >
                <Button
                  size="lg"
                  href="/stores"
                  style={{ ...ROUNDED_BUTTON, width: 250 }}
                >
                  Browse more stores
                </Button>
              </Row>
            </Row>
          </Container>
        </div>
        <div className="overflow-hidden">
          {/* promo images */}
          <div className="hide-for-mobile">
            <CustomCarousel data={promoImages} />
          </div>

          <div className="overflow-hidden">
            <Row style={{ marginTop: -3 }}>
              <Col lg={6} className="px-0">
                {storeOftheWeekImage ? (
                  <img
                    src={storeOftheWeekImage.image_url}
                    alt="app-store-promo"
                    width="100%"
                  />
                ) : null}
              </Col>
              <Col lg={6} className="px-0 item-mobile">
                {itemOftheWeekImage ? (
                  <img
                    src={itemOftheWeekImage.image_url}
                    alt="app-store-promo"
                    width="100%"
                  />
                ) : null}
              </Col>
            </Row>
            <Row style={{ marginTop: -3 }}>
              <Col lg={12}>
                {Object.values(bannerImage).every((val) => val !== "") ? (
                  <a
                    href={bannerImage.external_link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={bannerImage.image_url}
                      alt={bannerImage.title}
                      style={{ width: "100%", zIndex: 9999 }}
                      className="bannerv"
                      loading="lazy"
                    />
                  </a>
                ) : null}
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default HomePage;
