import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Container, Row, Col, Modal } from "react-bootstrap";
import { Oval } from "react-loader-spinner";
import { resendOTP } from "../../services/api";
import Header from "../../components/Header";
import styles from "./style";
import { Colors } from "../../utils/styles";

const ResendOTPpage = () => {
  const [error, setError] = useState("");
  const [hasError, setHasError] = useState(false);
  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState(
    localStorage.getItem("userEmail").replace(/"/g, "")
  );

  const navigate = useNavigate();

  const validateForm = () => {
    return email.length > 0;
  };

  const handleClose = () => setShowModal(false);

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const reset_res = await resendOTP({ email });

    if (reset_res.status !== 200) {
      const err = reset_res.response.data;
      const errMsg = Object.keys(err).map((key) => {
        return `${err[key]}`;
      });
      setError(errMsg);
      setHasError(true);
    } else {
      // navigate('/login')

      setHasError(false);
      setShowModal(true);
      setMessage(reset_res.data.message);
    }
    setIsLoading(false);
  };

  return (
    <div>
      <Header hideAuth={true} />
      <Container style={styles.bodyContainer}>
        <Row style={{ justifyContent: "center" }}>
          <Col
            sm={4}
            style={{
              height: "fit-content",
              width: "fit-content",
              border: `2px solid ${Colors.BES_PURPLE}`,
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              borderRadius: 10,
            }}
          >
            <p style={styles.signInHeaderText}>Request New OTP</p>
            <p className="text-center">
              Please enter your email to request for a new OTP.
            </p>

            <Modal
              show={showModal}
              onHide={handleClose}
              size="md"
              centered
              backdrop="static"
            >
              <Modal.Header>
                <Modal.Title>Successfully Sent OTP</Modal.Title>
              </Modal.Header>
              <Modal.Body>{message}</Modal.Body>
              <Modal.Footer>
                <Button
                  style={{
                    background: "#5034FF",
                    color: "#fff",
                    border: "none",
                    margin: "0 auto",
                    width: 100,
                  }}
                  onClick={() => {
                    handleClose();
                    navigate("/otp");
                  }}
                >
                  OK
                </Button>
              </Modal.Footer>
            </Modal>

            <Form onSubmit={handleSubmit} style={styles.signInForm}>
              <Form.Group
                size="lg"
                controlId="email"
                style={styles.signInFormContainer}
              >
                <Form.Control
                  placeholder="Enter email"
                  autoFocus
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={styles.formInput}
                  className="bes-input-box-shadow"
                />
              </Form.Group>
              <Button
                className="block bes-input-box-shadow"
                size="lg"
                type="submit"
                disabled={!validateForm()}
                style={styles.button}
              >
                {isLoading ? (
                  <div className="d-flex align-items-center justify-content-center">
                    <Oval
                      type="Circles"
                      color="#fff"
                      height={30}
                      width={30}
                      wrapperStyle={{ margin: "0 auto" }}
                    />
                  </div>
                ) : (
                  "Send"
                )}
              </Button>

              {hasError && (
                <div className="text-center">
                  <p
                    className={"text-danger text-center mt-2"}
                    style={{ textAlign: "center" }}
                  >
                    {error}
                  </p>
                </div>
              )}
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ResendOTPpage;
