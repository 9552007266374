import { Colors } from "../../utils/styles";

const styles =  {
    bodyContainer: {
        height: "100vh",
        width: "100vw"
    },
    rowSection: {
        height: "100vh"

    },
    col1: {
        backgroundColor: Colors.BES_PURPLE,        
    },
    formInput: {
        borderRadius: 8,
        border: "1px solid #BEBDFF",
        height: 46,
        margin: "8 0",
        fontSize: "14px"
    },
    registerFormLabel: {
        color: "#181743",
        fontSize: 16,
        fontWeight: 500,
    },
    loginText: {
        margin: "0 auto",
        marginTop: 40,
        fontSize: 16,
        textAlign: "center",
        color: "#ffff",
        fontWeight: 'normal',
    },
    loginSpanText: {
        color: 'tomato',
        marginLeft: 5,
    },
    termsAndConditionButton: {
        background: "none",
        border: "none",
        color: Colors.BES_PURPLE,
        cursor: "pointer",
        verticalAlign: "text-bottom",
        marginLeft:10
    },
};

export default styles;
