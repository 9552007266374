import React from "react";
import { Colors } from "../../../utils/styles";
import { Button } from "react-bootstrap";
import { ROUNDED_BUTTON_SECONDARY } from "../../../utils/styles/buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import WalletBackground from "../assets/background.png";

const Card = ({
  balance,
  showFundingOptions,
  toggleBalance,
  isBalanceVisible,
}) => {
  return (
    <div style={styles.container} className="mobile-wallet-card">
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          gap: 10,
        }}
      >
        <p style={{ ...styles.text, marginBottom: 0 }}>Wallet Balance</p>
        <FontAwesomeIcon
          icon={isBalanceVisible ? faEye : faEyeSlash}
          color={Colors.WHITE}
          size="xs"
          onClick={toggleBalance}
        />
      </div>

      <h2 style={styles.walletBalance}>{balance}</h2>
      {/* </Button> */}
      <Button
        size="md"
        style={{
          ...ROUNDED_BUTTON_SECONDARY,
          width: 250,
          height: 45,
          backgroundColor: "white",
        }}
        onClick={showFundingOptions} // trigger fund wallet modal
      >
        <FontAwesomeIcon icon={faPlus} color={Colors.BES_BLACK} size="xs" />
        {"  "}
        Fund Wallet
      </Button>
    </div>
  );
};

export default Card;

const styles = {
  container: {
    backgroundColor: Colors.BES_PURPLE,
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    justifyContainer: "center",
    alignItems: "center",
    paddingTop: 20,
    paddingBottom: 20,
    boxShadow: "rgba(0, 0, 0, 0.25) 0px 25px 50px -12px",
    backgroundImage: `url(${WalletBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right",
    backgroundSize: "contain",
  },
  image: {
    backgroundImage: require("../assets/background.png"),
    backgroundColor: "#cccccc",
    height: 300,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
  },
  walletBalance: {
    fontSize: 40,
    fontWeight: "bold",
    color: Colors.WHITE,
    paddingBottom: 20,
  },
  text: {
    color: Colors.WHITE,
    textAlign: "center",
    fontSize: 14,
  },
};
