import * as React from "react"
const SvgPayMobile = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={82}
    height={83}
    fill="none"
    {...props}
  >
    <circle cx={40.962} cy={41.428} r={40.962} fill="#FED6D6" />
    <g clipPath="url(#a)">
      <path
        fill="#EE4347"
        d="m40.167 45.452-3.238-3.27a2.994 2.994 0 0 1-.86-2.11c0-.63.194-1.26.59-1.79a2.92 2.92 0 0 1 2.099-1.17c.83-.07 1.653.225 2.258.808l2.79 2.691v-24.32H28.86v29.162h11.307Zm-8.158-14.055h-.705a.517.517 0 0 1-.512-.52c0-.287.23-.52.512-.52h.701c.14-1.227.865-2.122 1.972-2.413a.536.536 0 0 1 .471.097.551.551 0 0 1-.181.962c-.645.199-1.096.695-1.24 1.353h3.228c.41-1.389.988-2.302 2.372-2.302 1.255 0 2.166.916 2.317 2.302h.416c.283 0 .513.233.513.52 0 .287-.23.52-.513.52h-.435c-.204 1.45-1.097 1.958-1.627 2.132a.54.54 0 0 1-.49-.079.568.568 0 0 1-.229-.454c0-.233.143-.442.356-.523.526-.195.837-.54.966-1.076h-2.836c-.528 1.714-1.287 2.419-2.594 2.419-1.4 0-2.29-.879-2.462-2.42v.002Z"
      />
      <path
        fill="#EE4347"
        d="M39.942 30.359h-2.573c.345-.968.768-1.173 1.321-1.173.419 0 1.11.16 1.253 1.173h-.001ZM34.42 32.674c-.823 0-1.274-.407-1.403-1.276h2.928c-.395 1.05-.9 1.276-1.524 1.276Z"
      />
      <path
        fill="#3F3B7F"
        d="M51.087 61.058h-8.616a7.028 7.028 0 0 0-3.065-5.212 5.961 5.961 0 0 1-2.617-4.954V46.53h4.298c.28.481.432 1.036.432 1.599v.34c0 1.6.882 3.06 2.246 3.802a.527.527 0 0 0 .748-.293.534.534 0 0 0-.243-.654 3.254 3.254 0 0 1-1.688-2.854v-.341a4.287 4.287 0 0 0-1.227-2.998l-3.677-3.713a1.924 1.924 0 0 1-.172-2.487 1.861 1.861 0 0 1 1.339-.747 1.856 1.856 0 0 1 1.439.516l3.521 3.398 1.062 1.025 1.107 1.067c.213.206.55.198.752-.018a.544.544 0 0 0-.02-.761l-1.839-1.774V34.91l.582.58c1.967 1.953 4.414 4.388 5.235 6.404 1.062 2.607.837 5.535.62 8.369-.02.257-.04.515-.058.77-.217 2.967-.193 5.972-.168 8.878l.01 1.148v-.001ZM40.384 70.288v-8.152h12.68v8.15l-12.68.002Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M53.065 16.292v53.996H28.86V16.292z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgPayMobile
