// FONT FAMILY
export const FONT_FAMILY_Poppins = 'Poppins';

// FONT WEIGHT
export const FONT_WEIGHT_REGULAR = '400';
export const FONT_WEIGHT_MEDIUM = '600';
export const FONT_WEIGHT_BOLD = '700';

// FONT STYLE
export const FONT_REGULAR = {
  fontFamily: FONT_FAMILY_Poppins,
  fontWeight: FONT_WEIGHT_REGULAR,
};

export const FONT_MEDIUM = {
  fontFamily: FONT_FAMILY_Poppins,
  fontWeight: FONT_WEIGHT_MEDIUM,
};

export const FONT_BOLD = {
  fontFamily: FONT_FAMILY_Poppins,
  fontWeight: FONT_WEIGHT_BOLD,
};
