import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../../components/Header";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import styles from "./style";
import "./style.scss";
import { getPosts, getCategories } from "../../services/api";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { truncate, ITEMS_PER_PAGE } from "../../utils/utilities";
import Footer from "../../components/Footer";

const BlogPage = () => {
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [listPosts, setlistPosts] = useState([]);

  useEffect(() => {
    const endOffset = itemOffset + ITEMS_PER_PAGE;
    setPageCount(Math.ceil(posts.length / ITEMS_PER_PAGE));
    setlistPosts(posts.slice(itemOffset, endOffset));
    setLoading(false);
  }, [posts, itemOffset]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * ITEMS_PER_PAGE) % posts.length;
    setItemOffset(newOffset);
  };

  const getPostsList = async () => {
    setLoading(true);
    const postList = await getPosts();
    if (postList?.response?.status >= 400) {
      setError(postList?.response.data.detail);
    } else {
      setPosts(postList.data);
    }
  };

  const getCategoriesList = async () => {
    setLoading(true);
    const catList = await getCategories();
    if (catList.response?.status >= 400) {
      setError(catList.response.data.detail);
    } else {
      setCategories(catList.data);
      setLoading(false);
    }
  };
  const handleDetailView = (slug) => {
    navigate(`/blog/${slug}`);
  };

  const navigateToCategory = (slug) => {
    navigate(`/blog/categories/${slug}`);
  };

  const returnCategory = (id) => {
    const catData = categories.find((cat) => cat.id === id);
    return catData?.title;
  };

  useEffect(() => {
    getPostsList();
    getCategoriesList();

    // eslint-disable-next-line no-unused-vars
  }, []);
  // console.log("class. posts", posts)
  return (
    <div>
      <Header hideAuth={true} />
      <Container style={styles.bodyContainer}>
        {loading === false ? (
          <div>
            <div className="test-search">
              <SearchBar
                handleDetailView={handleDetailView}
                placeholder="Search a keyword..."
                data={posts}
              />
            </div>
            <div className="categories categories_scroll  mb-5 mt-3 ">
              {categories.length != 0 ?
                categories?.map((category, index) => {
                  return (
                    <span
                      key={index}
                      onClick={() => navigateToCategory(category.slug)}
                      className="badge text-dark bg-light mr-1"
                    >
                      {category.title}
                    </span>
                  );
                }): null}
            </div>

            <Row className="container"
            style={{ justifyContent: "left", marginBottom: "7rem" }}
            >
              {listPosts.length !=0 ? listPosts.map((post, index) => (
                <Col
                  lg={4}
                  key={index}
                  style={{ justifyContent: "center", marginTop: "20px" }}
                >
                  <div
                    className="blog"
                    onClick={() => handleDetailView(post.slug)}
                  >
                    <img
                      className="img-fluid"
                      src={post.image_url}
                      alt={post.slug}
                    />
                    <div className="title-card">
                      <span className="badge badge bg-dark text-uppercase">
                        {returnCategory(post.categories[0])}
                      </span>
                      <br />
                      <h6 className="postTitleInList ">
                        {truncate(post.title, 40)}
                      </h6>
                      <br />
                      <span className="badge badge bg-secondary text-light">
                        {moment(post.created_at).format("MMMM Do YYYY")}
                      </span>
                    </div>
                  </div>
                </Col>
              )):null}
{posts.length != 0 ? posts.length > ITEMS_PER_PAGE && (
              <div
                style={{ justifyContent: "left", marginBottom: "1rem" }}
                className="d-flex justify-content-center align-items-center"
              >
                <ReactPaginate
                  containerClassName="pagination "
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  breakLabel="..."
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  renderOnZeroPageCount={null}
                />
              </div>
            ):null
            }
              
            </Row>
            

            
          </div>
        ) : (
          <div className="flex flex-jc-c mt-5">
            <div className="flex flex-dc flex-jc-c flex-ai-c mt-5 ">
              <i className="fas fa-spinner mb-1 fa-2x"></i>
              <small> Just a moment</small>
            </div>
          </div>
        )}

      </Container>
     { loading == false && posts.length > 0 ? <Footer /> : null}

    </div>
  );
};

export default BlogPage;
