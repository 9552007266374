import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import Accordion from "react-bootstrap/Accordion";

const FaqPageMobile = () => {
  return (
    <>
      <div className="mt-3 mb-5">
        <Container>
          <Row>
            <Col lg={2}></Col>
            <Col lg={8}>
              <Accordion defaultActiveKey="0" flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    How Does the Beta-eshopping application work?
                  </Accordion.Header>
                  <Accordion.Body>
                    Beta-eshopping app makes it easy for you to purchase
                    your favorite products from US & Europe websites - all from
                    the comfort of your own home or place of work. All you have
                    to do is to navigate to supported stores on the application, add items to your cart and checkout.
                     You can also shop via customs order after filling the necessary details for your orders.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    Do I need credit card to make purchases?
                  </Accordion.Header>
                  <Accordion.Body>
                    Yes, you do. We currently accept various payment methods
                    like Visa, MasterCard, dollar transfers. We are
                    constantly adding new ones over time.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    Where can I pick-up my items?
                  </Accordion.Header>
                  <Accordion.Body>
                    There are two options available for delivery. You can pick
                    your items at our various pickup centers across Nigeria or
                    opt for a door-to-door delivery.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>What is Custom Order?</Accordion.Header>
                  <Accordion.Body>
                    Custom order is a special order for stores not supported by
                    the beta-eshopping application and allows you combine items from
                    multiple stores. All you have to do is; copy the
                    product URL, product name, quantity and other necessary details regarding
                    the product of your choice from the store you are shopping
                    from and paste in the cells as indicated. Select your
                    shipping type and place your order.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    What are your shipping fees and other costs?
                  </Accordion.Header>
                  <Accordion.Body>
                    Our shipping fees vary based on an algorithm on size,
                    weight, and volumetric dimensions. Other cost includes tax,
                    duties and handling costs.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                    Why is the shipping fee different for two identical items?
                  </Accordion.Header>
                  <Accordion.Body>
                    The Shipping cost may vary if two similar items are coming from
                    different stores or location. All shipments are delivered
                    via BETA Courier.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header>
                    How do I know when my order has arrived?
                  </Accordion.Header>
                  <Accordion.Body>
                    You can track your order status via your profile. Navigate to
                    profile screen on the app, Tap track order in menu list
                    and your updated order status will be displayed. BETA
                    Courier will contact you about your delivery and let you
                    know when to expect delivery.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                  <Accordion.Header>When do I get my orders</Accordion.Header>
                  <Accordion.Body>
                    Because we work with various merchants, the time it takes
                    for an item to get to us varies. However, you can expect
                    your item(s) within 10-15 working days depending on your
                    specific merchant.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                  <Accordion.Header>
                    Do you have a return policy?
                  </Accordion.Header>
                  <Accordion.Body>
                    Yes we do, There are 2 types of returns: <br />
                    <br /> 1. You got the wrong item: If that is the case,
                    BETA-eshopping will provide you with return instructions.
                    For instruction on how to make a return please send us an
                    email at info@beta-eshopping.com.
                    <br />
                    <br />

                    2. If you got the item but have changed your mind, you would
                    be responsible for the restocking fee and the cost of shipping it back, based on the merchant’s return policy.
                    

                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9">
                  <Accordion.Header>What items do not ship?</Accordion.Header>
                  <Accordion.Body>
                    For safety and regulatory reasons, we do not ship items
                    listed below;
                    <ol>
                      <li>
                        Hazardous materials/Chemicals/Radioactive material
                      </li>
                      <li>Paint/Ink and toners</li>
                      <li>Medication/Prescription drugs</li>
                      <li>Fire Arms/weapons/gun powder etc.</li>
                      <li>Alcohol/liquid/gas</li>
                      <li>Flammable substances</li>
                      <li>Lighter folds and matches/Fire extinguishers</li>
                      <li>Live Products including food, plants, animals</li>
                      <li>Food products/Perishable goods</li>
                      <li>Human remains</li>
                      <li>Lithium-ion Batteries</li>
                      <li>Antiques/Artworks</li>
                      <li>Credit/Debit card and gift vouchers</li>
                    </ol>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="10">
                  <Accordion.Header>
                    Can I shop from different stores on the application?
                  </Accordion.Header>
                  <Accordion.Body>
                    <strong>YES!</strong> Use our Custom order option.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
            <Col lg={2}></Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default FaqPageMobile;
