import { createContext } from "react";

const OrderContext = createContext({
  cartLen: 0,
  setCartLen: () => {},
  cartCode: "",
  setCartCode: () => {},
});

export default OrderContext;
