import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Form,
  Container,
  Row,
  Col,
  Alert,
  Modal,
} from "react-bootstrap";
import { Oval } from "react-loader-spinner";
import { passwordReset } from "../../services/api";
import Header from "../../components/Header";
import styles from "./style";
import { Colors } from "../../utils/styles";

const useQuery = () => new URLSearchParams(useLocation().search);

const NewPasswordPage = () => {
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [hasError, setHasError] = useState(false);
  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  let query = useQuery();
  const token = query.get("token")?.replace(/\/$/, "");

  const validateForm = () => {
    return password.length > 0;
  };

  const handleClose = () => setShowModal(false);

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const reset_res = await passwordReset({ token, new_password: password });

    if (reset_res.status !== 200) {
      const err = reset_res.response.data;
      const errMsg = Object.keys(err).map((key) => {
        return `${err[key]}`;
      });
      setError(errMsg);
      setHasError(true);
    } else {
      setHasError(false);
      setShowModal(true);
      setMessage(reset_res.data.message);
    }
    setIsLoading(false);
  };

  return (
    <div>
      <Header hideAuth={true} />
      <Container style={styles.bodyContainer}>
        <Row style={{ justifyContent: "center" }}>
          <Col
            sm={4}
            // className="border rounded"
            style={{
              height: "fit-content",
              width: 350,
              border: `2px solid ${Colors.BES_PURPLE}`,
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              borderRadius: 10,
            }}
          >
            <p style={styles.signInHeaderText}>Set a new password</p>
            {hasError && (
              <Alert variant={"danger"} style={{ textAlign: "center" }}>
                {error}
              </Alert>
            )}
            <Modal
              show={showModal}
              onHide={handleClose}
              size="md"
              centered
              backdrop="static"
            >
              <Modal.Header>
                <Modal.Title>Successful</Modal.Title>
              </Modal.Header>
              <Modal.Body>{message}</Modal.Body>
              <Modal.Footer>
                <Button
                  style={{
                    background: "#5034FF",
                    color: "#fff",
                    border: "none",
                    margin: "0 auto",
                    width: 100,
                  }}
                  onClick={() => {
                    handleClose();
                    navigate("/signin");
                  }}
                >
                  OK
                </Button>
              </Modal.Footer>
            </Modal>

            <Form onSubmit={handleSubmit} style={styles.signInForm}>
              <Form.Group
                size="lg"
                controlId="password"
                style={styles.signInFormContainer}
              >
                <Form.Control
                  placeholder="New Password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  style={styles.formInput}
                />
              </Form.Group>
              <Button
                className="block bes-input-box-shadow"
                size="lg"
                type="submit"
                disabled={!validateForm()}
                style={styles.button}
              >
                {isLoading ? (
                  <div className="d-flex align-items-center justify-content-center">
                    <Oval
                      type="Circles"
                      color="#fff"
                      height={30}
                      width={30}
                      wrapperStyle={{ margin: "0 auto" }}
                    />
                  </div>
                ) : (
                  "Reset"
                )}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NewPasswordPage;
