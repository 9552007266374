import { Colors, Fonts } from "../../utils/styles";

const styles = {
  navbarContainer: {
    margin: 0,
    paddingTop: 15,
    paddingBottom: 15,
    background: "#F4F4F4",
  },
  brand: {
    fontWeight: "bold",
    fontSize: 24,
    paddingRight: 11,
  },
  navLink: {
    color: Colors.BES_PURPLE,
    fontWeight: Fonts.FONT_WEIGHT_MEDIUM,
    paddingRight: 20,
    fontSize: 16,
    display: "flex",
  },
  dropdownLink: {
    color: Colors.BES_PURPLE,
    fontWeight: Fonts.FONT_WEIGHT_MEDIUM,
    paddingRight: 10,
    fontSize: 14,
  },
  login: {
    marginRight: 15,
  },
  signUp: {
    color: "#181743",
    fontSize: 14,
    border: "none",
    boxSizing: "border-box",
    borderRadius: 10,
    width: 100,
  },
  moveRight: {
    float: "right",
  },
  initials: {
    display: "block",
    height: 35,
    width: 35,
    lineHeight: 1.3,
    borderRadius: 30,
    backgroundColor: "black",
    color: "white",
    textAlign: "center",
    fontSize: 15,
    marginRight: 20,
    fontWeight: 600,
  },
  notificationBadge: {
    position: "relative",
    marginRight: 5,
  },

  notificationCount: {
    position: "absolute",
    top: -1,
    right: -17,
    width: 15,
    height: 15,
    backgroundColor: Colors.BES_RED,
    color: Colors.WHITE,
    fontSize: 10,
    borderRadius: "50%",
    textAlign: "center",
  },
  navLinkColor: {
    color: Colors.BES_PURPLE,
    fontWeight: Fonts.FONT_WEIGHT_MEDIUM,
  
  },
};

export default styles;
