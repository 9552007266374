import React from "react";
import { Colors } from "../../../utils/styles";
import DebitSvg from "./Debit";
import CreditSvg from "./Credit";
import PendingSvg from "./Pending";
import moment from "moment";
import { clean_dollar_format } from "../../../utils/utilities";

const groupTransactionsByDate = (transactions) => {
  const groupedTransactions = {};
  for (const transaction of transactions) {
    const { created_at } = transaction;
    const date = moment(created_at).format("MMM Do YYYY");
    if (!groupedTransactions[date]) {
      groupedTransactions[date] = [];
    }
    groupedTransactions[date].push(transaction);
  }
  return groupedTransactions;
};

const Transaction = ({
  transaction_type,
  status,
  time,
  description,
  value,
}) => {
  const styles = {
    container: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
      backgroundColor: "#F3F2FF",
      borderRadius: 8,
      padding: 10,
      marginBottom: 10,
    },
    title: {
      fontSize: 14,
      fontWeight: "600",
      color: Colors.BES_BLACK,
    },
    mutedText: {
      color: Colors.GRAY_DARKER,
      fontSize: 12,
    },
    value: {
      color:
        status !== "pending"
          ? transaction_type === "credit"
            ? "#059D14"
            : "#F74C50"
          : Colors.GRAY_DARK,
      fontSize: 16,
    },
  };
  return (
    <div style={styles.container}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {status !== "pending" ? (
          transaction_type === "credit" ? (
            <CreditSvg />
          ) : (
            <DebitSvg />
          )
        ) : (
          <PendingSvg />
        )}
        <div style={{ paddingLeft: 10 }}>
          <p style={{ ...styles.title, marginBottom: 0 }}>{description}</p>
          <p style={{ ...styles.mutedText, marginBottom: 0 }}>{time}</p>
        </div>
      </div>
      <p style={styles.value}>{value}</p>
    </div>
  );
};

const TransactionList = ({ transactions, handleGetTransaction }) => {
  const groupedTransactions = groupTransactionsByDate(transactions);

  return (
    <div>
      {Object.keys(groupedTransactions).map((date) => (
        <div key={date}>
          <p
            style={{
              fontSize: 14,
              fontWeight: "600",
              color: Colors.GRAY_DARKER,
              marginTop: 5,
              marginBottom: 6,
            }}
          >
            {date}
          </p>
          {groupedTransactions[date].map((transaction) => (
            <div
              onClick={() => handleGetTransaction(transaction?.reference)}
              style={{ cursor: "pointer" }}
            >
              <Transaction
                transaction_type={transaction?.transaction_type}
                time={moment(transaction?.created_at).format("LT")}
                status={transaction?.status}
                description={
                  transaction?.status === "pending"
                    ? transaction?.transaction_type === "credit"
                      ? "Wallet Funded - Pending"
                      : "Wallet Debited - Pending"
                    : transaction?.transaction_type === "credit"
                    ? "Wallet Funded"
                    : "Wallet Debited"
                }
                value={clean_dollar_format(transaction?.value)}
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default TransactionList;
