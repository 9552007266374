import React, {useState} from 'react';
import {
    Container,
    Row,
    Col,
} from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import emailjs from "emailjs-com";

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import styles from './style';
import './styles.css';

const ContactUsPage = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  
  const [disabled, setDisabled] = useState(false);

  // Function that displays a success toast on bottom right of the page when form submission is successful
  const toastifySuccess = () => {
    toast("Your message has been sent successfully!", {
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: "submit-feedback success",
      toastId: "notifyToast",
    });
  };

  // Function called on submit that uses emailjs to send email of valid contact form
  const onSubmit = async (data) => {
    // Destructure data object
    const { name, email, subject, message } = data;
    try {
      // Disable form while processing submission
      setDisabled(true);

      // Define template params
      const templateParams = {
        name,
        email,
        subject,
        message,
      };

      // Use emailjs to email contact form data
      await emailjs.send(
        "service_ltbmo99",
        "template_b2548rb",
        templateParams,
        "user_vmgCB6ttOeNXKnaJIw3h5"
      );

      // Reset contact form fields after submission
      reset();
      // Display success toast
      toastifySuccess();
      // Re-enable form submission
      setDisabled(false);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div>
      <Header hideAuth={true} />
      <Container
        style={styles.bodyContainer}
        className="contact-form-container"
      >
        <Row style={{ justifyContent: "center", marginBottom: "50px" }}>
          <Col lg={6}>
            <div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3963.845972468333!2d3.345290514266632!3d6.541126524774131!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8ddc99941df5%3A0x3f3145853fe05fac!2sEmmanuel%20Plaza!5e0!3m2!1sen!2sng!4v1644330753318!5m2!1sen!2sng"
                width="100%"
                height="450"
                allowFullScreen=""
                loading="lazy"
                title="office-location"
                className="location-map"
              ></iframe>{" "}
            </div>
          </Col>
          <Col
            lg={6}
            style={{ textAlign: "center" }}
            className="contact-form-right-column"
          >
            <h2 className="text-center">Contact us</h2>
            <p className="text-center">
              Kindly fill the form below or call{" "}
              <a href="tel:+234-1-714-0067">+234-1-714-0067</a> to speak to an
              agent.
            </p>
            <div className="ContactForm">
              <div className="container">
                <div className="row">
                  <div className="col-12 text-center">
                    <div className="contactForm">
                      <form
                        id="contact-form"
                        onSubmit={handleSubmit(onSubmit)}
                        noValidate
                      >
                        {/* Row 1 of form */}
                        <div className="row formRow">
                          <div className="col-md-6 col-xs-12">
                            <input
                              type="text"
                              name="name"
                              {...register("name", {
                                required: {
                                  value: true,
                                  message: "Please enter your name",
                                },
                                maxLength: {
                                  value: 30,
                                  message: "Please use 30 characters or less",
                                },
                              })}
                              className="form-control formInput"
                              placeholder="Name"
                            ></input>
                            {errors.name && (
                              <span className="errorMessage">
                                {errors.name.message}
                              </span>
                            )}
                          </div>
                          <div className="col-md-6 col-xs-12">
                            <input
                              type="email"
                              name="email"
                              {...register("email", {
                                required: true,
                                pattern:
                                  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                              })}
                              className="form-control formInput"
                              placeholder="Email address"
                            ></input>
                            {errors.email && (
                              <span className="errorMessage">
                                Please enter a valid email address
                              </span>
                            )}
                          </div>
                        </div>
                        {/* Row 2 of form */}
                        <div className="row formRow">
                          <div className="col">
                            <input
                              type="text"
                              name="subject"
                              {...register("subject", {
                                required: {
                                  value: true,
                                  message: "Please enter a subject",
                                },
                                maxLength: {
                                  value: 75,
                                  message:
                                    "Subject cannot exceed 75 characters",
                                },
                              })}
                              className="form-control formInput"
                              placeholder="Subject"
                            ></input>
                            {errors.subject && (
                              <span className="errorMessage">
                                {errors.subject.message}
                              </span>
                            )}
                          </div>
                        </div>
                        {/* Row 3 of form */}
                        <div className="row formRow">
                          <div className="col">
                            <textarea
                              rows={3}
                              name="message"
                              {...register("message", {
                                required: true,
                              })}
                              className="form-control formInput"
                              placeholder="Message"
                            ></textarea>
                            {errors.message && (
                              <span className="errorMessage">
                                Please enter a message
                              </span>
                            )}
                          </div>
                        </div>

                        <button
                          className="submit-btn"
                          disabled={disabled}
                          type="submit"
                        >
                          Submit
                        </button>
                      </form>
                    </div>
                    <ToastContainer />
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default ContactUsPage;