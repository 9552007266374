import React from "react";
import "./style.scss";
import { useLocation } from "react-router-dom";


const VerifyRedirect = () => {
  const location = useLocation();
  let email = location.state.userEmail;

  return (
    <div className="verify-redirect-header">
      <h1>🎉 Almost there!</h1>
      <p>
        We've sent you an email at <br /> <b>{email || "dd"}</b>{" "}
      </p>
      <p>Please follow the instructions in the email.</p>
      <div className="btn">VERIFICATION EMAIL SENT</div>
    </div>
  );
};

export default VerifyRedirect;
