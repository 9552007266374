import React, { useEffect, useContext } from 'react';
import { useLocation, useNavigate} from 'react-router-dom';
import queryString from 'query-string';
import { getUserGoggleLoginResponse } from "../../services/api";
import AuthContext from "../../context/authContext";
import { saveToStore } from "../../utils/storage-helpers";
import Loading from "../../components/Loading";


const Google = (props) => {
  let location = useLocation();
  const navigate = useNavigate();
  const { path } = props;
  const prevPage = path ? path : "/";

  const { setAuth, isAuthenticated } = useContext(AuthContext);
 

  const onGogglelogin = async () => {
    const response = await getUserGoggleLoginResponse(location.search);
    await saveToStore("access_token", response.data.access_token);
    await saveToStore("user", response.data.user);
    setAuth(true);
    
    const phone_no = response.data.user.phone_no
    if (phone_no) {
      navigate(prevPage);
    }
    else{
      // for a new user 
      navigate("/complete-registration/");
    }
  }

  useEffect(() => {
    const values = queryString.parse(location.search);
    const code = values.code ? values.code : null;

    if (code) {
      onGogglelogin()
    }
  }, []);


  return (
    <Loading/>
  );
};

export default Google;
