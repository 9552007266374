import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { Container, Row, Col, Modal, Button} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

import { verifyAccount, resendVerificationEmail } from "../../services/api";
import Header from "../../components/Header";

import { Colors } from "../../utils/styles";
import { ROUNDED_BUTTON } from "../../utils/styles/buttons";
import { Oval } from "react-loader-spinner";

import styles from "./style";

const useQuery = () => new URLSearchParams(useLocation().search);

const VerifyAccountPage = (props) => {
  const [isVerified, setIsVerified] = useState(false);
  const [error, setError] = useState("");
  const [hasError, setHasError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [modalShow, setModalShow] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();


  let query = useQuery();
  const token = query.get("token")?.replace(/\/$/, "");
  const userId = query.get("user")?.replace(/\/$/, "");

  const accountVerification = useCallback(async () => {
    const accountVerifyRes = await verifyAccount(userId, { token });

    if (accountVerifyRes.status !== 200) {
      setError(accountVerifyRes.response.data.detail);
      setIsVerified(false);
    } else {
      setIsVerified(true);
    }
  }, [token, userId]);

  const resendVerificationEmailToken = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    const resendVerificationEmailRes = await resendVerificationEmail({userId});
    if (resendVerificationEmailRes.status !== 200) {
      const err = resendVerificationEmailRes.response.data;
      const errMssg = Object.keys(err).map((key) => {
        return `${''}${err[key]}`;
      });
      setError(errMssg);
      setHasError(true);
      setSuccess(false);
      setModalShow(true);

    } else {
      setHasError(false);
      setSuccess(true);
      setModalShow(true)
    }
    setIsLoading(false)
  };

  useEffect(() => {
    accountVerification();
  }, [accountVerification]);

  return (
    <div>
      {hasError && (
        <Modal
          {...props}
          size="md"
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={modalShow}
          onHide={() => setModalShow(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Invalid Credentials
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{error}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="md"
              style={{
                ...ROUNDED_BUTTON,
                width: 100,
                height: 40,
                margin: "0 auto",
              }}
              onClick={() => {
                setModalShow(false);
                setSuccess(false);
                navigate("/register");
              }}
            >
              Got it!
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {success && (
        // insert success modal
        <Modal
          {...props}
          size="md"
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={modalShow}
          onHide={() => setModalShow(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Successful!
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Please check your email to activate your account.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="md"
              style={{
                ...ROUNDED_BUTTON,
                width: 100,
                height: 40,
                margin: "0 auto",
              }}
              onClick={() => {
                setSuccess(false);
                navigate("/signin");
              }}
            >
              Got it!
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <Header bg={"white"} varaint={"light"} hideAuth={true} />
      <Container style={styles.bodyContainer}>
        <Row style={{ justifyContent: "center" }}>
          <Col lg={6} style={{ textAlign: "center" }}>
            {isVerified ? (
              <div>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  size="6x"
                  color={Colors.BES_PURPLE}
                  className="mb-5"
                />
                <h3>Account Verification Successful!</h3>
                <span>
                  You can now
                  <a
                    href="/signin"
                    style={{
                      color: Colors.BES_PURPLE,
                      textDecoration: "none",
                      fontWeight: 600,
                    }}
                  >
                    {" "}
                    LOGIN
                  </a>{" "}
                  to start shopping
                </span>
              </div>
            ) : (
              <div>
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  size="6x"
                  color={Colors.ALERT}
                  className="mb-5"
                />
                <h3>Account Verification Failed</h3>
                <p>Click the button below to get a new verification email.</p>
                <button
                  onClick={resendVerificationEmailToken}
                  style={{
                    ...ROUNDED_BUTTON,
                    width: 250,
                    height: 50,
                    margin: "0 auto",
                    color: "#fff",
                    fontSize: 17,
                    fontWeight: 600,
                  }}
                >
                  {isLoading ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <Oval
                        type="Circles"
                        color="#fff"
                        height={25}
                        width={25}
                        wrapperStyle={{ marginLeft: 15 }}
                      />
                    </div>
                  ) : (
                    "Get Verification Link"
                  )}
                </button>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default VerifyAccountPage;
