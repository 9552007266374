import React from "react";
import TermsAndConditions from "../../components/TermsAndConditions";

const TCPageMobile = () => {
  return (
    <div className="container mt-4 px-3 text-justify">
      <TermsAndConditions />
    </div>
  );
};

export default TCPageMobile;
