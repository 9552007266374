import React, { useState, useEffect, useContext } from "react";
import { Colors } from "../../utils/styles";
import CardDebitForm from "./components/Form";
import {
  getCurrentRate,
  getWalletBalance,
  walletTransaction,
} from "../../services/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import SideBar from "../../components/SideBar";
import Header from "../../components/Header";
import {
  clean_dollar_format,
  addCommas,
  removeNonNumeric,
} from "../../utils/utilities";
import AuthContext from "../../context/authContext";
import { closePaymentModal, useFlutterwave } from "flutterwave-react-v3";
import Loading from "../../components/Loading";
import { Alert } from "react-bootstrap";

export const paymentStatusMapping = {
  successful: "successful",
  failed: "failed",
  pending: "pending",
};

const WalletInfoHeader = ({ balance }) => {
  return (
    <div style={styles.headerContainer}>
      <div>
        <p
          style={{
            color: Colors.BES_BLACK,
            fontSize: 14,
            marginBottom: 0,
          }}
        >
          Debit Card
        </p>
        <FontAwesomeIcon
          icon={faCreditCard}
          size="md"
          color={Colors.GRAY_DARK}
        />
      </div>
      <div style={styles.iconContainer}>
        <FontAwesomeIcon
          icon={faArrowRight}
          size="md"
          color={Colors.BES_BLACK}
        />
      </div>
      <div>
        <p
          style={{
            color: Colors.BES_BLACK,
            fontSize: 14,
            marginBottom: 0,
          }}
        >
          Beta Wallet
        </p>
        <p
          style={{
            color: Colors.GRAY_DARK,
            textAlign: "right",
            marginBottom: 0,
          }}
        >
          {balance}
        </p>
      </div>
    </div>
  );
};

const DebitCardPage = () => {
  const location = useLocation();
  const fundingType = location.state;
  const [debitAmount, setDebitAmount] = useState("");
  const [dollarToNairaConverted, setDollarToNariaConverted] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [nairaDollarRate, setNairaDollarRate] = useState(null);
  const [walletBalance, setWalletBalance] = useState(0);
  const [paymentVerificationLoading, setPaymentVerificationLoading] =
    useState(false);
  const [error, setError] = useState("");
  const [paymentTransactionLoading, setPaymentTransactionLoading] =
    useState(false);

  const { user } = useContext(AuthContext);

  useEffect(() => {
    fetchWalletBalance();
    getExchangeRates();
  }, []);

  const getExchangeRates = async () => {
    const rate_res = await getCurrentRate();
    if (rate_res.status === 200) {
      const rates = rate_res.data;
      setNairaDollarRate(rates.dollar_rate);
    }
  };

  const fetchWalletBalance = async () => {
    const transactions_res = await getWalletBalance();
    if (transactions_res?.status === 200) {
      setWalletBalance(transactions_res?.data.balance);
    }
  };

  const toggleExchangeRatesModal = () => {
    setShowModal(!showModal);
  };

  const handleInputChange = (val) => {
    const inputValue = val;
    const numericValue = removeNonNumeric(inputValue);

    // Ensure that the input value has at most two decimal places
    const parts = numericValue.split(".");
    if (parts.length === 2) {
      const decimalPart = parts[1].slice(0, 2);
      setDebitAmount(addCommas(`${parts[0]}.${decimalPart}`));
    } else {
      setDebitAmount(addCommas(numericValue));
    }
    setDollarToNariaConverted(
      (removeNonNumeric(val) / nairaDollarRate).toFixed(2)
    );
  };

  const transactionLimitErrorMsg = {
    naira: "Transaction Limit is ₦3,000,000",
    dollar: "Transaction Limit is $1,000",
  };

  const handleTransactionLimitErrorMsg = () => {
    const debitVal = removeNonNumeric(debitAmount);
    if (fundingType === "Naira" && debitVal > 3000000) {
      return transactionLimitErrorMsg.naira;
    }
    if (fundingType === "Dollar" && debitVal > 1000) {
      return transactionLimitErrorMsg.dollar;
    }
    return "";
  };

  // populate flutterwave config here based on funding type
  const fw_config = {
    tx_ref: `BESW-${Date.now()}`,
    public_key: process.env.REACT_APP_FLUTTER_PUBKEY,
    customer: {
      email: user?.email ? user?.email : "",
      phonenumber: user?.phone_no,
      name: `${user?.first_name} ${user?.last_name}`,
    },
    amount: Number(removeNonNumeric(debitAmount)),
    currency: fundingType === "Naira" ? "NGN" : "USD",
    payment_options: "card,mobilemoney,ussd",
    customizations: {
      title: "Beta-eshopping",
      description: `Payment for Wallet Top: ${user?.first_name} ${user?.last_name}`,
      logo: "https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg",
    },
  };

  const handleFlutterPayment = useFlutterwave(fw_config);

  const handleMakePayment = () => {
    setPaymentTransactionLoading(true);
    handleFlutterPayment({
      callback: (response) => {
        saveTransaction(response);
        closePaymentModal();
        setPaymentTransactionLoading(false);
      },
    });
  };

  const navigate = useNavigate();

  const saveTransaction = async (transaction) => {
    // console.log("here is the transaction");
    setPaymentVerificationLoading(true);
    const transactionData = {};
    transactionData.dollar_value =
      fundingType === "Naira"
        ? Number(dollarToNairaConverted)
        : Number(removeNonNumeric(debitAmount));
    transactionData.transaction_ref = transaction.tx_ref;

    const transactionStatus = await walletTransaction(transactionData);

    if (transactionStatus.status !== 201) {
      const err = transactionStatus?.response?.data;
      const errMsg = Object.keys(err).map((key) => {
        return `${[key]}: ${err[key]}`;
      });
      setError(errMsg);
    } else {
      const status = transactionStatus?.data?.status;
      navigate(`/payment?status=${paymentStatusMapping[status]}&type=wallet`);
    }
    setPaymentVerificationLoading(false);
  };

  return (
    <div>
      {paymentVerificationLoading ? (
        <Loading text="Processing Payment..." />
      ) : null}
      {paymentTransactionLoading && <Loading text="Initializing Payment..." />}
      {error && (
        <Alert
          variant="danger"
          style={{ width: "70%", margin: "0 auto", textAlign: "center" }}
          onClose={() => setError("")}
          dismissible
        >
          {error}
        </Alert>
      )}
      <Header hideAuth={true} />
      <div className="custom_container custom_container--pall">
        <section style={{ height: "90vh", marginTop: 20 }}>
          <div className="custom-row">
            <div
              style={{
                padding: "10px 0 0 0",
              }}
              className="profile-sidebar first"
            >
              <SideBar path="/wallet" />
            </div>
            <div
              className="second px-4"
              // style={{
              //   boxShadow: "none",
              // }}
            >
              <div style={{ padding: 10, marginTop: 10 }}>
                {/* Header */}
                <WalletInfoHeader
                  balance={clean_dollar_format(walletBalance)}
                />
                {/* Header Ends */}

                {/* Debit Card Form */}
                <CardDebitForm
                  fundingType={fundingType}
                  value={debitAmount}
                  showExchangeRates={() => toggleExchangeRatesModal()}
                  currentExchangeRate={nairaDollarRate}
                  onChangeText={(e) => {
                    handleInputChange(e.target.value);
                  }}
                  convertedVal={clean_dollar_format(dollarToNairaConverted)}
                  errMsg={handleTransactionLimitErrorMsg()}
                  handlePayment={() => handleMakePayment()}
                />
                {/* Debit Card Form End */}
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default DebitCardPage;

const styles = {
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#EBF1F3",
    alignItems: "center",
    padding: "10px 12px 10px 12px",
    borderRadius: 10,
    elevation: 1,
    shadowColor: Colors.GRAY_DARK,
    shadowOffset: { width: 0.5, height: 0.5 },
    shadowOpacity: 0.2,
    marginBottom: 20,
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 30,
    height: 30,
    backgroundColor: "#C7DFE8",
    borderRadius: 50,
  },
};

// <ExchangeRatesModal
//   showExchangeRateModal={showModal}
//   toggleModal={toggleExchangeRatesModal}
//   title="About Exchange Rates"
//   nairaDollarRate="$750"
// />
