import React, { useContext, useEffect, useState } from "react";
import { Button, Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faShoppingBag,
  faSignOutAlt,
  faDollarSign,
  faCartPlus,
  faBoxOpen,
  faComment,
  faCog,
  faUserCircle,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/authContext";
import { getFromStore } from "../../utils/storage-helpers";
import logo from "./assets/logo.png";
import { removeFromStore } from "../../utils/storage-helpers";
import ImageContext from "../../context/imageContext";
import OrderContext from "../../context/orderContext";
import { ReactComponent as UserIconSVG } from "./assets/user-icon.svg";
import ModalContext from "../../context/modalContext";

import styles from "./style";
import "./style.scss";

const Header = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const { bannerImage } = useContext(ImageContext);
  const { cartLen, setCartLen } = useContext(OrderContext);
  const [scroll, setScroll] = useState(0);
  const [username, setUsername] = useState("");
  const { setAuth } = useContext(AuthContext);
  const { setShowCustomModal } = useContext(ModalContext);

  const navigate = useNavigate();
  const handleModalShow = () => setShowCustomModal(true);

  useEffect(() => {
    if (isAuthenticated) {
      getInitials();
    } else {
      setCartLen(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    let progressBarHandler = () => {
      const totalScroll = document.documentElement.scrollTop;
      const windowHeight =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const scroll = `${totalScroll / windowHeight}`;

      setScroll(scroll);
    };

    window.addEventListener("scroll", progressBarHandler);

    return () => window.removeEventListener("scroll", progressBarHandler);
  }, []);

  const handleLogout = () => {
    let itemId = "/";
    removeFromStore("", true);
    setAuth(false);
    navigate(itemId);
  };

  const getInitials = async () => {
    const currentUser = await getFromStore("user");
    const { first_name } = currentUser;
    setUsername(first_name);
  };

  return (
    <React.Fragment>
      {Object.values(bannerImage).every((val) => val !== "") ? (
        <a href={bannerImage.external_link} target="_blank" rel="noreferrer">
          <img
            src={bannerImage.image_url}
            alt={bannerImage.title}
            style={{ width: "100%", zIndex: 9999 }}
            className="bannerv"
            loading="lazy"
          />
        </a>
      ) : null}

      <Navbar
        collapseOnSelect
        expand="lg"
        style={styles.navbarContainer}
        sticky="top"
      >
        <Container>
          <Navbar.Brand style={styles.brand}>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />

            <Link to="/">
              {" "}
              <img
                src={logo}
                alt="beta-eshopping logo"
                style={{ width: 220 }}
                className="logo"
              />
            </Link>
          </Navbar.Brand>
          <Link to="/profile" className="link user-svg-icon">
            <UserIconSVG />
          </Link>

          <Navbar.Collapse
            id="responsive-navbar-nav"
            className={isAuthenticated && "justify-content-end"}
          >
            <Nav className="me-auto">
              <Nav.Link style={styles.navLink}>
                <FontAwesomeIcon
                  className="hide-for-desktop"
                  styles={{ ...styles.navLinkColor }}
                  icon={faCartPlus}
                />
                <Link to="/load-cart" className="link">
                  Cart{" "}
                  {cartLen > 0 ? (
                    <span style={styles.notificationBadge}>
                      <span style={styles.notificationCount}>{cartLen}</span>
                    </span>
                  ) : null}
                </Link>
              </Nav.Link>
              <Nav.Link style={styles.navLink}>
                <FontAwesomeIcon
                  className="hide-for-desktop"
                  styles={{ ...styles.navLinkColor }}
                  icon={faBoxOpen}
                />
                <Link to="/custom-order" className="link">
                  Custom Order
                </Link>
              </Nav.Link>

              <Nav.Link style={styles.navLink}>
                <FontAwesomeIcon
                  className="hide-for-desktop"
                  styles={{ ...styles.navLinkColor }}
                  icon={faComment}
                />
                <Link to="/faq" className="link">
                  FAQs
                </Link>
              </Nav.Link>

              <Nav.Link style={styles.navLink}>
                <FontAwesomeIcon
                  className="hide-for-desktop"
                  styles={{ ...styles.navLinkColor }}
                  icon={faCog}
                />
                <div onClick={handleModalShow} className="link hide-for-mobile">
                  How it Works
                </div>
                <Link to="/how-it-works" className="link hide-for-desktop">
                  How it Works
                </Link>
              </Nav.Link>

              {isAuthenticated ? (
                <div className="hide-for-desktop nav-links-div">
                  <Nav.Link style={styles.navLink}>
                    <FontAwesomeIcon
                      icon={faDollarSign}
                      styles={{ ...styles.navLinkColor }}
                    />
                    <Link to="/current-rate" className="link">
                      Exchange Rates
                    </Link>
                  </Nav.Link>

                  <Nav.Link style={styles.navLink}>
                    <FontAwesomeIcon
                      styles={{ ...styles.navLinkColor }}
                      icon={faWallet}
                    />
                    <Link to="/wallet" className="link">
                     Wallet
                    </Link>
                  </Nav.Link>

                  <Nav.Link style={styles.navLink}>
                    <FontAwesomeIcon
                      styles={{ ...styles.navLinkColor }}
                      icon={faShoppingBag}
                    />
                    <Link to="/history" className="link">
                      Orders
                    </Link>
                  </Nav.Link>
                  <Nav.Link onClick={handleLogout} style={styles.navLink}>
                    <FontAwesomeIcon
                      styles={{ ...styles.navLinkColor }}
                      icon={faSignOutAlt}
                    />
                    <Link to="#" onClick={handleLogout} className="link">
                      Logout
                    </Link>
                  </Nav.Link>
                </div>
              ) : (
                <div className="hide-for-desktop nav-links-div">
                  <div
                    className="mobile-actions mobile-actions-login 
                 hide-for-desktop"
                  >
                    <Link to="/signin">LOGIN</Link>
                  </div>

                  <div className="mobile-actions mobile-actions-signup hide-for-desktop">
                    <Link to="/register">SIGN UP</Link>
                  </div>
                </div>
              )}
            </Nav>

            {!isAuthenticated ? (
              <Nav className="hide-for-mobile">
                <Nav.Link
                  href="/signin"
                  style={{ ...styles.navLink, ...styles.login }}
                >
                  <Link to="/signin" className="link">
                    Login
                  </Link>
                </Nav.Link>
                <Link to="/register">
                  <Button
                    variant="outline"
                    style={styles.signUp}
                    className="signup-btn"
                  >
                    Sign Up
                  </Button>
                </Link>
              </Nav>
            ) : (
              <Nav>
                <div
                  className="hide-for-mobile user-icon d-flex justify-content-between align-items-center"
                  style={{ marginRight: "41px" }}
                >
                  <FontAwesomeIcon
                    className="user-icon-fa"
                    styles={{ ...styles.navLink }}
                    icon={faUser}
                  />
                  <NavDropdown
                    id="nav-dropdown-light-example"
                    title={"Hi " + username}
                    className=""
                    menuVariant="primary"
                    style={{ ...styles.navLink, marginRight: 4 }}
                  >
                    <NavDropdown.Item
                      className="dropdown-link"
                      href="/profile"
                      style={{ ...styles.dropdownLink }}
                    >
                      <FontAwesomeIcon
                        styles={{ ...styles.navLink }}
                        icon={faUserCircle}
                      />{" "}
                      Profile
                    </NavDropdown.Item>

                    <NavDropdown.Item
                      className="dropdown-link"
                      href="/wallet"
                      style={{ ...styles.dropdownLink }}
                    >
                      <FontAwesomeIcon
                        styles={{ ...styles.navLink }}
                        icon={faWallet}
                      />{" "}
                      Wallet
                    </NavDropdown.Item>

                    <NavDropdown.Item
                      className="dropdown-link"
                      href="/history"
                      style={{ ...styles.dropdownLink }}
                    >
                      <FontAwesomeIcon
                        styles={{ ...styles.navLink }}
                        icon={faShoppingBag}
                      />{" "}
                      Orders
                    </NavDropdown.Item>

                    <NavDropdown.Item
                      className="dropdown-link "
                      onClick={handleLogout}
                      style={{ ...styles.dropdownLink, paddingRight: "51px" }}
                    >
                      <FontAwesomeIcon
                        styles={{ ...styles.navLink, marginRight: "1rem" }}
                        icon={faSignOutAlt}
                      />
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                </div>
              </Nav>
            )}
          </Navbar.Collapse>
        </Container>
        <div id="progressBarContainer">
          <div
            id="progressBar"
            style={{ transform: `scale(${scroll}, 1)`, opacity: `${scroll}` }}
          />
        </div>
      </Navbar>
    </React.Fragment>
  );
};

export default Header;
