import { Colors } from "../../utils/styles";

const styles = {
  footerSectionHeaderText: {
    color: Colors.BES_PURPLE,
    fontSize: 16,
    fontWeight: 600,
  },
  footerLinkText: {
    color: "#161616",
    fontSize: 14,
  },
  links: {
    textDecoration: "none",
    color: "#333",
  },

};

export default styles;
