import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faDollarSign,
  faSignOutAlt,
  faCartPlus,
  faWallet,
  faGift,
} from "@fortawesome/free-solid-svg-icons";
import { Navigation } from "react-minimal-side-navigation";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";

import AuthContext from "../../context/authContext";
import { removeFromStore } from "../../utils/storage-helpers";
import { Colors } from "../../utils/styles";
import "./style.css";

const SideBar = (props) => {
  const { setAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const { path } = props;

  return (
    <div>
      <Navigation
        activeItemId={path}
        onSelect={({ itemId }) => {
          if (itemId === "/signout") {
            itemId = "/";
            removeFromStore("", true);
            setAuth(false);
          }
          navigate(itemId);
        }}
        items={[
          {
            title: "Profile",
            itemId: "/profile",
            elemBefore: () => (
              <FontAwesomeIcon
                icon={faUser}
                color={path === "/profile" ? Colors.BES_PURPLE : "#CBCBCB"}
              />
            ),
          },
          {
            title: "Orders",
            itemId: "/history",
            elemBefore: () => (
              <FontAwesomeIcon
                icon={faCartPlus}
                color={path === "/history" ? Colors.BES_PURPLE : "#CBCBCB"}
              />
            ),
          },
          {
            title: "Wallet",
            itemId: "/wallet",
            elemBefore: () => (
              <FontAwesomeIcon
                icon={faWallet}
                color={path === "/wallet" ? Colors.BES_PURPLE : "#CBCBCB"}
              />
            ),
          },
          {
            title: "Exchange Rates",
            itemId: "/current-rate",
            elemBefore: () => (
              <FontAwesomeIcon
                icon={faDollarSign}
                color={path === "/current-rate" ? Colors.BES_PURPLE : "#CBCBCB"}
              />
            ),
          },
          {
            title: "Affilate & Referrals",
            itemId: "/refer-a-friend",
            elemBefore: () => (
              <FontAwesomeIcon
                icon={faGift}
                color={path === "/refer-a-friend" ? Colors.BES_PURPLE : "#CBCBCB"}
              />
            ),
          },
          {
            title: "Logout",
            itemId: "/signout",
            elemBefore: () => (
              <FontAwesomeIcon icon={faSignOutAlt} color={Colors.BES_RED} />
            ),
          },
        ]}
      />
    </div>
  );
};

export default SideBar;
