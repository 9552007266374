// import React from 'react';
import { Image } from "react-bootstrap";

import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

// import styles from './style';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#433B80",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const OrderItem = (props) => {
  const { order } = props;

  const itemStatusMapping = {
    null: order?.status === null ? "Awaiting confirmation" : 'Opened',
    1: "Item packaged",
    2: "Item shipped",
    3: "Out for delivery",
    4: "Ready for pickup",
    5: "Item delivered",
    6: "Refunded",
    7: "Cancelled",
    8: "Awaiting merchant",
    9: "Processed",
    10: "Undergoing Custom Clearance"
  };

  return (
    <div className="mb-5" style={{ textAlign: "center", height: "100%" }}>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 700 }}
          aria-label="customized table"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>Product</StyledTableCell>
              <StyledTableCell align="right">Price</StyledTableCell>
              <StyledTableCell align="right">Quantity</StyledTableCell>
              <StyledTableCell align="right">Size</StyledTableCell>
              <StyledTableCell align="right">Status</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {order?.items?.map(
              (item, idx) => (
                console.log(itemStatusMapping[item.status]),
                (
                  <StyledTableRow key={idx}>
                    <StyledTableCell component="th" scope="row">
                      <Image
                        src={item.image_url}
                        style={{
                          width: 90,
                          height: 90,
                          border: "none",
                        }}
                      />
                      <p
                        style={{
                          margin: "5px 0 0 0px",
                          color: "#262626",
                          fontSize: 12,
                        }}
                      >
                        <a href={item.url} target="_blank" rel="noreferrer">
                          {item.name}
                        </a>
                      </p>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {item.unit_price === null ? "-" : "$" + item.unit_price}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {item.quantity}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {" "}
                      {item.size === null ? "-" : item.size}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {" "}
                      {itemStatusMapping[item.status]}
                    </StyledTableCell>
                  </StyledTableRow>
                )
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default OrderItem;
