import React from "react";
import { Button, Modal } from "react-bootstrap";
import styles from "./style";

const ActionConfirmationModal = (props) => {
  const {
    mainContainerStyle,
    imageIcon,
    imageStyle,
    headerText,
    headerTextStyle,
    bodyText,
    bodyTextStyle,
    actionButtonStyle,
    visible,
    onClose,
    actionButtonTitle,
    onActionButton,
    title,
    size,
  } = props;

  return (
    <Modal
      className="modal-content-z"
      show={visible}
      size={size}
      onHide={onClose}
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <h5 className="fw-bold">{title}</h5>
      </Modal.Header>
      <Modal.Body>
        <div style={{ ...styles.mainContainer, mainContainerStyle }}>
          {imageIcon && (
            <div style={styles.headerContainer}>
              <img
                src={imageIcon}
                style={{ ...styles.image, imageStyle }}
                alt="dialog-logo"
              />
              <p style={{ ...styles.header, headerTextStyle }}>{headerText}</p>
            </div>
          )}
          <div style={styles.bodyContainer}>
            {bodyText && (
              <p style={{ ...styles.body, bodyTextStyle }}>{bodyText}</p>
            )}
          </div>
        </div>
        <Button
          onClick={onActionButton}
          style={{ ...styles.button, actionButtonStyle }}
        >
          {actionButtonTitle}
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default ActionConfirmationModal;
