import * as React from "react"
const SvgShopMobile = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={82}
    height={83}
    fill="none"
    {...props}
  >
    <circle cx={40.962} cy={41.428} r={40.962} fill="#FED6D6" />
    <path
      fill="#3F3B7F"
      d="m25.345 23.8 2.559.433 1.507 40.722-5.206-2.07 1.14-39.086ZM28.892 24.046l1.5 40.974 29.655-3.078-.978-38.645-8.566.127.112 1.3c.554.276.927.805.98 1.42.084.972-.667 1.828-1.673 1.91-1.007.08-1.894-.644-1.978-1.616a1.729 1.729 0 0 1 .722-1.556l-.123-1.429-1.476.023.008-3.666c.004-2.123-1.246-3.967-3.072-4.889 2.356-.169 4.425 1.53 4.622 3.807l.554 6.402a1.287 1.287 0 0 0-.748 1.269c.062.713.711 1.243 1.45 1.184.74-.06 1.288-.687 1.227-1.4a1.307 1.307 0 0 0-.955-1.132L49.6 18.65c-.242-2.807-2.807-4.901-5.716-4.667l-.572.046a5.504 5.504 0 0 0-1.376.295 5.738 5.738 0 0 0-.549-.027l-.618-.002A5.765 5.765 0 0 0 36.7 15.93a5.364 5.364 0 0 0-1.645 3.944l.059 4.01-6.222.16v.002Zm9.719 1.586-.19-1.833-2.255.059-.058-3.997a4.371 4.371 0 0 1 1.34-3.216 4.67 4.67 0 0 1 2.74-1.297 4.953 4.953 0 0 0-1.705 4.28l.647 6.276c-.547.2-.916.724-.865 1.315.062.713.711 1.243 1.45 1.183.74-.06 1.289-.686 1.227-1.4a1.297 1.297 0 0 0-.828-1.084l-.658-6.384a4.036 4.036 0 0 1 .977-3.078c.414-.481.926-.853 1.496-1.11 2.307.267 4.1 2.166 4.094 4.462l-.008 3.684-5.652.084.21 2.042c.487.292.806.786.855 1.346.084.972-.666 1.828-1.673 1.909-1.007.08-1.894-.643-1.978-1.615a1.753 1.753 0 0 1 .833-1.625v-.001Z"
    />
    <path
      fill="#EEF"
      d="M58.233 67.431c5.425 0 9.823-4.362 9.823-9.743s-4.398-9.744-9.823-9.744c-5.425 0-9.823 4.363-9.823 9.744 0 5.381 4.398 9.743 9.823 9.743Z"
    />
    <path
      fill="#EE4347"
      d="M59.858 57.925c.354.749.685 1.416.982 2.097.108.249.196.533.186.798-.02.484-.411.85-.901.938-.465.083-.93-.14-1.165-.603-.295-.581-.558-1.179-.838-1.768-.077-.163-.167-.32-.275-.524-.254.256-.476.478-.7.7-.352.35-.765.464-1.238.283-.457-.175-.68-.55-.664-1.118.048-1.75.104-3.5.147-5.249.012-.486.17-.875.631-1.095.465-.222.876-.089 1.255.211 1.399 1.101 2.797 2.202 4.199 3.301.393.308.569.69.417 1.177-.152.481-.504.723-1.001.77-.318.031-.635.053-1.035.085v-.003Z"
    />
  </svg>
)
export default SvgShopMobile
