import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Container, Row, Col, Modal } from "react-bootstrap";
import { Oval } from "react-loader-spinner";
import { verifyOTPRequest } from "../../services/api";
import Header from "../../components/Header";
import styles from "./style";
import { Colors } from "../../utils/styles";
import OtpInput from "react-otp-input";
import { Link } from "react-router-dom";

const OTPpage = () => {
  const [otp, setOTP] = useState("");
  const [error, setError] = useState("");
  const [hasError, setHasError] = useState(false);
  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const validateForm = () => {
    return otp.length > 5;
  };

  const handleClose = () => setShowModal(false);

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const reset_res = await verifyOTPRequest({ otp });

    if (reset_res.status !== 200) {
      const err = reset_res.response.data;
      const errMsg = Object.keys(err).map((key) => {
        return `${err[key]}`;
      });
      setError(errMsg);
      setHasError(true);
    } else {
      setHasError(false);
      setShowModal(true);
      setMessage(reset_res.data.detail);
    }
    setIsLoading(false);
  };

  return (
    <div>
      <Header hideAuth={true} />
      <Container style={styles.bodyContainer}>
        <Row style={{ justifyContent: "center" }}>
          <Col
            sm={4}
            style={{
              height: "fit-content",
              width: "fit-content",
              border: `2px solid ${Colors.BES_PURPLE}`,
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              borderRadius: 10,
            }}
          >
            <p style={styles.signInHeaderText}>Verify OTP</p>
            <div className="text-center">
              Please enter your OTP sent to your email.
            </div>

            <Modal
              show={showModal}
              onHide={handleClose}
              size="md"
              centered
              backdrop="static"
            >
              <Modal.Header>
                <Modal.Title>OTP Verified Successfully</Modal.Title>
              </Modal.Header>
              <Modal.Body>{message}</Modal.Body>
              <Modal.Footer>
                <Button
                  style={{
                    background: "#5034FF",
                    color: "#fff",
                    border: "none",
                    margin: "0 auto",
                    width: 100,
                  }}
                  onClick={() => {
                    handleClose();
                    navigate("/signin");
                  }}
                >
                  Login
                </Button>
              </Modal.Footer>
            </Modal>

            <Form onSubmit={handleSubmit} style={styles.signInForm}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <OtpInput
                  value={otp}
                  onChange={setOTP}
                  numInputs={6}
                  renderSeparator={<span>-</span>}
                  inputStyle={{
                    width: "35px",
                    height: "35px",
                    margin: "10px",
                    fontSize: "16px",
                    borderRadius: "4px",
                    border: `1px solid ${Colors.BES_PURPLE}`,
                    textAlign: "center",
                  }}
                  renderInput={(props) => <input {...props} />}
                />
              </div>
              <Button
                className="block bes-input-box-shadow"
                size="lg"
                type="submit"
                disabled={!validateForm()}
                style={styles.button}
              >
                {isLoading ? (
                  <div className="d-flex align-items-center justify-content-center">
                    <Oval
                      type="Circles"
                      color="#fff"
                      height={30}
                      width={30}
                      wrapperStyle={{ margin: "0 auto" }}
                    />
                  </div>
                ) : (
                  "Verify OTP"
                )}
              </Button>

              <div className="text-center">
                {hasError && (
                  <p
                    className={"text-danger text-center mt-2"}
                    style={{ textAlign: "center" }}
                  >
                    {error}
                  </p>
                )}

                <Link
                  to="/resend-otp"
                  className="is-cursor text-center bg-dark-blue mt-2 btn-sm"
                  style={{ textAlign: "center", cursor: "pointer" }}
                >
                  Request a New OTP!
                </Link>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OTPpage;
