import axios from "axios";
import {
  registerUrl,
  loginUrl,
  fetchStoresUrl,
  fetchCartUrl,
  createOrderUrl,
  passwordResetUrl,
  sendResetRequestUrl,
  resendVerificationEmailUrl,
  getOrdersUrl,
  getOrderUrl,
  sendPaymentUrl,
  sendCouponUrl,
  getCurrentRateUrl,
  BannerImagesUrl,
  PromoImagesUrl,
  fetchPostsUrl,
  getPostUrl,
  fetchCategoriesUrl,
  fetchCategoriesbySlugUrl,
  fetchPostsbySearchUrl,
  fetchTagsUrl,
  getCartItemsUrl,
  createOrderV2Url,
  fetchUserGoggleLoginResponseUrl,
  editUserUrl,
  walletUrl,
  getTransactionsUrl,
  verifyPasswordUrl,
  fetchUserUrl,
  vertifyOTPUrl,
  resendOTPUrl,
  sendResetRequestOTPUrl
} from "./urls";
import { getFromStore } from "../utils/storage-helpers";

export const request = async (url, action, data = null, skip_auth = false) => {
  const token = await getFromStore("access_token");
  let config = { "Content-Type": "application/json" };

  if (!skip_auth) {
    config["Authorization"] = `Bearer ${token}`;
  }

  try {
    const response = await axios({
      method: action,
      url,
      data,
      headers: config,
    });
    return response;
  } catch (error) {
    if (error.response.status === 500) {
      error.response["data"] = {
        "Server Error": "Please reach out to an adminstrator.",
      };
      return error;
    } else if (error.response.statusText === "Unauthorized") {
      error.response["data"] = {
        detail:
          "You have been logged out automatically. Click the button below to sign in.",
      };
      return error;
    } else {
      return error;
    }
  }
};

export const userSignup = async (data) => {
  return await request(registerUrl(), "post", data, true);
};

export const userLogin = async (data) => {
  return await request(loginUrl(), "post", data, true);
};

export const fetchStores = async (count, searchTerm) => {
  return await request(fetchStoresUrl(count, searchTerm), "get", null, true);
};

export const fetchCart = async (code) => {
  return await request(fetchCartUrl(code), "put");
};

export const deleteCart = async (code) => {
  return await request(fetchCartUrl(code), "delete");
};

export const createOrder = async (data) => {
  return await request(createOrderUrl(), "post", data);
};

export const createOrderV2 = async (data) => {
  return await request(createOrderV2Url(), "post", data);
};

export const passwordReset = async (data) => {
  return await request(passwordResetUrl(), "put", data);
};

export const sendResetRequest = async (data) => {
  return await request(sendResetRequestUrl(), "post", data);
};
export const sendCouponRequest = async (data) => {
  return await request(sendCouponUrl(), "post", data);
};

export const getOrders = async (status) => {
  return await request(getOrdersUrl(status), "get");
};

export const getOrder = async (order_id) => {
  return await request(getOrderUrl(order_id), "get");
};

// export const orderPayment = async data => {
//     return await request(sendPaymentUrl(), 'post',  data)
// };

export const verifyPaymentTrans = async (data) => {
  return await request(sendPaymentUrl(), "post", data);
};

export const deleteOrder = async (order_id, data) => {
  return await request(getOrderUrl(order_id), "delete", data);
};

export const verifyAccount = async (id, data) => {
  return await request(registerUrl(id), "patch", data);
};

export const resendVerificationEmail = async (data) => {
  return await request(resendVerificationEmailUrl(), "post", data);
};

export const getCurrentRate = async () => {
  return await request(getCurrentRateUrl(), "get");
};

export const BannerImages = async () => {
  return await request(BannerImagesUrl(), "get", null, true);
};

export const PromoImages = async () => {
  return await request(PromoImagesUrl(), "get", null, true);
};

export const getPosts = async () => {
  return await request(fetchPostsUrl(), "get", null, true);
};

export const getTags = async () => {
  return await request(fetchTagsUrl(), "get", null, true);
};
export const getPostdetail = async (slug) => {
  return await request(getPostUrl(slug), "get", null, true);
};

export const getCategories = async () => {
  return await request(fetchCategoriesUrl(), "get", null, true);
};

export const getPostbyCategory = async (slug) => {
  return await request(fetchCategoriesbySlugUrl(slug), "get", null, true);
};

export const getPostsBySearch = async (slug) => {
  return await request(fetchPostsbySearchUrl(slug), "get", null, true);
};

export const getCartItems = async (code) => {
  return await request(getCartItemsUrl(code), "get", null, true);
};

export const getUserGoggleLoginResponse = async (code) => {
  return await request(
    fetchUserGoggleLoginResponseUrl(code),
    "get",
    null,
    true
  );
};

export const updateUserDetails = async (data) => {
  return await request(editUserUrl(), "put", data);
};

export const getWalletBalance = async () => {
  return await request(walletUrl(), "get");
};

export const walletTransaction = async (data) => {
  return await request(walletUrl(), "post", data);
};

export const getTransactions = async (type, reference) => {
  return await request(getTransactionsUrl(type, reference), "get");
};

export const paymentTransaction = async (data) => {
  return await request(sendPaymentUrl(), "post", data);
};

export const verifyUserPassword = async (data) => {
  return await request(verifyPasswordUrl(), "post", data);
};

export const getUser = async () => {
  return await request(fetchUserUrl(), "get");
};

export const verifyOTPRequest = async (data) => {
  return await request(vertifyOTPUrl(), "post", data, true);
};

export const resendOTP = async (data) => {
  return await request(resendOTPUrl(), "post", data, true);
};

export const sendResetRequestOTP = async (data) => {
  return await request(sendResetRequestOTPUrl(), "post", data, true);
};