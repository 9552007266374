import React from "react";
import "./styles.css";

const Loading = ({ text }) => {
  return (
    <>
      <div id="cover-spin"></div>
      <div className="loading-icon-container">
        <div className="dot-pulse"></div>
        <small className="text-center mt-1 loader-text">{text}</small>
      </div>
    </>
  );
};

export default Loading;
