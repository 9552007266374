import React, { useState, useEffect } from "react";
import {
  Form,
  FormControl,
  InputGroup,
  Row,
  Col,
  Alert,
  Image,
  Modal,
  Button,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Oval } from "react-loader-spinner";

import Header from "../../components/Header";
import { fetchStores } from "../../services/api";
import { Colors } from "../../utils/styles";
import styles from "./style";

const StoresPage = () => {
  const [stores, setStores] = useState([]);
  const [searchText, setsearchText] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [searchLoader, setSearchLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedStore, setSelectedStore] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    getStores();
  }, []);

  const getStores = async () => {
    setIsLoading(true);
    const storeList = await fetchStores();

    if (storeList.status === 200) {
      const sortedStores = storeList.data.sort((a, b) => {
        const nameA = a.displayname.toUpperCase(); // ignore upper and lowercase
        const nameB = b.displayname.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });

      setStores(sortedStores);
    } else {
      setError(storeList.response.data.detail);
    }
    setIsLoading(false);
  };

  const handleSearchInput = async (e) => {
    setSearchLoader(true);
    let storeList = [];
    setsearchText(e.target.value);

    if (!e.target.value.length) {
      storeList = await fetchStores(null, null);
    } else {
      storeList = await fetchStores(null, searchText);
    }
    setStores(storeList.data);
    setSearchLoader(false);
  };

  const handleClose = () => setShow(false);

  const StoreSupportModal = ({ store }) => {
    return (
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ justifyContent: "center" }}>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontWeight: 600 }}
          >
            Hello Beta-eshopper!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            textAlign: "center",
            alignContent: "center",
          }}
        >
          <span style={{ width: 20 }}>
            The beta-eshopping chrome extension does not currently support{" "}
            {store.displayname}. You may want to place a custom order instead.
          </span>
        </Modal.Body>
        <Modal.Footer
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <a href={store.store_url} target="_blank" rel="noreferrer">
            <Button
              onClick={() => {
                handleClose();
                navigate("/custom-order");
              }}
              style={{
                borderRadius: 10,
                backgroundColor: Colors.BES_PURPLE,
                color: "#FFFFFF",
                fontSize: 16,
                width: 200,
                marginTop: 20,
                height: 45,
                border: "none",
                fontWeight: 600,
              }}
            >
              Place Custom Order
            </Button>
          </a>
          <Button
            onClick={handleClose}
            style={{
              borderRadius: 10,
              backgroundColor: Colors.BES_RED,
              color: "#FFFFFF",
              fontSize: 16,
              width: 200,
              marginTop: 20,
              height: 50,
              border: "none",
              fontWeight: 600,
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const storesSupported = [
    "sports direct",
    "aztecasoccer",
    "amazon",
    "amazon uk",
    "world of watches",
    "lauras boutique",
    "carter's",
    "clarks",
    "prettylittlething",
    "carparts4less",
    "perfumebox",
    "sparepartsstore24",
    "burton",
    "zara",
    "aldo",
    "carid",
    "jd sports",
    "bath & body works",
    "matchesfashion",
    "gymshark",
    "lookfantastic",
    "t.m. lewin",
    "cottonon",
    "cookie's kids",
    "tk maxx",
    "ebonyline",
    "neiman marcus",
    "oshkosh",
    "accessorize",
    "dorothy perkins",
    "emporio armani",
    "men's wearhouse",
    "nine west",
    "watch station",
    "kitbag",
    "zalando",
    "standout",
    "scotts",
    "divatress",
    "madewell",
    "rag & bone",
    "kith us",
    "bnkr",
    "office",
    "forever 21",
    "nyx cosmetics",
    "hudabeauty",
  ];

  const handleSupportedStores = (store) => {
    if (!storesSupported.includes(store.displayname.toLowerCase())) {
      setShow(true);
      setSelectedStore(store);
    } else {
      window.open(store.store_url, "_blank");
    }
  };

  return (
    <div>
      <StoreSupportModal store={selectedStore} />
      <Header bg={"white"} varaint={"light"} hideAuth={true} />
      {error && (
        <Alert
          variant="danger"
          style={{ width: "30%", margin: "0 auto", textAlign: "center" }}
          onClose={() => setError("")}
          dismissible
        >
          {error}
        </Alert>
      )}
      <div class="container mt-5">
        <Row style={{ margin: "0 0 50px 10%" }}>
          <Col lg={8}>
            <p style={styles.customText}>
              Can’t find your favourite store?{" "}
              <a href="/custom-order" style={styles.customSpanText}>
                Place a custom order
              </a>
            </p>
          </Col>
          <Col lg={4}>
            <Form>
              <InputGroup style={{ width: "90%" }}>
                <FormControl
                  onChange={(e) => handleSearchInput(e)}
                  value={searchText}
                  type="text"
                  placeholder="Search stores"
                  className="mr-sm-2 bes-input-box-shadow"
                />
                {searchLoader && (
                  <InputGroup.Text id="basic-addon2">
                    <Oval
                      type="Circles"
                      color="#5034FF"
                      height={25}
                      width={25}
                    />
                  </InputGroup.Text>
                )}
              </InputGroup>
            </Form>
          </Col>
        </Row>
        {isLoading ? (
          <Oval
            type="Circles"
            color="#5034FF"
            height={50}
            width={50}
            wrapperStyle={{ left: "50%", position: "absolute" }}
          />
        ) : (
          <Row
            style={{ textAlign: "center", width: "90%" }}
            className="mx-auto"
          >
            {stores.length > 0 &&
              stores.map((store) => {
                return (
                  store.show && (
                    <>
                      <Col lg={3} xs={12} key={store.id}>
                        <div
                          style={{
                            width: "70%",
                            margin: "0px auto 20px",
                            marginTop: 20,
                            marginBottom: 20,
                            paddingTop: 20,
                            borderRadius: 10,
                            boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
                            cursor: "pointer",
                          }}
                          className="border stores-card"
                          onClick={() => handleSupportedStores(store)}
                        >
                          <Image
                            src={store.store_icon}
                            style={{ width: 100, height: 100 }}
                            rounded
                          />
                          <p
                            style={{
                              fontWeight: 500,
                              paddingTop: 10,
                              fontSize: "fit-content",
                            }}
                          >
                            {store.displayname}
                          </p>
                        </div>
                      </Col>
                    </>
                  )
                );
              })}
          </Row>
        )}
      </div>
    </div>
  );
};

export default StoresPage;
