import { createContext } from 'react';

const AuthContext = createContext({
    isAuthenticated: false,
    setAuth: () => {},
    user: {},
    setUser: () => {}
});

export default AuthContext;
