import { Colors } from "../../utils/styles";

const styles = {
  bodyContainer: {
    height: "100vh",
    width: "100vw",
  },
  rowSection: {
    height: "100%",
  },
  col1: {
    backgroundColor: Colors.BES_PURPLE,
  },
  formInput: {
    borderRadius: 8,
    border: "1px solid #BEBDFF",
    height: 46,
    margin: "8 0",
    fontSize: "14px",
  },
  registerFormLabel: {
    color: "#181743",
    fontSize: 16,
    fontWeight: 500,
  },
  loginText: {
    margin: "0 auto",
    marginTop: 40,
    fontSize: 16,
    textAlign: "center",
    color: "#ffff",
    fontWeight: "normal",
  },
  loginSpanText: {
    color: "tomato",
    marginLeft: 5,
  },
  termsAndConditionButton: {
    background: "none",
    border: "none",
    color: Colors.BES_PURPLE,
    cursor: "pointer",
    verticalAlign: "text-bottom",
    marginLeft: 10,
  },
  button: {
    width: "100%",
    height: 50,
    margin: "40px auto auto auto",
    display: "block",
    background: "#ffff",
    borderRadius: 5,
    border: "none",
    color: "#333",
  },
  secureEntry: {
    position: "absolute",
    right: "10px",
    top: "50%",
    width: 14,
    height: 14,
    transform: "translateY(-50%)",
    cursor: "pointer",
    color: "#666",
  },
};

export default styles;
