import React from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";

import "react-phone-number-input/style.css";
import "./style.scss";
import styles from "./style";
import AutoplayCarousel from "../AuthCarousel";

const AuthWrapper = ({ header, description, showHome, children }) => {
  return (
    <div>
      <div style={styles.rowSection} className="main-auth-wrapper">
        <div
          className="main-content main-left"
        >
          <div className="main-content-box">
            { !showHome ? <div className="auth-navbar">
              <Link
                to="/"
                className="outline bes_purple-color  "
              >
               <i class="fa-solid fa-house bes_purple-color"></i> Home
              </Link>
            </div>: null}
            {children}
          </div>
        </div>
        <div className="main-right">
          <Container
            style={{ marginTop: 20, paddingLeft: 70 }}
            className="signup-left-container"
          >
            <div className="flex  flex-dc flex-jc-c flexi-ai-c">
            <div className="mb-3 text-center">
              <h1 style={{ color: "#FFFFFF", fontWeight: 600 }}>{header}</h1>
              <p style={{ color: "#FFFFFF", marginTop: 10 }}>{description}</p>
            </div>
            <AutoplayCarousel />
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default AuthWrapper;
