import React from "react";
import { Modal } from "react-bootstrap";
import { Colors } from "../../../utils/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import NigerianFlag from "../assets/nigeria-flag.png";
import USFlag from "../assets/us-flag.png";

export const Separator = ({ width }) => {
  return (
    <div
      style={{
        border: `0.3px solid ${Colors.GRAY_LIGHT}`,
        width: width,
      }}
    ></div>
  );
};

const CurrencyCard = ({ title, onClick, imageUri }) => {
  return (
    <div onClick={onClick} style={styles.container}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <img src={imageUri} style={styles.image} alt="currency-options" />
          <p style={{ fontSize: 14, paddingLeft: 10, marginBottom: 0 }}>
            {title}
          </p>
        </div>
        <FontAwesomeIcon
          icon={faChevronRight}
          color={Colors.GRAY_MEDIUM}
          size="xs"
        />
      </div>
    </div>
  );
};

const PaymentOptions = ({ showModal, togglePaymentOption }) => {
  const navigate = useNavigate();
  return (
    // return a modal of payment options
    <Modal
      className="modal-content-z"
      show={showModal}
      size="xs"
      onHide={togglePaymentOption}
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <h5 className="fw-bold">Select Currency</h5>
      </Modal.Header>
      <Modal.Body>
        <CurrencyCard
          title="Nigerian Naira (₦)"
          imageUri={NigerianFlag}
          onClick={() => {
            togglePaymentOption();
            navigate("/wallet/fund", { state: { fundingType: "Naira" } });
          }}
        />
        <Separator />
        <CurrencyCard
          title="US Dollar ($)"
          imageUri={USFlag}
          onClick={() => {
            togglePaymentOption();
            navigate("/wallet/fund", { state: { fundingType: "Dollar" } });
          }}
        />
      </Modal.Body>
    </Modal>
  );
};

export default PaymentOptions;

const styles = {
  container: {
    cursor: "pointer",
    padding: "15px 0px 15px 0px",
  },
  image: {
    width: 20,
    height: 20,
    borderRadius: 50,
  },
};
