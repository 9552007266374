import { Colors } from "../../utils/styles";

const styles =  {
    bodyContainer: {
        marginTop: 80
    },
    customTextText: {
        width: 385,
        marginTop: 30,
        fontWeight: 600,
        fontSize: 16,
        textAlign: "left",
        color: "#3F474B",
    },
    customSpanText: {
        color: Colors.BES_RED,
        textDecoration: "none"
    },
};

export default styles;