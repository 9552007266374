
const styles = {
  bodyContainer: {
    //   marginLeft: 100,

    marginTop: 30,
  },
  signInHeaderText: {
    width: "80%",
    fontWeight: 700,
    fontSize: 50,
    textAlign: "left",
    color: "#",
  },
  loginText: {
    width: 385,
    marginTop: 24,
    fontWeight: 600,
    fontSize: 16,
    textAlign: "left",
    color: "#333",
  },
  loginSpanText: {
    color: "#F84145",
    marginTop: 10,
  },
  loginSpanRegisterText: {
    color: "#F84145",
    marginTop: 10,
  },

  signInForm: {
    marginTop: 80,
  },
  signInFormContainer: {
    marginBottom: 5,
  },
  signInFormLabel: {
    fontWeight: 500,
    fontSize: 16,
    color: "#333",
  },
  formInput: {
    borderRadius: 8,
    border: "1px solid grey",
    height: 46,
    margin: "8 0",
  },
  button: {
    width: "100%",
    height: 50,
    margin: "40px auto auto auto",
    display: "block",
    background: "#ffff",
    borderRadius: 5,
    border: "none",
    color: "#333",
  },
};

export default styles;