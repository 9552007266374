// BASIC
export const PRIMARY = "#1779BA";
export const SECONDARY = "#767676";
export const WHITE = "#FFFFFF";
export const BLACK = "#000000";

// BES
export const BES_PURPLE = "#433B80";
export const BES_RED = "#F84145";
export const BES_PINK = "#FED6D6";
export const BES_BLACK = "#333333";

// ACTIONS
export const SUCCESS = "#3ADB76";
export const WARNING = "#FFAE00";
export const ALERT = "#CC4B37";

// GRAYSCALE
export const GRAY_LIGHT = "#E6E6E6";
export const GRAY_MEDIUM = "#CACACA";
export const GRAY_DARK = "#8A8A8A";
export const GRAY_DARKER = "#565656";
