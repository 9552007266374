// import { Colors } from "../../utils/styles";

const styles =  {
    cartListHeader: {
        fontWeight: 600,
        fontSize: 16,
        color: "#22262A"
    },
    smallCols: {
        width: "100px"
    },
    formInput: {
        borderRadius: 8,
        border: "1px solid #BEBDFF",
        height: 40,
        marginBottom:10,
        width: "100%",
    },
};

export default styles;
