import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import Loading from "../../components/Loading";

import { clean_naira_format, clean_dollar_format } from "../../utils/utilities";
import AuthContext from "../../context/authContext";
import Header from "../../components/Header";
import OrderItem from "../../components/OrderItem";
import TermsAndConditions from "../../components/TermsAndConditions";
import Checkmark from "../../components/Checkmark";
import PaymentOptions from "./PaymentOptions";
import LoginPage from "../login";
import {
  getOrder,
  verifyPaymentTrans,
  sendCouponRequest,
  paymentTransaction,
  getWalletBalance,
} from "../../services/api";
import { ROUNDED_BUTTON } from "../../utils/styles/buttons";
import { getFromStore, saveToStore } from "../../utils/storage-helpers";

import { Colors } from "../../utils/styles";
import styles from "./style";
import "./style.scss";
import { AlertTitle, Checkbox, FormControlLabel, Alert } from "@mui/material";

export const paymentStatusMapping = {
  successful: "successful",
  failed: "failed",
  pending: "pending",
};

const CheckOutPage = (props) => {
  const [order, setOrder] = useState(null);
  const [error, setError] = useState("");
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [discountCode, setDiscountCode] = useState("");
  const [acceptCondiiton, setAcceptConditions] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [optionmodalShow, setOptionmodalShow] = useState(false);
  const [paymentType, setPaymentType] = useState("");
  const [isWalletPayChecked, setIsWalletPayChecked] = useState(false);
  const [flutterwaveConfig, setFlutterwaveConfig] = useState(null);
  const [paymentVerificationLoading, setPaymentVerificationLoading] =
    useState(false);
  const [paymentTransactionLoading, setPaymentTransactionLoading] =
    useState(false);
  const [walletBalance, setWalletBalance] = useState(0);
  const [walletBalanceNaira, setWalletBalanceNaira] = useState(0);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const handleModalClose = () => setModalShow(false);

  const handleOptionModalClose = () => setOptionmodalShow(false);

  const { isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();
  let params = useParams();

  const orderId = params.order_id;

  // Store payment methods validation as a bool

  // Store payment methods validation as a bool
  const validatePaymentMethods = () => {
    if (order?.additional_payment > 0) {
      return (
        isWalletPayChecked &&
        Number(order?.additional_payment) <= Number(walletBalance)
      );
    } else {
      return (
        isWalletPayChecked &&
        Number(order?.order_total) <= Number(walletBalance)
      );
    }
  };
  // const validateOrderPaymentMethods =
  //   isWalletPayChecked && Number(order?.order_total) <= Number(walletBalance);

  // const validateAdditionalPaymentMethods =
  //   isWalletPayChecked &&
  //   Number(order?.additional_payment) <= Number(walletBalance);

  // Function calculates payment based on payment option selected
  const calculatePayment = (
    amount,
    isAdditionalPayment,
    isWalletPayChecked,
    walletBalanceValue
  ) => {
    const numericAmount = Number(amount);
    const numericWalletBalance = isWalletPayChecked
      ? Number(walletBalanceValue)
      : 0;

    return isAdditionalPayment && !isWalletPayChecked
      ? numericAmount
      : Math.max((numericAmount - numericWalletBalance).toFixed(2), 0);
  };

  const nairaValue =
    Number(order?.additional_payment) > 0
      ? order?.additional_payment_naira
      : order?.order_total_naira;

  const dollarValue =
    Number(order?.additional_payment) > 0
      ? order?.additional_payment
      : order?.order_total;

  // Construct the payment object to be supplied to fw_config
  const paymentObj = useMemo(() => {
    return {
      naira: calculatePayment(
        nairaValue,
        Number(order?.additional_payment) > 0,
        isWalletPayChecked,
        walletBalanceNaira
      ),
      dollar: calculatePayment(
        dollarValue,
        Number(order?.additional_payment) > 0,
        isWalletPayChecked,
        walletBalance
      ),
    };
  }, [
    dollarValue,
    isWalletPayChecked,
    nairaValue,
    order?.additional_payment,
    walletBalance,
    walletBalanceNaira,
  ]);

  const updatePaymentConfigurations = useCallback(() => {
    if (order) {
      const config = {
        public_key: process.env.REACT_APP_FLUTTER_PUBKEY,
        tx_ref: `BES-${Date.now()}`,
        amount: paymentType === "naira" ? paymentObj.naira : paymentObj.dollar,
        currency: paymentType === "naira" ? "NGN" : "USD",
        payment_options: "card,mobilemoney,ussd",
        customer: {
          email: order?.shopper.email,
          phonenumber: order?.shopper.phone_no,
          name: `${order?.shopper.first_name} ${order?.shopper.last_name}`,
        },
        customizations: {
          title: "beta-eshopping",
          description: `Payment for order: ${order?.order_id}`,
          logo: "https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg",
        },
      };

      // const dollarConfig = {
      //   public_key: process.env.REACT_APP_FLUTTER_PUBKEY,
      //   tx_ref: `BES-${Date.now()}`,
      //   amount:
      //     order?.additional_payment > 0
      //       ? order?.additional_payment
      //       : order?.order_total,
      //   currency: "USD",
      //   payment_options: "card,mobilemoney,ussd",
      //   customer: {
      //     email: order?.shopper.email,
      //     phonenumber: order?.shopper.phone_no,
      //     name: `${order?.shopper.first_name} ${order?.shopper.last_name}`,
      //   },
      // };

      // const config = paymentType === "naira" ? nairaConfig : dollarConfig;

      setFlutterwaveConfig(config);
    }
  }, [order, paymentType, paymentObj]);

  const handleOptionChange = (event) => {
    setPaymentType(event.target.value);
  };

  const fetchWalletBalance = async () => {
    const transactions_res = await getWalletBalance();
    if (transactions_res?.status === 200) {
      setWalletBalance(transactions_res?.data.balance);
      setWalletBalanceNaira(transactions_res?.data.balance_naira);
    }
  };

  const getOrderDetails = useCallback(async (id) => {
    const orderDetailsRes = await getOrder(id);

    if (orderDetailsRes.response?.status >= 400) {
      setError(orderDetailsRes.response.data.detail);
    } else {
      setOrder(orderDetailsRes.data);
    }
  }, []);

  const validateDiscountForm = () => {
    return discountCode.length > 0;
  };

  useEffect(() => {
    updatePaymentConfigurations();
  }, [order, updatePaymentConfigurations]);

  useEffect(() => {
    getOrderDetails(orderId);
    fetchWalletBalance();
  }, [orderId, getOrderDetails, paymentType]);

  const validateForm = () => {
    return (
      (acceptCondiiton && paymentType) ||
      (acceptCondiiton && isWalletPayChecked)
    );
  };

  const handleFlutterPayment = useFlutterwave(flutterwaveConfig);

  const handleMakePayment = async () => {
    if (!optionmodalShow) {
      setPaymentTransactionLoading(true);
    }
    const order_rate_storage_status = await getFromStore(orderId);

    // Save to store for new Orders
    if (order_rate_storage_status === null) {
      await saveToStore(orderId, order?.has_rate_change);
    }

    if (
      paymentType &&
      JSON.stringify(order?.has_rate_change) !==
        JSON.stringify(order_rate_storage_status) &&
      order?.has_rate_change.status
    ) {
      setOptionmodalShow(true);
      await saveToStore(orderId, order?.has_rate_change);
    } else {
      setOptionmodalShow(false);
      updatePaymentConfigurations();
      handleFlutterPayment({
        callback: (response) => {
          saveTransaction(response, order?.order_id);
          closePaymentModal();
          setPaymentTransactionLoading(false);
        },
      });
    }
  };

  const saveTransaction = async (transaction, order_id) => {
    setPaymentTransactionLoading(true);
    const transactionData = {};
    transactionData.order_id = order_id;
    transactionData.transaction_ref = transaction.tx_ref;
    if (paymentType && isWalletPayChecked) {
      transactionData.is_wallet = true;
    }

    const transactionStatus = await verifyPaymentTrans(transactionData);

    if (transactionStatus.status !== 200) {
      const errMsg = transactionStatus?.response?.data?.message;
      setError(errMsg);
      setPaymentVerificationLoading(false);
    } else {
      const status = transactionStatus?.data?.status;
      navigate(
        `/payment?status=${paymentStatusMapping[status]}&ref=${order.order_id}`
      );
    }
    setPaymentVerificationLoading(false);
  };

  const handleWalletPayment = async () => {
    setPaymentTransactionLoading(true);
    const data = { order_id: order?.order_id, is_wallet: true };
    const transaction_res = await paymentTransaction(data);
    if (transaction_res.status !== 200) {
      const errMsg = transaction_res.response.data.message;
      toast.error(errMsg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "light",
      });
    } else {
      const status = transaction_res?.data?.status;
      navigate(
        `/payment?status=${paymentStatusMapping[status]}&ref=${order.order_id}`
      );
    }
    setPaymentTransactionLoading(false);
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const order_id = order.order_id;
    const coupon_res = await sendCouponRequest({ discountCode, order_id });
    if (coupon_res.status !== 200) {
      const err = coupon_res.response.data;
      toast.error(err.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "light",
      });
      // setError(errMsg);
      setHasError(true);
      setDiscountCode("");
    } else {
      // setMessage(coupon_res.data.message);
      setHasError(false);
      setDiscountCode("");
      setTimeout(() => {
        setIsLoading(false);
        window.location.reload(false);
      }, 3000);
    }
  };

  if (!isAuthenticated) {
    return <LoginPage path="/pending-orders" />;
  }

  return (
    <>
      <Header hideAuth={true} isAuthenticated={isAuthenticated} />
      <section style={{ height: "100vh" }} className="container">
        <Modal
          size="md"
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={optionmodalShow}
          onHide={handleOptionModalClose}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Conversion Rates Update!
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Our conversion rates changed and your order details has been
              updated.
            </p>
            <p>
              Order Total (N) = {clean_naira_format(order?.order_total_naira)}
            </p>
            <p>Order Total ($) = {clean_dollar_format(order?.order_total)} </p>

            <p> Click "Continue" to confirm payment`</p>
            <div className="flex flex-jc-c gap-6 mb-2">
              <Button
                disabled={!validateForm()}
                style={{
                  backgroundColor: Colors.BES_PURPLE,
                  height: 53,
                  width: 200,
                  margin: "0 auto",
                  border: "none",
                  marginBottom: 10,
                }}
                onClick={(e) => {
                  if (validatePaymentMethods() || !paymentType) {
                    // handle payment with betaWallet
                    handleWalletPayment();
                  } else {
                    // handle flutterwave payment in NGN/USD
                    handleMakePayment();
                  }
                }}
              >
                Continue
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          {...props}
          size="md"
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={modalShow}
          onHide={handleModalClose}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Bank Details - USD/NGN
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>Bank: Guaranty Trust Bank Ltd</h5>
            <p>Address: 31 Mobolaji Bank Anthony Way, Ikeja, Lagos, Nigeria.</p>
            <p>Sort Code: 058-152023</p>
            <p>Swift Code: GTBINGLA</p>
            <p>NUBAN: 0679125931</p>
            <p>Account Name: Beta Courier Services Ltd</p>
            <p>DOLLAR Account No: 0679125931</p>
            <p>NAIRA Account No: 0002887073</p>
            <p>
              Kindly send proof of payment with order ID to
              info@beta-eshopping.com.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="md"
              style={{
                ...ROUNDED_BUTTON,
                width: 100,
                height: 40,
                margin: "0 auto",
              }}
              onClick={handleModalClose}
            >
              OK
            </Button>
          </Modal.Footer>
        </Modal>

        {error && (
          <Alert
            severity="error"
            style={{ width: "50%", margin: "0 auto", textAlign: "center" }}
            onClose={() => setError("")}
          >
            <AlertTitle>Something went wrong!</AlertTitle>
            {error}
          </Alert>
        )}
        <Container>
          {paymentVerificationLoading ? (
            <Loading text="Processing payment. Please wait.." />
          ) : null}
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover={false}
            theme="light"
          />
          <Modal
            className="modal-content-z"
            show={showModal}
            onHide={handleClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>Terms and Conditions</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <TermsAndConditions />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {order?.additional_payment > 0 ? (
            <Row style={{ height: "100%" }}>
              <Col lg={8} style={{ marginTop: 55 }}>
                <h4 style={{ width: 460, fontWeight: 600 }}>Checkout</h4>
                <OrderItem order={!isLoading && order} />
              </Col>
              <Col
                lg={4}
                style={{ margin: "90px auto" }}
                className="cart-box px-4"
              >
                <Row className="mt-3">
                  <Col>
                    <p style={styles.checkoutLabels}>Additional Fee</p>
                  </Col>
                  <Col>
                    <p style={styles.checkoutValues}>
                      {clean_dollar_format(order?.additional_payment)}
                    </p>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col>
                    <h5 style={styles.checkoutLabels}>Total</h5>
                  </Col>
                  <Col>
                    <h5 style={styles.checkoutValues}>
                      {clean_dollar_format(order?.additional_payment)}
                    </h5>
                  </Col>
                </Row>
                <Row>
                  <div
                    className="flex p-2 mb-3 mt-4
                  rounded choose-options"
                  >
                    <div>
                      {/* <p className="bes_purple-color text-sm">
                        Choose Payment Method
                      </p> */}
                      {/* Naira and USD Options */}
                      <PaymentOptions
                        paymentType={paymentType}
                        handleOptionChange={handleOptionChange}
                        isWalletPayChecked={isWalletPayChecked}
                        handleWalletOnChange={(event) =>
                          setIsWalletPayChecked(event.target.checked)
                        }
                        walletBalance={walletBalance}
                        disableFlutterWavePaymentOptions={validatePaymentMethods()}
                        disableWalletPaymentOption={Number(walletBalance) === 0}
                      />
                      {validatePaymentMethods() ? (
                        <p
                          style={{
                            color: Colors.BES_RED,
                            fontSize: 12,
                            fontWeight: "600",
                            paddingTop: 5,
                            textAlign: "center",
                            marginBottom: 0,
                          }}
                        >
                          👉 Only your BetaWallet will be charged for this
                          transaction.
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <FormControlLabel
                    className="mt-1 mb-3"
                    value="I accept the terms and conditions"
                    onChange={(e) => {
                      setAcceptConditions(e.target.checked);
                    }}
                    checked={acceptCondiiton}
                    control={
                      <Checkbox
                        sx={{
                          color: Colors.BES_PURPLE,
                          "&.Mui-checked": {
                            color: Colors.BES_PURPLE,
                          },
                        }}
                      />
                    }
                    label={
                      <p className="mb-0">
                        <Link
                          onClick={(e) => {
                            e.preventDefault();
                            handleShow();
                          }}
                          style={{ textDecoration: "underline" }}
                        >
                          I accept the terms and conditions.
                        </Link>
                      </p>
                    }
                  />
                  <Button
                    disabled={!validateForm() || paymentTransactionLoading}
                    style={{
                      backgroundColor: Colors.BES_PURPLE,
                      height: 53,
                      width: 300,
                      margin: "0 auto",
                      border: "none",
                      marginBottom: 20,
                    }}
                    onClick={() =>
                      validatePaymentMethods() ||
                      (isWalletPayChecked && !paymentType)
                        ? handleWalletPayment()
                        : handleMakePayment()
                    }
                  >
                    Pay{" "}
                    {(paymentType === "naira" && !isWalletPayChecked) ||
                    (paymentType === "naira" &&
                      isWalletPayChecked &&
                      !validatePaymentMethods())
                      ? clean_naira_format(flutterwaveConfig.amount)
                      : (paymentType === "usd" && !isWalletPayChecked) ||
                        (paymentType === "usd" &&
                          isWalletPayChecked &&
                          !validatePaymentMethods())
                      ? clean_dollar_format(flutterwaveConfig.amount)
                      : isWalletPayChecked && order?.additional_payment > 0
                      ? clean_dollar_format(order?.additional_payment)
                      : validatePaymentMethods()
                      ? clean_dollar_format(order?.additional_payment)
                      : ""}
                  </Button>
                </Row>
              </Col>
            </Row>
          ) : (
            <Row style={{ height: "100%" }}>
              <Col lg={8} style={{ marginTop: 55 }}>
                <h4 style={{ width: 460, fontWeight: 600, marginButtom: 5 }}>
                  Checkout
                </h4>
                <OrderItem order={order} />
              </Col>
              <Col
                lg={4}
                style={{ margin: "90px auto" }}
                className="cart-box px-4"
              >
                <Row className="mt-3">
                  <Col>
                    <p style={styles.checkoutLabels}>Subtotal</p>
                    <p style={styles.checkoutLabels}>Shipping fee</p>
                    <p style={styles.checkoutLabels}>Handling fee</p>
                    <p style={styles.checkoutLabels}>Tax & Duties</p>
                    {order?.discount_amount !== null &&
                    order?.discount_code !== null ? (
                      <p style={styles.checkoutLabels}>Discount</p>
                    ) : null}
                  </Col>
                  <Col>
                    <p style={styles.checkoutValues}>
                      {clean_dollar_format(order?.amount)}
                    </p>
                    <p style={styles.checkoutValues}>
                      {clean_dollar_format(order?.shipping_fee)}
                    </p>
                    <p style={styles.checkoutValues}>
                      {clean_dollar_format(order?.handling_fee)}
                    </p>
                    <p style={styles.checkoutValues}>
                      {clean_dollar_format(order?.tax_duties)}
                    </p>

                    {order?.discount_code != null && (
                      <p style={{ color: "red", textAlign: "end" }}>
                        - {clean_dollar_format(order?.discount_amount)}
                      </p>
                    )}
                  </Col>
                </Row>

                {order?.discount_amount == null &&
                order?.discount_code == null ? (
                  <Row className="mt-5">
                    <Col lg={9}>
                      {/* <div style={{display: "flex", justifyContent: "space-between", alignItems: 'center'}}> */}
                      <Form onSubmit={handleSubmit} style={styles.signInForm}>
                        <Form.Group
                          size="lg"
                          controlId="promo-code"
                          style={{ width: 300 }}
                          className="form-group"
                        >
                          <Form.Label style={{ color: Colors.GRAY_DARK }}>
                            {" "}
                            Enter a valid discount code below.
                          </Form.Label>
                          <Form.Control
                            placeholder="Discount code"
                            value={discountCode}
                            onChange={(e) => setDiscountCode(e.target.value)}
                            className="loginFormInput"
                            style={{ width: 250 }}
                          />
                        </Form.Group>
                      </Form>
                    </Col>
                    <Col lg={3}>
                      {isLoading & !hasError ? (
                        <Checkmark />
                      ) : (
                        <Button
                          type="submit"
                          disabled={!validateDiscountForm()}
                          style={{
                            fontWeight: 550,
                            background: Colors.BES_PURPLE,
                            border: "none",
                          }}
                          onClick={handleSubmit}
                        >
                          Claim
                        </Button>
                      )}
                    </Col>
                  </Row>
                ) : null}
                <hr />
                <Row>
                  <Col>
                    <h5 style={styles.checkoutLabels}>Total</h5>
                  </Col>
                  <Col>
                    <h5 style={styles.checkoutValues}>
                      {clean_dollar_format(order?.order_total)}
                    </h5>
                  </Col>
                </Row>
                <Row>
                  <div
                    className="flex p-2 mb-3 mt-4
                  rounded choose-options"
                  >
                    <div>
                      {/* <p className="bes_purple-color text-sm">
                        Choose Payment Method
                      </p> */}
                      {/* Naira and USD Options */}
                      <PaymentOptions
                        paymentType={paymentType}
                        handleOptionChange={handleOptionChange}
                        isWalletPayChecked={isWalletPayChecked}
                        handleWalletOnChange={(event) =>
                          setIsWalletPayChecked(event.target.checked)
                        }
                        walletBalance={walletBalance}
                        disableFlutterWavePaymentOptions={validatePaymentMethods()}
                        disableWalletPaymentOption={Number(walletBalance) === 0}
                      />
                      {validatePaymentMethods() ? (
                        <p
                          style={{
                            color: Colors.BES_RED,
                            fontSize: 12,
                            fontWeight: "600",
                            paddingTop: 5,
                            textAlign: "center",
                            marginBottom: 0,
                          }}
                        >
                          👉 Only your BetaWallet will be charged for this
                          transaction.
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <FormControlLabel
                    className="mt-1 mb-3"
                    value="I accept the terms and conditions"
                    onChange={(e) => {
                      setAcceptConditions(e.target.checked);
                    }}
                    checked={acceptCondiiton}
                    control={
                      <Checkbox
                        sx={{
                          color: Colors.BES_PURPLE,
                          "&.Mui-checked": {
                            color: Colors.BES_PURPLE,
                          },
                        }}
                      />
                    }
                    label={
                      <p className="mb-0">
                        <Link
                          onClick={(e) => {
                            e.preventDefault();
                            handleShow();
                          }}
                          style={{ textDecoration: "underline" }}
                        >
                          I accept the terms and conditions.
                        </Link>
                      </p>
                    }
                  />
                  <Button
                    disabled={!validateForm() || paymentTransactionLoading}
                    style={{
                      backgroundColor: Colors.BES_PURPLE,
                      height: 53,
                      width: 300,
                      margin: "0 auto",
                      border: "none",
                      marginBottom: 20,
                    }}
                    onClick={() =>
                      validatePaymentMethods() ||
                      (isWalletPayChecked && !paymentType)
                        ? handleWalletPayment()
                        : handleMakePayment()
                    }
                  >
                    Pay{" "}
                    {(paymentType === "naira" && !isWalletPayChecked) ||
                    (paymentType === "naira" &&
                      isWalletPayChecked &&
                      !validatePaymentMethods())
                      ? clean_naira_format(flutterwaveConfig.amount)
                      : (paymentType === "usd" && !isWalletPayChecked) ||
                        (paymentType === "usd" &&
                          isWalletPayChecked &&
                          !validatePaymentMethods())
                      ? clean_dollar_format(flutterwaveConfig.amount)
                      : isWalletPayChecked && !paymentType
                      ? clean_dollar_format(order?.order_total)
                      : validatePaymentMethods()
                      ? clean_dollar_format(order?.order_total)
                      : ""}
                  </Button>
                </Row>
              </Col>
            </Row>
          )}

          {paymentTransactionLoading && <Loading text="Processing Payment" />}
        </Container>
      </section>
    </>
  );
};

export default CheckOutPage;
