import { Colors } from '../../utils/styles';

const styles =  {
    pendingOrdersHeader: {
        fontWeight: 600,
        fontSize: 20,
        color: "#000000",
        paddingBottom: 10,
    },
    button: {
        background: Colors.BES_PURPLE
    },
};

export default styles;