import { Colors } from "../../utils/styles";

const styles = {
  mainContainer: {
    marginTop: 20,
  },
  carousel: {
    // position: "absolute",
    overflow: "hidden",
    width: "100%",
    height: 420,
  },
  mobilecarousel: {
    position: "absolute",
    overflow: "hidden",
    height: "43rem",
    width: "173%",
    top: "11rem",
    left: "16rem",
  },
  actionBtnContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: 380,
    height: 70,
  },
  howItWorksLink: {
    marginLeft: 20,
    textTransform: "uppercase",
  },
  section3card: {
    width: "18rem",
    border: "none",
    alignItems: "center",
    margin: "0 auto",
  },
  section3cardtitle: {
    fontWeight: 700,
    marginTop: 10,
    marginBottom: 20,
  },
  footerSectionHeaderText: {
    color: Colors.BES_PURPLE,
    fontSize: 14,
    fontWeight: 600,
  },
  footerLinkText: {
    color: "#161616",
    fontSize: 16,
  },
  links: {
    textDecoration: "none",
    color: "inherit",
  },
  sectionHeader: {
    fontSize: 32,
    fontWeight: 700,
    textAlign: "center",
    color: Colors.BES_PURPLE,
  },
  processSection: {
    display: "flex",
    // justifyContent: "center",
    alignItems: "center",
  },
  processContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: "wrap",
  },
  icon: {
    paddingRight: 25,
    paddingLeft: 25,
  },
  iconMobile: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
  },
  iconText: {
    width: 220,
    color: Colors.BES_PURPLE,
    fontWeight: 700,
    fontSize: 14,
    textAlign: "center",
    marginTop: 10,
  },

  iconTextMobile: {
    color: Colors.BES_PURPLE,
    fontWeight: 700,
    fontSize: 12,
    textAlign: "center",
    width: "150px",
  },
  center: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
  },
};

export default styles;
