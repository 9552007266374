import React, { useCallback, useEffect, useState } from "react";
import SideBar from "../../components/SideBar";
import Header from "../../components/Header";
import Card from "./components/Card";
import { clean_dollar_format } from "../../utils/utilities";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import TransactionList from "./components/Transaction";
import PaymentOptions from "./components/PaymentOptions";
import { ROUNDED_BUTTON_SECONDARY } from "../../utils/styles/buttons";
import { getWalletBalance, getTransactions } from "../../services/api";
import { useTransaction } from "../../context/transactionContext";
import TransactionModal from "./components/TransactionModal";
import { useNavigate } from "react-router-dom";
import "./style.scss";

const Wallet = () => {
  const [toggleBalance, setToggleBalance] = useState(false);
  const [showPaymentOptionsModal, setShowPaymentOptionsModal] = useState(false);
  const [walletBalance, setWalletBalance] = useState(0);
  const [showTransaction, setShowTransaction] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState({});

  const { transactions, setTransactions } = useTransaction();

  const handleToggleBalance = () => {
    setToggleBalance(!toggleBalance);
  };

  const toggleTransactionModal = () => {
    setShowTransaction(!showTransaction);
  };

  const togglePaymentOptionsModal = () => {
    setShowPaymentOptionsModal(!showPaymentOptionsModal);
  };

  const fetchWalletBalance = useCallback(async () => {
    const transactions_res = await getWalletBalance();
    if (transactions_res?.status === 200) {
      setWalletBalance(transactions_res?.data.balance);
    }
  }, []);

  const fetchWalletTransactions = useCallback(async () => {
    const wallet_trans_res = await getTransactions("wallet", null);
    if (wallet_trans_res?.status === 200) {
      setTransactions(wallet_trans_res?.data);
    }
  }, [setTransactions]);

  useEffect(() => {
    fetchWalletBalance();
    fetchWalletTransactions();
  }, [fetchWalletBalance, fetchWalletTransactions]);

  const getTransactionByReference = async (reference) => {
    // show a modal
    setShowTransaction(true);
    // handle api call to get transation by Reference
    const txn_res = await getTransactions(null, reference);
    if (txn_res?.status === 200) {
      setSelectedTransaction(txn_res?.data);
    }
  };

  const navigate = useNavigate();

  return (
    <div>
      <Header hideAuth={true} />
      <div className="custom_container custom_container--pall">
        <section style={{ height: "90vh", marginTop: 20 }}>
          <div className="custom-row">
            <div
              style={{
                padding: "10px 0 0 0",
              }}
              className="profile-sidebar first"
            >
              <SideBar path="/wallet" />
            </div>
            <div
              className="second px-4"
              style={{
                boxShadow: "none",
              }}
            >
              {/* Wallet Balance */}
              <Card
                balance={
                  toggleBalance
                    ? "******"
                    : Number(walletBalance) === 0
                    ? "$0.00"
                    : clean_dollar_format(walletBalance)
                }
                toggleBalance={() => handleToggleBalance()}
                isBalanceVisible={!toggleBalance}
                showFundingOptions={() => setShowPaymentOptionsModal(true)}
              />
              {/* Wallet Balance End */}

              {/* Show first 5 Transaction Listings */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingBottom: 16,
                  paddingTop: 16,
                }}
              >
                <p style={{ fontSize: 14, fontWeight: "600" }}>
                  Recent Transactions
                </p>

                <Button
                  style={{
                    ...ROUNDED_BUTTON_SECONDARY,
                    width: 90,
                    height: 35,
                    fontSize: 12,
                    fontWeight: 600,
                  }}
                  onClick={() => navigate("/wallet/transactions")}
                  disabled={transactions?.length === 0}
                >
                  View all <FontAwesomeIcon icon={faArrowRight} />
                </Button>
              </div>
              {transactions?.length === 0 ? (
                <p className="text-center mt-4 text-muted">
                  You have no Transactions.
                </p>
              ) : (
                <TransactionList
                  transactions={transactions?.slice(0, 5)}
                  handleGetTransaction={getTransactionByReference}
                />
              )}

              {/* End Transaction Listing */}

              {/* Payment Options Modal */}
              <PaymentOptions
                showModal={showPaymentOptionsModal}
                togglePaymentOption={togglePaymentOptionsModal}
              />
              {/* Payment Options Modal End */}

              {/* View a Single Transaction */}
              <TransactionModal
                toggleTransactionModal={toggleTransactionModal}
                title="Transaction"
                transaction={selectedTransaction[0]} // This should be an object
                showModal={showTransaction}
              />
              {/* View a Single Transaction End */}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Wallet;
