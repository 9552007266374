import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import styles from "./style";
import "./style.scss";
import { getPostsBySearch, getCategories } from "../../services/api";
import moment from "moment";
import { useParams } from "react-router-dom";
import { truncate, ITEMS_PER_PAGE } from "../../utils/utilities";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Footer from "../../components/Footer";

const SearchPage = () => {
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]);
  const { slug } = useParams();
  // const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState(slug);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [listPosts, setlistPosts] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const endOffset = itemOffset + ITEMS_PER_PAGE;
    setPageCount(Math.ceil(posts.length / ITEMS_PER_PAGE));
    setlistPosts(posts.slice(itemOffset, endOffset));
    setLoading(false);
  }, [posts, itemOffset]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * ITEMS_PER_PAGE) % posts.length;
    setItemOffset(newOffset);
  };

  const getPostsList = async () => {
    setLoading(true);
    const postList = await getPostsBySearch(searchInput);
    if (postList.response?.status >= 400) {
      // setError(postList.response.data.detail);
    } else {
      console.log(postList.data);
      setPosts(postList.data);
    }
    setLoading(false);
  };

  const getCategoriesList = async () => {
    setLoading(true);
    const catList = await getCategories();
    if (catList.response?.status >= 400) {
      // setError(catList.response.data.detail);
    } else {
      setCategories(catList.data);
      setLoading(false);
    }
  };

  const handleOnChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleDetailView = (slug) => {
    navigate(`/blog/${slug}`);
  };

  const postContentMarkup = (post) => {
    return { __html: truncate(post.content, 200) };
  };

  const returnCategory = (id) => {
    const catData = categories.find((cat) => cat.id === id);
    return catData?.title;
  };
  const handleSubmit = () => {
    if (searchInput.trim() !== "") {
      // getPostsList();
      navigate(`/blog/search/${searchInput}`);
    }
  };

  useEffect(() => {
    getPostsList();
    getCategoriesList();
    // eslint-disable-next-line no-unused-vars
  }, [slug]);

  return (
    <div>
      <Header hideAuth={true} />
      <Container style={styles.bodyContainer}>
        {loading === false ? (
          <div>
            <div>
              <div className="hide-for-mobile  custom_container custom_container--pall">
                <div className="searchInputs">
                  <input
                    type="text"
                    placeholder="Search a Keyword"
                    value={searchInput}
                    onChange={handleOnChange}
                  />
                  <button
                    onClick={handleSubmit}
                    disabled={searchInput.trim() === "" ? true : false}
                    className="flex flex-jc-c flex-ai-c gap-1 btn btn searchIcon bg-light text-dark p-2"
                  >
                    <FontAwesomeIcon className="" icon={faSearch} /> Search
                  </button>
                </div>

                {listPosts.length !== 0 &&
                  listPosts.map((post, index) => (
                    <div
                      className="searchSectionBox"
                      key={index}
                      style={{ marginBottom: "30px" }}
                    >
                      <div
                        className="searchSection "
                        onClick={() => handleDetailView(post.slug)}
                      >
                        <div className="flex flex-ai-c">
                          <img src={post.image_url} alt={post.slug} />
                          <div className="ml-1 flex flex-dc flex-ai-c">
                            <div>
                              <h4 className="searchSection__title mb-2 mt-5 ">
                                {truncate(post.title, 50)}
                              </h4>
                              <p className="mb-3 bes_purple-color">
                                <i className="fas fa-calendar-alt"></i>
                                <small>
                                  {" "}
                                  {moment(post.created_at).format(
                                    "MMMM Do YYYY"
                                  )}
                                </small>
                              </p>

                              <div
                                className="text-dark mb-3 contentOnSearch"
                                dangerouslySetInnerHTML={postContentMarkup(
                                  post
                                )}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <Row className="hide-for-desktop">
                {posts.length !== 0 ? posts.map((post, index) => (
                  <Col
                    lg={4}
                    key={index}
                    style={{ justifyContent: "center", marginTop: "20px" }}
                  >
                    <div
                      className="blog"
                      onClick={() => handleDetailView(post.slug)}
                    >
                      <img
                        className="img-fluid"
                        src={post.image_url}
                        alt={post.slug}
                      />
                      <div className="title-card">
                        <span className="badge badge bg-dark text-uppercase">
                          {returnCategory(post.categories[0])}
                        </span>
                        <br />
                        <h6 className="postTitleInList ">
                          {truncate(post.title, 40)}
                        </h6>
                        <br />
                        <span className="badge badge bg-secondary text-light">
                          {moment(post.created_at).format("MMMM Do YYYY")}
                        </span>
                      </div>
                    </div>
                  </Col>
                )):null}
              </Row>
              {listPosts.length === 0 && (
                <div className="text-center mt-5">
                  <p>No Posts found.</p>
                </div>
              )}
            </div>

            {posts && posts.length > ITEMS_PER_PAGE && (
              <div
                style={{ justifyContent: "left", marginBottom: "2rem" }}
                className="d-flex justify-content-center align-items-center"
              >
                {
                  <ReactPaginate
                    containerClassName="pagination"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    breakLabel="..."
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    renderOnZeroPageCount={null}
                  />
                }
              </div>
            )}
          </div>
        ) : (
          <div className="flex flex-jc-c mt-5">
            <div className="flex flex-dc flex-jc-c flex-ai-c mt-5 ">
              <i className="fas fa-spinner mb-1 fa-2x"></i>
              <small> Just a moment</small>
            </div>
          </div>
        )}
      </Container>

      { loading === false && searchInput === "" ? <Footer /> : null}

    </div>
  );
};

export default SearchPage;
