import React, { useState } from "react";
import { Modal, Button, Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faFacebook,
  faTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import TermsAndConditions from "../TermsAndConditions";
import logo from "./assets/logo.png";
import styles from "./style";
import "./style.css";

const Footer = () => {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => {
    setShowModal(true);
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        size="lg"
        className="modal-content-z"
      >
        <Modal.Header closeButton>
          <Modal.Title>TERMS AND CONDITIONS</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TermsAndConditions />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleClose}>
            I Agree
          </Button>
        </Modal.Footer>
      </Modal>

      <div style={{ backgroundColor: "#F4F4F4", padding: 20, width: "100%" }}>
        <Container
          style={{ marginTop: 40, marginBottom: 50, textAlign: "center" }}
        >
          <Row>
            <Col lg={2}>
              <img
                src={logo}
                alt="beta-eshopping logo"
                style={{ width: 230, marginBottom: 20 }}
                className="logo"
              />
            </Col>
            <Col lg={3}>
              <p style={styles.footerSectionHeaderText}>Company</p>
              <Col className="mb-3" style={styles.footerLinkText}>
                <Link to="/about-us">
                  <p style={styles.links} className="footer-links">
                    About us
                  </p>
                </Link>
              </Col>
              <Col className="mb-3" style={styles.footerLinkText}>
                <Link to="/contact-us">
                  <p style={styles.links} className="footer-links">
                    Contact us
                  </p>
                </Link>
              </Col>
              <Col className="mb-3" style={styles.footerLinkText}>
                <Link to="/contact-us">
                  <p style={styles.links} className="footer-links">
                    Support
                  </p>
                </Link>
              </Col>
              <Col className="mb-3" style={styles.footerLinkText}>
                {/* change link to /blog */}
                <Link to="/blog">
                  <p style={styles.links} className="footer-links">
                    Blog
                  </p>
                </Link>
              </Col>
            </Col>
            <Col lg={3}>
              <p style={styles.footerSectionHeaderText}>Shopping</p>
              <Col className="mb-3" style={styles.footerLinkText}>
                <Link to="/how-it-works">
                  {" "}
                  <p style={styles.links} className="howitworks footer-links">
                    How it works
                  </p>
                </Link>
              </Col>
              <Col className="mb-3" style={styles.footerLinkText}>
                <Link to="/stores">
                  <p style={styles.links} className="footer-links">
                    List of stores
                  </p>
                </Link>
              </Col>

              <Col className="mb-3" style={styles.footerLinkText}>
                <button
                  onClick={handleShow}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    marginLeft: -6,
                  }}
                  className="footer-links"
                >
                  Terms and conditions
                </button>
              </Col>
              <Col className="mb-3" style={styles.footerLinkText}>
                <Link to="/faq" className="link">
                  {" "}
                  <p style={styles.links} className="footer-links">
                    FAQs
                  </p>
                </Link>
              </Col>
            </Col>
            <Col lg={3}>
              <p style={styles.footerSectionHeaderText}>Let's stay connected</p>
              <Col lg={12} xs={12} className="mb-4">
                <Row>
                  <div className="mb-3 d-flex">
                    <Col lg={3} xs={3}>
                      <a
                        href="https://www.instagram.com/betaeshopping/"
                        style={styles.links}
                      >
                        <FontAwesomeIcon
                          icon={faInstagram}
                          size="2x"
                          className="social-icon-instagram"
                        />
                      </a>
                    </Col>
                    <Col lg={3} xs={3}>
                      <a
                        href="https://web.facebook.com/betaeshopping"
                        style={styles.links}
                      >
                        <FontAwesomeIcon
                          icon={faFacebook}
                          size="2x"
                          className="social-icon-facebook"
                        />
                      </a>
                    </Col>
                    <Col lg={3} xs={3}>
                      <a
                        href="https://linkedin.com/company/beta-eshopping"
                        style={styles.links}
                      >
                        <FontAwesomeIcon
                          icon={faLinkedin}
                          size="2x"
                          className="social-icon-linkedin"
                        />
                      </a>
                    </Col>
                    <Col lg={3} xs={3}>
                      <a
                        href="https://twitter.com/BetaEshopping"
                        style={styles.links}
                      >
                        <FontAwesomeIcon
                          icon={faTwitter}
                          size="2x"
                          className="social-icon-twitter"
                        />
                      </a>
                    </Col>
                  </div>
                  <a
                    href="https://goo.gl/maps/FGE6ef5LfGuEh3rJ8"
                    style={{
                      fontSize: 13,
                      marginBottom: 5,
                      textAlign: "left",
                      color: "#333",
                    }}
                  >
                    Emmanuel Plaza, 23b, Fatai Atere Way, Matori 102215, Lagos,
                    Nigeria.
                  </a>
                  <a
                    href="mailto:info@beta-eshopping.com"
                    style={{
                      fontSize: 13,
                      textAlign: "left",
                      marginBottom: 5,
                      color: "#333",
                    }}
                  >
                    info@beta-eshopping.com
                  </a>
                  <a
                    href="tel:+234-201-714-0067"
                    style={{
                      fontSize: 13,
                      textAlign: "left",
                      marginBottom: 5,
                      color: "#333",
                    }}
                  >
                    +234-201-714-0067
                  </a>
                </Row>
              </Col>
            </Col>
          </Row>
        </Container>
        <div>
          <hr
            style={{
              margin: "0 auto",
              border: "1px solid #CBCBCB",
              width: "80%",
            }}
          />
          <p
            style={{
              left: 10,
              marginTop: 25,
              fontSize: 14,
              textAlign: "center",
            }}
          >
            Betaeshopping &copy; {new Date().getFullYear()} | All Rights
            Reserved.
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
