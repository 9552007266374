import React from "react";
import { Fonts, Colors } from "../../../utils/styles";
import CreditSvg from "./Credit";
import DebitSvg from "./Debit";
import PendingSvg from "./Pending";
import moment from "moment";
import { addCommas } from "../../../utils/utilities";
import { Modal } from "react-bootstrap";

const TxDetailsCard = ({ label, description }) => {
  return (
    <div style={styles.detailsCardContainer}>
      <p style={styles.muted}>{label}</p>
      <p style={styles.descText}>{description}</p>
    </div>
  );
};

const TransactionModal = ({
  toggleTransactionModal,
  title,
  transaction,
  showModal,
}) => {
  return (
    <Modal
      className="modal-content-z"
      show={showModal}
      size="md"
      onHide={toggleTransactionModal}
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <h5 className="fw-bold">{title}</h5>
      </Modal.Header>
      <Modal.Body>
        <div style={styles.transactionTitleContainer}>
          {transaction?.status !== "pending" ? (
            transaction?.transaction_type === "credit" ? (
              <CreditSvg />
            ) : (
              <DebitSvg />
            )
          ) : (
            <PendingSvg />
          )}
          <p style={{ paddingLeft: 10, textAlign: "center", marginBottom: 0 }}>
            {transaction?.transaction_type?.toUpperCase()}
          </p>
        </div>
        <p style={{ textAlign: "center", paddingTop: 20, marginBottom: 0 }}>
          On {moment(transaction?.created_at).format("llll")}
        </p>
        {/* Transaction Details */}
        <TxDetailsCard
          label="Value"
          description={addCommas(transaction?.value)}
        />
        <TxDetailsCard label="Currency" description={transaction?.currency} />
        <TxDetailsCard
          label="Description"
          description={transaction?.description}
        />
        <TxDetailsCard
          label="Transaction Reference"
          description={transaction?.reference}
        />
        <TxDetailsCard
          label="Status"
          description={
            transaction?.status?.charAt(0).toUpperCase() +
            transaction?.status?.slice(1)
          }
        />
      </Modal.Body>
    </Modal>
  );
};

export default TransactionModal;

const styles = {
  headerText: {
    fontSize: 18,
    color: Colors.BES_BLACK,
    fontWeight: Fonts.FONT_WEIGHT_MEDIUM,
    marginBottom: 10,
  },
  transactionTitleContainer: {
    display: "flex",
    width: 100,
    height: 40,
    flexDirection: "row",
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.GRAY_LIGHT,
    margin: "0 auto",
    borderRadius: 10,
  },
  detailsCardContainer: {
    paddingVertical: 20,
    borderBottomWidth: 1,
    borderBottomColor: Colors.GRAY_LIGHT,
  },
  muted: {
    color: Colors.GRAY_DARK,
    fontSize: 14,
    paddingBottom: 2,
    marginBottom: 0,
  },
  descText: {
    fontSize: 16,
    fontWeight: "500",
    // marginBottom: 0,
  },
};
