import React, { useState } from "react";
import { Button, Form, Container, Alert } from "react-bootstrap";
import { sendResetRequestOTP } from "../../services/api";
import { useNavigate } from "react-router-dom";

import styles from "./style";
import { Oval } from "react-loader-spinner";
import AuthWrapper from "../../components/AuthWrapper";

const ResetPasswordPage = () => {
  const [userEmail, setUserEmail] = useState("");
  const [error, setError] = useState("");
  const [hasError, setHasError] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const validateForm = () => {
    return userEmail.length > 0;
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const email = userEmail.toLowerCase();
    const reset_res = await sendResetRequestOTP({ email });

    if (reset_res.status !== 200) {
      const err = reset_res.response.data;
      const errMsg = Object.keys(err).map((key) => {
        return `${err[key]}`;
      });
      setError(errMsg);
      setHasError(true);
    } else {
      setMessage(reset_res.data.detail);
      setHasError(false);
      setUserEmail("");
      navigate('/reset-password-otp')
    }
    setIsLoading(false);
  };

  return (
    <div>
      <AuthWrapper>
      <Container  style={{ marginTop: 10, paddingLeft: 85, paddingRight: 85 }} className="p-2">
          {message && (
            <Alert
              variant={"success"}
              onClose={() => setMessage("")}
              dismissible
            >
              {message}
            </Alert>
          )}
           <h1 className="login-container-title mt-5">Reset your Password</h1>
        <p className="form-description">
    
        </p>
         
            {hasError && (
              <Alert variant={"danger"} style={{ textAlign: "center" }}>
                {error}
              </Alert>
            )}

            <Form onSubmit={handleSubmit} style={styles.signInForm}>
              <Form.Group
                size="lg"
                controlId="email"
                style={styles.signInFormContainer}
              >
                <Form.Control
                  placeholder="Enter email"
                  autoFocus
                  type="email"
                  value={userEmail}
                  onChange={(e) => setUserEmail(e.target.value)}
                  style={styles.formInput}
                  className="bes-input-box-shadow"
                />
              </Form.Group>
              <Button
                className="block w-100"
                size="lg"
                type="submit"
                disabled={!validateForm() || isLoading}
                style={styles.button}

              >
                <div style={{ display: "inline-flex"  }}>
                  {isLoading ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <Oval
                        type="Circles"
                        color="#FFFFFF"
                        height={40}
                        width={40}
                        wrapperStyle={{ marginLeft: 15 }}
                      />
                    </div>
                  ) : (
                    "Reset Password"
                  )}
                </div>
              </Button>
            </Form>

      </Container>
      </AuthWrapper>
    </div>
  );
};

export default ResetPasswordPage;
