import * as React from "react";
const SvgDelivery = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={169}
    height={169}
    fill="none"
    {...props}
  >
    <circle cx={84.632} cy={84.368} r={84.368} fill="#FED6D6" />
    <g clipPath="url(#a)">
      <path
        fill="#3F3B7F"
        d="M58.34 101.538H38.2l-.536 5.39a1.972 1.972 0 0 0 1.97 2.161H55.79c-.105-1.717.227-3.481.983-5.165a9.026 9.026 0 0 1 1.566-2.386h.002ZM75.9 106.536c.162.837.216 1.694.162 2.555H94.85c1.04 0 1.903-.799 1.976-1.83l.405-5.721H73.32a10.008 10.008 0 0 1 2.581 4.998l-.001-.002ZM62.155 101.046a7.26 7.26 0 0 0-3.688 3.665c-1.53 3.409-.919 7.078 1.596 9.576 2.514 2.497 6.207 3.106 9.638 1.586a7.255 7.255 0 0 0 3.69-3.665c1.529-3.408.916-7.078-1.598-9.575-2.514-2.498-6.207-3.106-9.638-1.587Zm7.186 9.721a3.003 3.003 0 0 1-1.088 1.083c-.76.438-1.562.652-2.339.652-1.06 0-2.074-.4-2.862-1.185-1.367-1.357-1.577-3.386-.535-5.168a2.99 2.99 0 0 1 1.09-1.081c1.793-1.035 3.835-.826 5.202.531 1.367 1.358 1.576 3.387.535 5.168h-.003ZM111.667 101.318a7.256 7.256 0 0 0-3.688 3.664c-1.529 3.407-.917 7.075 1.596 9.572 2.514 2.497 6.205 3.105 9.635 1.585a7.249 7.249 0 0 0 3.687-3.663c1.53-3.407.917-7.075-1.595-9.573-2.513-2.496-6.204-3.104-9.635-1.585Zm7.184 9.72a2.994 2.994 0 0 1-1.089 1.081 4.666 4.666 0 0 1-2.338.653 4.016 4.016 0 0 1-2.862-1.185c-1.367-1.358-1.576-3.387-.535-5.169a2.994 2.994 0 0 1 1.089-1.081c1.793-1.035 3.835-.825 5.202.531 1.366 1.358 1.576 3.387.534 5.169l-.001.001Z"
      />
      <path
        fill="#EE4347"
        d="m56.532 66.377.102.206.218.433.11.214a34.914 34.914 0 0 0 .337.637c.114.211.232.424.353.638l.12.21c.09.156.18.314.273.472.042.072.083.144.127.217.123.207.248.415.377.626l.03.05c.14.227.282.454.428.682l.137.213c.11.17.22.34.334.512l.143.217c.152.228.308.458.469.691l.016.025c.166.239.335.48.508.723l.157.219c.13.181.264.363.399.547l.162.221c.185.249.374.5.567.752a73.88 73.88 0 0 0 7.882 8.713l.175-.18.052-.053c.053-.054.107-.112.164-.172l.045-.048a21.374 21.374 0 0 0 .276-.29c.066-.07.133-.14.203-.216l.066-.072c.088-.094.179-.19.273-.293l.013-.015c.09-.096.181-.198.277-.301.026-.03.052-.057.079-.086l.262-.29.054-.06c.107-.117.217-.24.328-.365l.07-.078c.095-.105.188-.211.285-.32l.085-.097.365-.416.014-.017.358-.414.094-.109a163.285 163.285 0 0 1 .39-.46c.133-.156.266-.314.4-.476.029-.032.055-.065.083-.099l.34-.41.09-.11.422-.52.022-.028c.132-.165.265-.33.399-.5l.1-.127c.13-.163.26-.33.39-.497a70.44 70.44 0 0 0 .478-.621c.03-.038.059-.077.088-.117l.366-.484.09-.121c.148-.198.294-.397.442-.6l.037-.05c.133-.183.268-.369.401-.555l.101-.141c.136-.193.274-.386.41-.581.01-.013.02-.026.028-.04.147-.209.292-.422.438-.635a89.868 89.868 0 0 0 .451-.672c.028-.043.058-.085.085-.129.144-.217.286-.437.428-.656l.05-.079c.124-.194.248-.389.37-.585l.097-.159c.134-.214.265-.43.396-.648l.013-.021c.136-.226.27-.454.403-.683.03-.05.059-.102.088-.153a44.647 44.647 0 0 0 .4-.704 66.364 66.364 0 0 0 .432-.796c.105-.198.21-.398.312-.599.03-.056.059-.113.086-.17 1.887-3.713 3.204-7.605 3.204-11.236.003-8.784-7.193-15.933-16.038-15.933-8.846 0-16.04 7.15-16.04 15.936 0 2.499.565 6.039 2.676 10.54l.285.593ZM69.61 48.172c3.636 0 6.594 2.938 6.594 6.55 0 3.613-2.958 6.552-6.594 6.552s-6.594-2.939-6.594-6.551c0-3.613 2.958-6.551 6.594-6.551Z"
      />
      <path
        fill="#3F3B7F"
        d="M97.397 65.783H84.52c-1.39 3.002-3.318 6.202-5.78 9.578-4.144 5.688-8.35 9.807-8.392 9.848a.775.775 0 0 1-1.053.026c-.176-.154-4.359-3.857-8.609-9.402-2.667-3.48-4.723-6.836-6.161-10.05h-11.98c-.938 0-1.72.725-1.792 1.665L38.2 99.853h22.216c.304-.18.62-.342.947-.488 2.124-.942 4.373-1.222 6.5-.815 1.203.231 2.34.672 3.372 1.301H97.23l1.961-32.15a1.804 1.804 0 0 0-1.796-1.918h.002ZM132.423 87.43l-4.351-1.889a1.162 1.162 0 0 1-.585-.56l-6.084-13.966a8.746 8.746 0 0 0-8.019-5.237l-9.351-.005a2.798 2.798 0 0 0-2.801 2.618l-2.36 39.154c-.003 1.183.932 1.742 2.045 1.742h4.382c-.092-1.696.242-3.432.987-5.094a9.056 9.056 0 0 1 4.596-4.562c2.123-.938 4.367-1.218 6.493-.81a10.122 10.122 0 0 1 5.246 2.772 10.027 10.027 0 0 1 2.957 7.694h6.225c1.02 0 1.864-.69 1.932-1.578l.746-17a3.417 3.417 0 0 0-2.06-3.282l.002.003Zm-26.856-4.61.699-12.438 7.106.006c1.527 0 2.905.966 3.512 2.46l4.05 9.973h-15.366l-.001-.002Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M37.654 39.308h96.832v77.657H37.654z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgDelivery;
