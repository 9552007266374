import React, { useState, useContext, useEffect } from "react";
import {
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import ModalContext from "../../context/modalContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, Button } from "react-bootstrap";
import {
  ROUNDED_BUTTON_SECONDARY,
  ROUNDED_BUTTON,
} from "../../utils/styles/buttons";
import { Colors } from "../../utils/styles";

//Images
import {
  CreateAccount,
  PlaceCartOrder,
  CompleteCheckout,
  Checkout,
  Stores,
  ShopNow,
  chromeEx,
  CustomOrder,
  CreateCustomOrder,
  PlaceCustomOrder,
  orderPlaced,
} from "./imgData";

const carouselData1 = [
  {
    imgUrl: CreateAccount,
    description: "Create a new account or sign in to your existing account",
  },
  {
    imgUrl: chromeEx,
    description: `Download the <a href='https://chrome.google.com/webstore/detail/beta-eshopping/kgcpjclkbimaefcfefhmgcpenbkdmigb' target='_blank'
     class="text-decoration-underline text-danger">Beta E-shopping Chrome Extension</a> and pin it to the browser.`,
  },

  {
    imgUrl: ShopNow,
    description: "On the homepage, click on “SHOP NOW”.",
  },

  {
    imgUrl: Stores,
    description:
      "Select your preferred store (e.g., Amazon) and then add item(s) to the store's cart.",
  },
  {
    imgUrl: Checkout,
    description:
      "Click on the 'Checkout button', you will be redirected to a page to see the list of your item(s) in your cart",
  },
  {
    imgUrl: CompleteCheckout,
    description:
      "Click on the “Complete Checkout on Beta-Eshopping” button, now you will redirected to your cart in Beta-Eshopping Site.",
  },

  {
    imgUrl: PlaceCartOrder,
    description: "Choose your preferred shipping method, select “Place Order,",
  },
  {
    imgUrl: orderPlaced,
    description:
      "An email notification will be sent to you. you can then review your pending orders on your dashbaord and make payment",
  },
];

const carouselData2 = [
  {
    imgUrl: CreateAccount,
    description: "Create a new account or sign in to your existing account",
  },
  {
    imgUrl: CustomOrder,
    description: "Click on Custom Order.",
  },
  {
    imgUrl: CreateCustomOrder,
    description:
      "Copy the Item URL, item name, and other neccessary details of the item from the store you are shopping from and paste in the cells as indicated.",
  },

  {
    imgUrl: PlaceCustomOrder,
    description: "Select your shipping type and place your order",
  },
  {
    imgUrl: orderPlaced,
    description:
      "An email notification will be sent to you. you can then review your pending orders on your dashbaord and make payment",
  },
];

const HowItWorksModal = (props) => {
  const [showCustomOrder, setShowCustomOrder] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [carouselData, setCarouselData] = useState(carouselData1);
  const { showCustomModal, setShowCustomModal } = useContext(ModalContext);
  const [imagesLoaded, setImagesLoaded] = useState(false);

  const handleModalClose = () => setShowCustomModal(false);

  const handleShowLoadCart = () => {
    setShowCustomOrder(false);
    setCarouselData(carouselData1);
    setCurrentIndex(0);
  };

  const handleShowCustomOrder = () => {
    setShowCustomOrder(true);
    setCarouselData(carouselData2);
    setCurrentIndex(0);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : carouselData.length - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < carouselData.length - 1 ? prevIndex + 1 : 0
    );
  };


  useEffect(() => {
    const imagePromises = carouselData.map((item) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = item.imgUrl;
        img.onload = resolve;
        img.onerror = reject;
      });
    });

    Promise.all(imagePromises)
      .then(() => setImagesLoaded(true))
      .catch((error) => console.error("Error loading images:", error));
  }, [carouselData]);


  return (
    <Modal
      {...props}
      size="md"
      className={`hide-for-mobile ${imagesLoaded ? "modal-content-header": "" } `}
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={showCustomModal}
      onHide={handleModalClose}
    >
      <Modal.Header closeButton>
       {imagesLoaded?<ul className="nav nav-tabs w-100 flex flex-jc-c ai-i-c">
          <li className="nav-item">
            <div
              className={`nav-link cursor-toggle ${
                !showCustomOrder ? "active" : ""
              }`}
              onClick={handleShowLoadCart}
              disabled={imagesLoaded ? false : true}

            >
              How to Load a Cart
            </div>
          </li>

          <li className="nav-item">
            <div
              className={`nav-link cursor-toggle ${
                showCustomOrder ? "active" : ""
              }`}
              onClick={handleShowCustomOrder}
            >
              How to Place a custom order
            </div>
          </li>
        </ul>: null}
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body-extend">
      {imagesLoaded ? (
          <>
            <img
              className="modal-img"
              src={carouselData[currentIndex].imgUrl}
              alt="..."
              loading="lazy"
            />
            <p
              className="modal-description"
              dangerouslySetInnerHTML={{
                __html: carouselData[currentIndex].description,
              }}
            ></p>
          </>
        ) : (
          <div className="flex flex-jc-c ai-i-c">Loading...</div>
        )}
      </Modal.Body>
    {imagesLoaded ?<Modal.Footer className="modal-footer-header">
      <Button
        size="md"
        style={{
          ...ROUNDED_BUTTON_SECONDARY,
          width: 100,
          height: 40,
          margin: "0 auto",
        }}
        disabled={imagesLoaded ? false : true}
        onClick={handlePrev}
      >
        <FontAwesomeIcon
          style={{
            color: Colors.BES_PURPLE,
            fontSize: "1.2rem",
            marginRight: "0.4rem",
          }}
          icon={faArrowAltCircleLeft}
        />
        Prev
      </Button>
      <Button
        size="md"
        style={{
          ...ROUNDED_BUTTON,
          width: 100,
          height: 40,
          margin: "0 auto",
        }}
        onClick={handleNext}
        disabled={imagesLoaded ? false : true}
      >
        Next
        <FontAwesomeIcon
          style={{
            color: "white",
            fontSize: "1.2rem",
            marginLeft: "0.4rem",
          }}
          icon={faArrowAltCircleRight}
        />
      </Button>
    </Modal.Footer>: null}
    </Modal>
  );
};

export default HowItWorksModal;
