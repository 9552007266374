import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal, Form } from "react-bootstrap";
import { verifyUserPassword, updateUserDetails } from "../../services/api";
import { ToastContainer, toast } from "react-toastify";
import PhoneInput from "react-phone-number-input";
import { saveToStore } from "../../utils/storage-helpers";

import AuthContext from "../../context/authContext";
import Header from "../../components/Header";
import SideBar from "../../components/SideBar";
import LoginPage from "../login";
import "./styles.scss";
import styles from "../login/style";

const ProfilePage = () => {
  const { user, isAuthenticated } = useContext(AuthContext);
  const [modalShow, setModalShow] = useState(false);
  const [passwordScreen, setPasswordScreen] = useState(false);
  const [passwordShown] = useState(false);
  const [password, setPassword] = useState("");
  const [verified, setVerfied] = useState(false);
  const [phone, setUserPhone] = useState(user.phone_no);
  const navigate = useNavigate();

  if (!isAuthenticated) {
    return <LoginPage path="/profile" />;
  }

  const handleShowPasswordScreen = () => {
    if (
      user.registration_method === "google" ||
      user.registration_method === "apple"
    ) {
      setPasswordScreen(true);
      setVerfied(true);
    } else {
      setPasswordScreen(!passwordScreen);
    }
  };

  const handleCloseModal = () => {
    setModalShow(!modalShow);
    setPasswordScreen(!passwordScreen);
  };

  const handleSubmit = async () => {
    if (password.trim() !== "") {
      const verify_res = await verifyUserPassword({ password: password });

      if (
        verify_res &&
        verify_res.response &&
        verify_res.response.status === 400
      ) {
        // console.log("register_res", register_res.response)
        toast.error(verify_res.response.data.detail, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          theme: "light",
        });
      } else {
        setVerfied(true);
      }
      setPassword("");
    }
  };

  const handlePhoneSubmit = async () => {
    if (phone !== 0) {
      const update_res = await updateUserDetails({ phone_no: phone });
      console.log("update_res", update_res?.data);
      await saveToStore("user", update_res?.data);

      if (update_res?.status === 200) {
        toast.success("Phone number updated successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          theme: "light",
        });
      }
      setVerfied(!verified);
      handleCloseModal();
      navigate("/profile");
    }
  };

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="light"
      />
      <Modal
        size="md"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={handleCloseModal}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {!passwordScreen && <h4>Phone Number</h4>}
            {passwordScreen && !verified && <h4>Confirm your Identify</h4>}
            {passwordScreen && verified && <h4>Change phone number</h4>}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!passwordScreen && !verified && (
            <div className="text-center bes-purple-text ">
              <i className="fas fa-phone fa-2x mb-2"></i>
              <p>{user.phone_no}</p>
              <small className="text-center text-sm text-secondary ">
                We use your phone number to verify who you are and give updates
                on your orders.
              </small>

              <Button
                onClick={handleShowPasswordScreen}
                className="bes-purple-text-bg w-100 rounded mt-4 "
                size="md"
              >
                Change Phone Number
              </Button>
            </div>
          )}
          {passwordScreen && verified && (
            <Form className="text-center bes-purple-text ">
              <Form.Group
                size="lg"
                controlId="phone"
                style={styles.signInFormContainer}
                className="register-form"
              >
                <Form.Label style={styles.registerFormLabel}>
                  Phone <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <PhoneInput
                  international
                  defaultCountry="NG"
                  value={phone}
                  onChange={setUserPhone}
                  style={styles.formInput}
                  className="signupFormInput"
                />
              </Form.Group>
              <Button
                onClick={handlePhoneSubmit}
                className="bes-purple-text-bg w-100 rounded mt-3"
                size="md"
                disabled={phone === 0}
              >
                Update
              </Button>
            </Form>
          )}

          {passwordScreen && !verified && (
            <Form className="text-center bes-purple-text ">
              <Form.Group
                size="lg"
                controlId="password"
                style={styles.signInFormContainer}
                onSubmit={handleSubmit}
              >
                <Form.Label className="text-start">
                  Enter your password
                </Form.Label>
                <Form.Control
                  placeholder="Password"
                  type={passwordShown ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  style={styles.formInput}
                  className="loginFormInput "
                />
              </Form.Group>
              <Button
                onClick={handleSubmit}
                className="bes-purple-text-bg w-100 rounded mt-3"
                size="md"
                disabled={password.trim() === ""}
              >
                Submit
              </Button>
            </Form>
          )}
        </Modal.Body>
      </Modal>

      <Header hideAuth={true} />
      <div className="custom_container custom_container--pall">
        <section style={{ height: "90vh", marginTop: 20 }}>
          <div className="custom-row">
            <div
              style={{
                padding: "10px 0 0 0",
              }}
              className="profile-sidebar first"
            >
              <SideBar path="/profile" />
            </div>
            <div lg={6} className="second px-4 py-4">
              <h5 className="mb-4 text-secondary text-uppercase">
                Account Details
              </h5>

              <>
                <div className="profile_box">
                  <p className="text fw-bold text-uppercase">
                    Basic Information
                  </p>
                  <div className="flex gap-5">
                    <div>
                      <label className="form-label text-secondary">
                        First Name
                      </label>
                      <p className="text-light">{user.first_name}</p>
                    </div>
                    <div>
                      <label className="form-label text-muted">Last Name</label>
                      <p>{user.last_name}</p>
                    </div>
                  </div>

                  <p className="text fw-bold text-uppercase mt-3">
                    Contact Information
                  </p>

                  <div className="flex gap-5 flex-ai-c">
                    <div className="">
                      <label className="form-label text-muted">Email</label>
                      <p className="">{user.email}</p>
                    </div>
                    <div className="flex flex-jc-sb flex-ai-c gap-2">
                      <div>
                        <label className="form-label text-muted">
                          Phone no
                        </label>
                        <div className="flex flex-ai-c gap-3 ">
                          <p>{phone || user.phone_no} </p>
                          <i
                            className="fas fa-edit cusor-me mb-3"
                            onClick={() => setModalShow(!modalShow)}
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ProfilePage;
