import React, { useState } from "react";
import SideBar from "../../components/SideBar";
import Header from "../../components/Header";
import TransactionList from "./components/Transaction";
import { useTransaction } from "../../context/transactionContext";
import { getTransactions } from "../../services/api";
import TransactionModal from "./components/TransactionModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { Colors } from "../../utils/styles";
import { useNavigate } from "react-router-dom";

const ViewTransactions = () => {
  const [showTransaction, setShowTransaction] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState([]);
  const { transactions } = useTransaction();

  const toggleTransactionModal = () => {
    setShowTransaction(!showTransaction);
  };

  const getTransactionByReference = async (reference) => {
    // show a modal
    setShowTransaction(true);
    // handle api call to get transation by Reference
    const txn_res = await getTransactions(null, reference);
    if (txn_res?.status === 200) {
      setSelectedTransaction(txn_res?.data);
    }
  };

  const navigate = useNavigate();
  return (
    <div>
      <Header hideAuth={true} />
      <div className="custom_container custom_container--pall">
        <section style={{ height: "90vh", marginTop: 20 }}>
          <div className="custom-row">
            <div
              style={{
                padding: "10px 0 0 0",
              }}
              className="profile-sidebar first"
            >
              <SideBar path="/wallet" />
            </div>
            <div
              className="second px-4"
              style={{
                boxShadow: "none",
              }}
            >
              <div className="d-flex gap-3 align-items-center mb-3">
                <FontAwesomeIcon
                  icon={faArrowCircleLeft}
                  color={Colors.BES_PURPLE}
                  size="lg"
                  onClick={() => navigate("/wallet")}
                />
                <p className="fw-bold mb-0">All Transactions</p>
              </div>
              <div className="tab-content">
                <TransactionList
                  transactions={transactions}
                  handleGetTransaction={getTransactionByReference}
                />
              </div>
              {/* View a Single Transaction */}
              <TransactionModal
                toggleTransactionModal={toggleTransactionModal}
                title="Transaction"
                transaction={selectedTransaction[0]} // This should be an object
                showModal={showTransaction}
              />
              {/* View a Single Transaction End */}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ViewTransactions;
