import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

import Header from "../../components/Header";
import { Colors } from "../../utils/styles";
import styles from "./style";

import { calculateETADateRange } from "../../utils/utilities";

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const SuccessPage = () => {
  const [status, setStatus] = useState("");
  const [ref, setRef] = useState("");
  const [type, setType] = useState("");
  let query = useQuery();

  useEffect(() => {
    transactionStatus();
  });

  const transactionStatus = () => {
    setStatus(query.get("status"));
    setType(query.get("type"));
    setRef(query.get("ref"));
  };

  const navigate = useNavigate();

  return (
    <div>
      <Header hideAuth={true} />
      <Container style={styles.bodyContainer}>
        <Row style={{ justifyContent: "center" }}>
          <Col lg={6} style={{ textAlign: "center", marginTop: 100 }}>
            {status === null ? (
              "loading"
            ) : status === "successful" ? (
              <div>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  size="6x"
                  color={Colors.BES_PURPLE}
                  className="mb-5"
                />
                <h5>
                  {type === "wallet"
                    ? "🎉 Payment Successful"
                    : `🎉 Payment for order ${ref}
                  is successfull!`}
                </h5>
                <p>
                  {type === "wallet"
                    ? "Your wallet has been funded successfully"
                    : `Your estimated delivery date is between
                  ${calculateETADateRange(new Date())}`}
                </p>
                <Button
                  href={type === "wallet" ? "/wallet" : "/stores"}
                  style={{
                    backgroundColor: Colors.BES_PURPLE,
                    marginTop: 30,
                    border: "none",
                  }}
                >
                  {type === "wallet" ? "Done" : "Continue Shopping"}
                </Button>
              </div>
            ) : status === "failed" ? (
              <div>
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  size="6x"
                  color={Colors.ALERT}
                  className="mb-5"
                />
                <h5>
                  {type === "wallet"
                    ? "🥺 Payment Failed"
                    : `Payment for order ${ref}
                  failed!`}
                </h5>
                <p>
                  {type === "wallet"
                    ? "Payment for wallet top up failed! Please contact support."
                    : `Payment for order ${ref} Failed! Please contact support`}
                </p>
                <Button
                  href={type === "wallet" ? "/wallet" : "/stores"}
                  style={{
                    backgroundColor: Colors.BES_PURPLE,
                    marginTop: 30,
                    border: "none",
                  }}
                >
                  Done
                </Button>
              </div>
            ) : (
              <div>
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  size="6x"
                  color={Colors.ALERT}
                  className="mb-5"
                />
                <h5>
                  {type === "wallet"
                    ? "🥺 Payment for wallet top up is Pending"
                    : `Payment for order ${ref} is Pending!`}
                </h5>
                <p>
                  {type === "wallet"
                    ? "Payment for wallet top up is pending! Please contact support."
                    : `Payment for order ${ref} is pending! Please contact support`}
                </p>
                <Button
                  href={navigate("/contact-us")}
                  style={{
                    backgroundColor: Colors.BES_PURPLE,
                    marginTop: 30,
                    border: "none",
                  }}
                >
                  Contact Support
                </Button>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SuccessPage;
