import { Colors } from "../../utils/styles";

export const ROUNDED_BUTTON = {
  width: 170,
  height: 45,
  background: Colors.BES_PURPLE,
  borderRadius: 10,
  fontWeight: 500,
  border: "none",
  fontSize: 18,
};

export const ROUNDED_BUTTON_SECONDARY = {
  width: 170,
  height: 45,
  display: "block",
  background: "transparent",
  borderRadius: 10,
  fontWeight: 500,
  borderWidth: 1,
  borderColor: Colors.BES_PURPLE,
  color: "#073E54",
  fontSize: 18,
};
