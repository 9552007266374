const ROOT_URL = process.env.REACT_APP_API_URL;

// User authentication
export const registerUrl = (id = null) => {
  if (id) {
    return `${ROOT_URL}/auth/register/${id}/`;
  }
  return `${ROOT_URL}/auth/register/`;
};

export const loginUrl = () => `${ROOT_URL}/auth/login/`;

export const fetchStoresUrl = (count = null, search = null) => {
  if (count && search) {
    return `${ROOT_URL}/stores?count=${count}&search=${search}`;
  } else if (count) {
    return `${ROOT_URL}/stores?count=${count}`;
  } else if (search) {
    return `${ROOT_URL}/stores?search=${search}`;
  }
  return `${ROOT_URL}/stores/`;
};

export const fetchCartUrl = (code) => {
  return `${ROOT_URL}/cart-v2?code=${code}`;
};

export const createOrderUrl = () => {
  return `${ROOT_URL}/orders/`;
};

export const createOrderV2Url = () => {
  return `${ROOT_URL}/orders-v2/`;
};

export const passwordResetUrl = () => {
  return `${ROOT_URL}/user/password-reset-confirm/`;
};

export const sendResetRequestUrl = () => {
  return `${ROOT_URL}/user/password-reset/`;
};

export const resendVerificationEmailUrl = () => {
  return `${ROOT_URL}/user/resend-token/`;
};

export const getOrdersUrl = (status) => {
  return `${ROOT_URL}/orders/?${status}=true`;
};

export const getOrderUrl = (order_id) => {
  return `${ROOT_URL}/orders/${order_id}/`;
};

// export const sendPaymentUrl = () => {
//   return `${ROOT_URL}/payment/`;
// };

export const sendCouponUrl = () => {
  return `${ROOT_URL}/coupon/`;
};

export const getCurrentRateUrl = () => {
  return `${ROOT_URL}/dollar-rate/`;
};

export const BannerImagesUrl = () => {
  return `${ROOT_URL}/images/banner/`;
};

export const PromoImagesUrl = () => {
  return `${ROOT_URL}/images/promo/`;
};

export const fetchPostsUrl = () => {
  return `${ROOT_URL}/blog/posts`;
};

export const fetchTagsUrl = () => {
  return `${ROOT_URL}/blog/tags/`;
};

export const getPostUrl = (slug) => {
  return `${ROOT_URL}/blog/posts/${slug}/`;
};

export const fetchCategoriesUrl = () => {
  return `${ROOT_URL}/blog/categories/`;
};

export const fetchCategoriesbySlugUrl = (slug) => {
  return `${ROOT_URL}/blog/posts/?category=${slug}`;
};

export const fetchPostsbySearchUrl = (slug) => {
  return `${ROOT_URL}/blog/posts/?search=${slug}`;
};

export const getCartItemsUrl = (code) => {
  return `${ROOT_URL}/get-cart-items/?code=${code}`;
};

export const fetchUserGoggleLoginResponseUrl = (code) => {
  return `${ROOT_URL}/v1/auth/login/google/${code}`;
};

export const editUserUrl = () => {
  return `${ROOT_URL}/user/edit-profile/`;
};

export const walletUrl = () => {
  return `${ROOT_URL}/wallet/`;
};

export const getTransactionsUrl = (type = null, reference = null) => {
  if (type) {
    return `${ROOT_URL}/transactions/?type=${type}`;
  } else {
    return `${ROOT_URL}/transactions/?reference=${reference}`;
  }
};

export const sendPaymentUrl = () => {
  return `${ROOT_URL}/payment/order/`;
};

export const verifyPasswordUrl = () => {
  return `${ROOT_URL}/user/verify-password/`;
};


export const fetchUserUrl = () => {
  return `${ROOT_URL}/user/`;
};

export const vertifyOTPUrl = () => {
  return `${ROOT_URL}/verify/otp/`;
};

export const resendOTPUrl = () => {
  return `${ROOT_URL}/resend/otp/`;
};

export const sendResetRequestOTPUrl = () => {
  return `${ROOT_URL}/reset-password/otp/`;
};