import React, { useEffect, useState, useMemo } from "react";
import jwt_decode from "jwt-decode";
import "./App.css";

import AuthContext from "./context/authContext";
import ImageContext from "./context/imageContext";
import OrderContext from "./context/orderContext";
import ModalContext from "./context/modalContext";
import HowItWorksModal from "./components/HowitsModal";
import { getFromStore, removeFromStore } from "./utils/storage-helpers";
import { BannerImages, PromoImages, fetchCart } from "./services/api";
import Pages from "./pages";
import { TransactionContext } from "./context/transactionContext";

const App = () => {
  const [user, setUser] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [isAuthenticated, setAuth] = useState(false);
  const [bannerImage, setBannerImage] = useState({});
  const [promoImage, setPromoImage] = useState([]);
  const [showCustomModal, setShowCustomModal] = useState(false);
  const [cartLen, setCartLen] = useState(0);
  const [cartCode, setCartCode] = useState("");
  const [transactions, setTransactions] = useState([]);

  const value = useMemo(
    () => ({
      user,
      setUser,
      isAuthenticated,
      setAuth,
      bannerImage,
      setBannerImage,
      promoImage,
      setPromoImage,
      cartLen,
      setCartLen,
      cartCode,
      setCartCode,
    }),
    [user, isAuthenticated, bannerImage, promoImage, cartLen, cartCode]
  );

  useEffect(() => {
    const getToken = async () => {
      const token = await getFromStore("access_token");
      

      if (!token) {
        setAuth(false);
        setIsLoaded(true);
        return;
      }

      const { exp } = jwt_decode(token);
      const expirationTime = exp * 1000;
      let currentDate = new Date();

      if (currentDate.getTime() >= expirationTime) {
        setAuth(false);
        removeFromStore("access_token");
      } else {
        setAuth(true);
      }
      setIsLoaded(true);
    };

    const getUser = async () => {
      const currentUser = await getFromStore("user");
      setUser(currentUser);
    };

    const handleCartLength = async () => {
      if (isAuthenticated) {
        const code = null;
        const cart_res = await fetchCart(code);
        if (cart_res.data.length === 1) {
          //handling an existing cart
          setCartCode(cart_res.data[0].cart.code);
          setCartLen(1);
        }
      }
    };
    getToken();
    getUser();
    handleCartLength();
  }, [isAuthenticated]);

  useEffect(() => {
    const getBannerImage = async () => {
      const image = await BannerImages();
      if (image.status === 200) {
        setBannerImage(image.data);
      }
    };

    const handlePromotionalImages = async () => {
      const promoImages = await PromoImages();
      if (promoImages.status === 200) {
        setPromoImage(promoImages.data);
      }
    };

    getBannerImage();
    handlePromotionalImages();
  }, []);

  return (
    <div className="App">
      <AuthContext.Provider value={value}>
        <ModalContext.Provider value={{ showCustomModal, setShowCustomModal }}>
          <HowItWorksModal />
          <ImageContext.Provider
            value={{ bannerImage, setBannerImage, promoImage, setPromoImage }}
          >
            <OrderContext.Provider
              value={{ cartLen, setCartLen, cartCode, setCartCode }}
            >
              <TransactionContext.Provider
                value={{ transactions, setTransactions }}
              >
                {isLoaded ? <Pages /> : null}
              </TransactionContext.Provider>
            </OrderContext.Provider>
          </ImageContext.Provider>
        </ModalContext.Provider>
      </AuthContext.Provider>
    </div>
  );
};

export default App;
