import React from "react";
import { Card, Container, Row, Col } from "react-bootstrap";

import Header from "../../components/Header";
import Footer from "../../components/Footer";

import styles from "./style";
import "./style.css";

const AboutUsPage = () => {
  return (
    <div>
      <Header hideAuth={true} />
      <Container style={styles.bodyContainer}>
        <Row style={{ justifyContent: "center" }}>
          <Col lg={6}>
            <h1 className="about-us-title">Who we are and what we do</h1>
            <p className="text-muted about-us-description">
              {" "}
              Beta-eshopping is an E-commerce platform that allows users shop
              from most international stores in Europe and North America. A
              subsidiary of{" "}
              <a
                href="https://betalogistics.ng/"
                target="_blank"
                rel="noreferrer"
              >
                Beta Courier Services Limited,
              </a>{" "}
              an international courier and logistics solution which has been in
              operations for over 15-years, Beta-eshopping ensures a seamless
              end to end International shopping experience from payment to local
              delivery for its users.
            </p>
          </Col>
          <Col lg={3}>
            <Card style={{ width: "18rem" }} className="about-us-card">
              <Card.Img
                variant="top"
                src="https://niqong.com/wp-content/uploads/2021/01/missions-clipart-team-goal-9-1.png"
              />
              <Card.Body>
                <Card.Title className="card-text-title">Our Vision</Card.Title>
                <Card.Text className="text-muted text-justify card-text-description">
                  Providing customers with the best online shopping experience
                  from start to finish, with a smart, user-friendly, clear and
                  secured payment methods, easy to access website and fast
                  quality delivery.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3}>
            <Card style={{ width: "18rem" }} className="about-us-card">
              <Card.Img
                variant="top"
                src="https://atg-prod-scalar.s3.amazonaws.com/studentpower/media/mission.png"
              />
              <Card.Body>
                <Card.Title className="card-text-title">Our Mission</Card.Title>
                <Card.Text className="text-muted text-justify card-text-description">
                  To offer fast, reliable and affordable e-commerce services to
                  cater to all our customers shopping needs. We believe the best
                  way to deliver a great shopping experience is understanding
                  what our customers want and need.
                </Card.Text>
              </Card.Body>
            </Card>
            {/* quote */}
          </Col>
          <Col lg={6}>
            <blockquote class="otro-blockquote">
              Understanding what our customers want and need is, in our opinion,
              the best way to provide a wonderful shopping experience.
              <span>John Isakpa - MD, Beta-eshopping.</span>
            </blockquote>
          </Col>
        </Row>
        <Row></Row>
      </Container>
      <Footer />
    </div>
  );
};

export default AboutUsPage;
