import React, { useState } from "react";
// import "./SearchBar.css";
import { faSearch, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./SearchBar.scss";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const SearchBar = ({ placeholder, data, handleDetailView }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");
  const navigate = useNavigate();

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);
    const titleFilter = data.filter((value) => {
      return Object.values(value.title).join('').toLowerCase().includes(searchWord.toLowerCase())  ;
    });
    const contentFilter = data.filter((value) => {
      return Object.values(value.content).join('').toLowerCase().includes(searchWord.toLowerCase())  ;
    });

    if (searchWord === "") {
      setFilteredData([]);
    } else {
      if(titleFilter.length > 0){
        setFilteredData(titleFilter);
      }
      else{
        setFilteredData(contentFilter);

      }
    }
  };  
  const navigateToSerchPage = () => {
    navigate(`/blog/search/${wordEntered}`);
  };

  
  const clearInput = () => {
    setFilteredData([]);
    setWordEntered("");
  };

  return (
    <div className="ll">
      <div className="searchInputs">
        <input
          type="text"
          placeholder={placeholder}
          value={wordEntered}
          onChange={handleFilter}
        />
        <div className="searchIcon">
          {filteredData.length === 0 ? (
            <FontAwesomeIcon className="" icon={faSearch} />
          ) : (
            <FontAwesomeIcon
              className="md"
              icon={faWindowClose}
              onClick={clearInput}
            />
          )}
        </div>
      </div>
      {filteredData.length !== 0 && (
        <div className="dataResult">
          {filteredData.map((value, key) => {
            return (
              <div
              key={key}
                className="cursor-toggle searchbar-holder"
                onClick={() => handleDetailView(value.slug)}
              >
                <img
                  src={value.image_url}
                  width="40"
                  height="60"
                  alt={value.slug}
                />
                <div className="flex flex-dc p-2">
                  <small>{value.title} </small>
                  <small className="text-secondary">
                    {" "}
                    {moment(value.created_at).format("MMMM Do YYYY")}
                  </small>
                </div>
                
              </div>
            );
          })}
          <h6  className="text-center"
          onClick={navigateToSerchPage}
          > <i className="fas fa-search"></i> View All Results</h6>
        </div>
      )}
      {filteredData.length === 0 && (
          <div>
           {wordEntered !== ""  && <div className="noResult">
           <div className="flex flex-jc-c flex-ai-c">
             <p className="text-center"> No Posts Found. </p>
                  
                </div>
        </div>}
          </div>
      
      )}
    </div>
  );
}

export default SearchBar;
