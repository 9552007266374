import { createContext } from "react";

const ImageContext = createContext({
  bannerImage: {},
  setBannerImage: () => {},
  promoImage: [],
  setPromoImage: () => {},
});

export default ImageContext;
