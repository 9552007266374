import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Row, Col, Container } from "react-bootstrap";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { Oval } from "react-loader-spinner";
import { updateUserDetails } from "../../services/api";
import { getFromStore, saveToStore } from "../../utils/storage-helpers";
import { ToastContainer, toast } from "react-toastify";
import AuthWrapper from "../../components/AuthWrapper";
import styles from "../register/style";
import "../register/style.scss";
import "./index.scss";

const CompleteRegistration = () => {
  const [firstname, setUserFirstName] = useState("");
  const [lastname, setUserLastName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [phone, setUserPhone] = useState(0);
  const [referral, setreferral] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const getInitials = async () => {
    const currentUser = await getFromStore("user");
    const { first_name, last_name, email, phone_no } = currentUser;
    setUserFirstName(first_name);
    setUserLastName(last_name);
    setUserEmail(email);
    setUserPhone(phone_no);
  };

  useEffect(() => {
    getInitials();
  }, []);

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const data = {
      phone_no: phone,
      referral: referral,
    };
    const register_res = await updateUserDetails(data);
    if (register_res && register_res.response && register_res.response.status === 400){
      // console.log("register_res", register_res.response)
      toast.error(register_res.response.data.detail, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "light",
      });
    }
    else{
    navigate("/");
    await saveToStore("user", register_res.data);
    
    }
    setIsLoading(false);
  };

  const validateForm = () => {
    return phone === null;
  };

  return (
    <AuthWrapper header="Start Shopping Now!" 
    description="Complete your account to enjoy a stress-free shopping experience."
    showHome={true}>
       <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover={false}
            theme="light"
          />
       <Container
        style={{ marginTop: 47, paddingLeft: 7, paddingRight: 9 , width: '24rem' }}
       
      >
          <p
          className="login-container-title mb-1"
          style={{fontSize: '1.3rem'}}
        >
          Complete your Registration
        </p>
    
        <Form onSubmit={handleSubmit} className="m-3"
        
        >
      
          <Row style={{ marginBottom: 20 }}>
            <Col xs={12} md={12}>
              <Form.Group
                size="lg"
                controlId="firstname"
                style={styles.signInFormContainer}
                className="register-form"
              >
                <Form.Label style={styles.registerFormLabel}>
                  Firstname <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  placeholder="Enter First Name"
                  autoFocus
                  type="text"
                  value={firstname}
                  onChange={(e) => setUserFirstName(e.target.value)}
                  style={styles.formInput}
                  disabled={true}
                  className="signupFormInput"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row style={{ marginBottom: 20 }}>
            <Col xs={12} md={12}>
              <Form.Group
                size="lg"
                controlId="lastname"
                style={styles.signInFormContainer}
              >
                <Form.Label style={styles.registerFormLabel}>
                  Lastname <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  placeholder="Enter Last Name"
                  type="text"
                  value={lastname}
                  disabled={true}
                  onChange={(e) => setUserLastName(e.target.value)}
                  style={styles.formInput}
                  className="signupFormInput"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row style={{ marginBottom: 20 }}>
            <Col xs={12} md={12} className="email-input">
              <Form.Group
                size="lg"
                controlId="email"
                style={styles.signInFormContainer}
              >
                <Form.Label style={styles.registerFormLabel}>
                  Email <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  placeholder="Enter email"
                  type="email"
                  disabled={true}
                  value={userEmail}
                  style={styles.formInput}
                  className="signupFormInput"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row style={{ marginBottom: 20 }}>
            <Col xs={12} md={12}>
              <Form.Group
                size="lg"
                controlId="phone"
                style={styles.signInFormContainer}
                className="register-form"
              >
                <Form.Label style={styles.registerFormLabel}>
                  Phone <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <div className="">
                  <PhoneInput
                    international
                    defaultCountry="NG"
                    value={phone}
                    onChange={setUserPhone}
                    style={styles.formInput}
                    className="signupFormInput margin-styles"
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row style={{ marginBottom: 15 }}>
            <Col xs={12} md={12} lg={12}>
              <Form.Group
                size="lg"
                controlId="referral"
                style={styles.signInFormContainer}
              >
                <Form.Label style={styles.registerFormLabel}>
                  How did you hear about us?
                </Form.Label>
                <Form.Control
                  placeholder="referral, Facebook, Google e.t.c"
                  type="text"
                  value={referral}
                  onChange={(e) => setreferral(e.target.value)}
                  style={styles.formInput}
                  className="signupFormInput w-48"
                />
              </Form.Group>
              <div className="d-grid gap-2 text-center ">
                <Button
                  className="block login-btn"
                  size="lg"
                  type="submit"
                  style={styles.button}
                  disabled={validateForm()}
                >
                  {isLoading ? (
                    <div className="d-flex align-items-center justify-content-center">
                      <Oval
                        type="Circles"
                        color="#fff"
                        height={30}
                        width={30}
                        wrapperStyle={{ margin: "0 auto" }}
                      />
                    </div>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Container>
    </AuthWrapper>
  );
};

export default CompleteRegistration;
