import React from "react";
import { Button } from "react-bootstrap";
import { Colors } from "../../../utils/styles";
import {
  clean_naira_format,
  clean_dollar_format,
} from "../../../utils/utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign, faNairaSign } from "@fortawesome/free-solid-svg-icons";
import { ROUNDED_BUTTON } from "../../../utils/styles/buttons";
import { useNavigate } from "react-router-dom";

const CardDebitForm = ({
  fundingType,
  convertedVal,
  currentExchangeRate,
  value,
  onChangeText,
  errMsg,
  handlePayment,
}) => {
  const removeNonNumeric = (num) => num.toString().replace(/[^0-9.]/g, "");
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
        alignItems: "center",
      }}
    >
      {/* Error Notification Text */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          paddingLeft: 40,
          paddingBottom: 5,
        }}
      >
        <p
          style={{
            color: Colors.BES_RED,
            fontSize: 14,
            marginBottom: 0,
            fontWeight: "500",
            textAlign: "center",
          }}
        >
          {errMsg !== "" ? errMsg : ""}
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignSelf: "center",
          alignItems: "center",
          paddingBottom: 50,
        }}
      >
        <div style={styles.iconContainer}>
          {/* <p>{fundingType === "Naira" ? "currency-ngn" : "currency-usd"}</p> */}
          <FontAwesomeIcon
            icon={fundingType === "Naira" ? faNairaSign : faDollarSign}
            size="md"
            color="#144C68"
          />
        </div>
        {/* Input */}
        <div
          style={{
            ...styles.textInputContainer,
          }}
        >
          <input
            style={{
              ...styles.textInput,
              color: errMsg ? Colors.BES_RED : Colors.BES_BLACK,
              borderColor: errMsg ? Colors.BES_RED : "#235772",
            }}
            name="fund-wallet"
            type="text"
            required
            keyboardType="numeric"
            maxLength={9}
            placeholder="0.00"
            autoFocus
            onChange={onChangeText}
            value={value}
          />
          {fundingType === "Naira" ? (
            <p style={styles.convRate}>{convertedVal}</p>
          ) : null}
        </div>
        {/* Input Ends */}
      </div>

      {/* conversion rates */}
      {fundingType === "Naira" ? (
        <div className="convRateContainer" style={styles.convRateContainer}>
          <p
            style={{
              marginBottom: 0,
              fontWeight: "600",
              color: Colors.BES_BLACK,
            }}
          >
            {`$1 = ₦${currentExchangeRate}`}
          </p>
          <div
            style={styles.innerConvChild}
            onClick={() => navigate("/current-rate")}
          >
            <p style={{ color: "#235772", marginBottom: 0, fontSize: 12 }}>
              Why this rate?
            </p>
          </div>
        </div>
      ) : null}
      {/* end conversion rates */}

      {/* Call to Action */}
      <div
        className="mobile-wallet-width"
        style={{
          paddingTop: fundingType === "Naira" ? 40 : 0,
          width: "50%",
        }}
      >
        <Button
          style={{ ...ROUNDED_BUTTON, width: "100%" }}
          disabled={errMsg ? true : value == 0 ? true : false}
          onClick={handlePayment}
        >
          {fundingType === "Naira"
            ? `Add ${clean_naira_format(removeNonNumeric(value))}`
            : `Add ${clean_dollar_format(removeNonNumeric(value))}`}
        </Button>
      </div>
    </div>
  );
};

export default CardDebitForm;

const styles = {
  textInput: {
    fontSize: 26,
    fontWeight: "600",
    borderRadius: 20,
    width: 150,
    height: 70,
    textAlign: "center",
    borderColor: "#235772",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 30,
    width: 30,
    backgroundColor: "#C7DFE8",
    borderRadius: 50,
    marginRight: 10,
  },
  textInputContainer: {
    height: 70,
    marginLeft: 10,
    marginRight: 10,
  },
  convRate: {
    paddingTop: 10,
    paddingBottom: 10,
    fontSize: 16,
    textAlign: "center",
    color: Colors.GRAY_DARK,
    fontWeight: "600",
    marginBottom: 0,
  },
  convRateContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    borderRadius: 50,
    height: 35,
    width: "35%",
    backgroundColor: Colors.GRAY_LIGHT,
  },
  innerConvChild: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 50,
    width: "50%",
    height: 20,
    backgroundColor: Colors.WHITE,
    cursor: "pointer",
  },
};
