// import React from 'react';
import { Image } from "react-bootstrap";

import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Colors } from "../../utils/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faMinusCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "@mui/material/Tooltip";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: Colors.BES_PURPLE,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const OrderItemCart = (props) => {
  const { items, onChangeQty, onRemove, is_uk_store } = props;
  const currency = is_uk_store ? "£" : "$";


  return (
    <div className="mb-5" style={{ textAlign: "center" }}>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 500 }}>
          <Table
            sx={{ minWidth: 700 }}
            aria-label="customized table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Product</StyledTableCell>
                <StyledTableCell align="center">Price</StyledTableCell>
                <StyledTableCell align="center">Quantity</StyledTableCell>
                <StyledTableCell align="center">Size</StyledTableCell>
                <StyledTableCell align="center">Color</StyledTableCell>
                <StyledTableCell align="center"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items?.map((item, idx) => (
                <StyledTableRow key={idx}>
                  <StyledTableCell component="th" scope="row">
                    <Image
                      src={item.image_url}
                      style={{
                        width: 90,
                        height: 90,
                        border: "none",
                      }}
                    />
                    <p
                      style={{
                        margin: "5px 0 0 0px",
                        color: "#262626",
                        fontSize: 12,
                        width: "100%",
                      }}
                    >
                      <a href={item.url} target="_blank" rel="noreferrer">
                        {item.name}
                      </a>
                    </p>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {currency}{item.unit_price}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <FontAwesomeIcon
                      style={{ marginRight: 4 }}
                      icon={faMinusCircle}
                      size="1x"
                      color={Colors.BES_RED}
                      onClick={() => {
                        onChangeQty("remove", item);
                      }}
                    />
                    {item.quantity}
                    <FontAwesomeIcon
                      style={{ marginLeft: 4 }}
                      icon={faPlusCircle}
                      size="1x"
                      color={Colors.BES_PURPLE}
                      onClick={() => {
                        onChangeQty("add", item);
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {item.size === "" ? "N/A" : item.size}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {item.color === "" ? "N/A" : item.color}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Tooltip title="Delete Item">
                      <div>
                        <FontAwesomeIcon
                          icon={faTrash}
                          size="1x"
                          color={Colors.BES_RED}
                          onClick={() => {
                            onRemove(item.id);
                          }}
                        />
                      </div>
                    </Tooltip>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default OrderItemCart;
