import React, { useState, useEffect } from "react";
import "./style.scss";

const images = [
  { url: "https://i.ibb.co/S3ns2bX/Browse-stores.png", title: "Explore different stores to find your next favourite item." },
  { url: "https://i.ibb.co/LdxJfn7/Add-items-to-cart.png", title: "The real excitement starts when you add items to your cart." },
  { url: "https://i.ibb.co/2y6cM2T/Checkout.png", title: "Choose Naira or Dollar payment option to complete transaction." },
  { url: "https://i.ibb.co/nsMTdxs/Delivery.png", title: "Sit back and leave the delivery to us." },
];

const AuthCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  
  return (
    <div className="auth-carousel">
      {images.map((image, index) => (
        <div
          key={index}
          className={` auth-slide ${
            index === activeIndex ? "auth-active" : ""
          }`}
        >   
          <img src={image.url} alt={`Slide ${index}`} width="20" height="auto" />
          <p className=" text-white text-center">{image.title}</p>

        </div>
      ))}


    </div>
  );
};

export default AuthCarousel;
