import React, { useState, useEffect } from "react";
import "./style.scss";

const CustomCarousel = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % data.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [data]);

  const handleSlideClick = (index) => {
    const image = data[index];
    if (image && image.external_link) {
      window.open(image.external_link, "_blank");
    }
  };

  return (
    <div className="custom-carousel">
      {data.map((image, index) => (
        <div
          key={index}
          className={`custom-slide ${
            index === activeIndex ? "custom-active" : ""
          }`}
          onClick={() => handleSlideClick(activeIndex)}
        >
          <img src={image.image_url} alt={`Slide ${index}`} />
        </div>
      ))}
    </div>
  );
};

export default CustomCarousel;
